import React, { Component }  from 'react'
import SostaOnlineApiService from '../../../services/SostaOnlineApiService'
import moment                from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import  * as regularIcons  from '@fortawesome/free-regular-svg-icons'
import './BackofficeNews.css'
import { getSubjectDescription } from 'libs/messages'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reportActions } from 'redux/modules/Report' 
import queryString from 'query-string'
import { Oval } from 'react-loader-spinner'
import {actions as authActions} from '../../../redux/modules/Auth'
import { getGridHeight } from 'libs/utils'
import BackofficeEditNewsModal from 'components/News/BackofficeEditNewsModal'
import { NEWS_TYPES } from 'libs/news'


const mapStateToProps = (state) => {
  return {
    stateAuth: state.auth,
    stateReport : state.report
  }
}

const mapDispatchToProps = (dispatch) => {
  return {   
    authActions:   bindActionCreators(authActions,   dispatch), 
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}

class BackofficeNews extends Component {
  static propTypes = {}

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor (props) {
    super(props)
    this.state = {
      maxHeight: 0,
      loading: true,
      selectedThreadId: null,
      selectedChildId: null,
      newsList: [],
      showEditNewsModal: false,
      sendErrors: null,
      selectetNews: null,
      page: 0,
      totalPages: 0,
      userId: null
    }

    this.onCloseNewNewsModal = this.onCloseNewNewsModal.bind(this)
    this.onSendNews          = this.onSendNews.bind(this)
    this.onCloseMessageModal = this.onCloseMessageModal.bind(this)
    this.onSendReplyMessage  = this.onSendReplyMessage.bind(this)
    this.fetchThingsToDo     = this.fetchThingsToDo.bind(this)
    this.handleResize        = this.handleResize.bind(this)
    
  }

  /**
   * --------------------------------------------------------------
   */
  async componentDidMount () {
    window.addEventListener('resize', this.handleResize)
    this.setState({ maxHeight : getGridHeight() })

    
    this.setState({loading: true})

    const queryParams = queryString.parse(this.props.location.search)
    if (queryParams.userId) {
      this.setState({ userId: queryParams.userId })
      this.fetchNews(this.state.page,queryParams.userId )
      this.props.initialize({ userId: queryParams.userId });
    }
    else {
      this.fetchNews(this.state.page,this.state.userId)
    }

    this.fetchThingsToDo()
    
  }

  /**
    * ----------------------------------------------------------------
    */
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  /**
   * ----------------------------------------------------------------
   */
  handleResize ( ) {        
    this.setState({ maxHeight : getGridHeight() })    
  }

  /**
   * --------------------------------------------------------------
   * @param {*} prevProps 
   */
  async componentDidUpdate(prevProps) {
    
  }

  /**
   * --------------------------------------------------------------
   */
  fetchThingsToDo () {
    SostaOnlineApiService.fetchThingsToDo().then(data => {      
      this.props.reportActions.fetchThingsToDo(data.data)
    })
    .catch( error => {
      console.log("ERROR",error)
    })
  }

  /**
   * --------------------------------------------------------------
   */
  fetchNews (page) {

    if ( page == null || page < 0 )
      page = 0

    SostaOnlineApiService.fetchNews(page)
      .then( result => {
          if ( result.result === 'ok' ) {                     
              this.setState({
                'newsList':result.data, 
                'page': page,
                'totalPages' : result.pagination.totalPages,
                'loading' : false
              })
              
          }
      } )
      .catch( error => {
        console.log("ERROR",error)
      })

  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  getSortedList() {
    var {            
        newsList: newsList
    } = this.state

    //console.log("getSortedList",newsList)
    
    return newsList 
  }

  /**
   * --------------------------------------------------------------
   * @param {*} msg 
   * @returns 
   */
  getTooltipNews() {
    var tooltipMessage = "Clicca per vedere e modificare la News."
    return tooltipMessage
  }

  /**
   * --------------------------------------------------------------
   * @param {*} msg 
   */
  childsCount(msg) {
    var childs = this.state.newsList.filter( m  =>  m.rootMessage === msg.id )
    return childs.length
  }

  /**
   * --------------------------------------------------------------
   * @param {*} news 
   * @returns 
   */
  getNewsStatus(news) {
    var icon           = solidIcons.faPause
    var style          = { color: "grey" }
    var tooltipMessage = "News disattiva"
    
    if ( news.enable ) {
      icon           = solidIcons.faPlay
      style          = { color: "green" }
      tooltipMessage = "News attiva"

      if ( news.startDate != null &&  moment().isBefore(moment(news.startDate))  ) {
        icon           = solidIcons.faClock
        style          = { color: "green" }
        tooltipMessage = "News schedulata"
      }

      if ( news.endDate != null &&  moment().isAfter(moment(news.endDate))  ) {
        icon           = solidIcons.faClock
        style          = { color: "darkred" }
        tooltipMessage = "News scaduta"
      }

    }

    return (
      <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-1-'+news.id}>{tooltipMessage}</Tooltip>}> 
        <FontAwesomeIcon  style={style} size="1x" icon={icon} />      
      </OverlayTrigger>
    )
                        
  }

  /**
   * --------------------------------------------------------------
   * @param {*} news 
   */
  getNewsTypeIcon(news) {

    var icon  = solidIcons.faInfo
    var style = { color: "grey" }

    if ( news.type == NEWS_TYPES.INFO.value ) {
      icon = solidIcons.faInfoCircle
      style = { color: "green" }
    }
    else if ( news.type == NEWS_TYPES.WARNING.value ) {
      icon = solidIcons.faCircleExclamation
      style = { color: "orange" }
    }
    else if ( news.type == NEWS_TYPES.ALERT.value ) {      
      icon = solidIcons.faTriangleExclamation
      style = { color: "darkred" }
    }
    else if ( news.type == NEWS_TYPES.ADV.value ) {      
      icon = solidIcons.faBullhorn
      style = { color: "#02afff" }
    }

  
    return (
        <FontAwesomeIcon  style={style} size="1x" icon={icon} />            
    )
  }

  /**
   * --------------------------------------------------------------
   * @param {*} news 
   * @param {*} index 
   * @returns 
   */
  getNewsRow(news) {

    //console.log("NEWS",news)

    return (     
      <React.Fragment  key={'main-'+news.id}>
        
        <tr key={'msg-'+news.id} className="backofficeMessageRow">
          
          <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-2-'+news.id}>{this.getTooltipNews()}</Tooltip>}>
            <td className="text-left" onClick={ evt => { this.showNews(news) } } >{this.getNewsTypeIcon(news)}</td>
          </OverlayTrigger>

          <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-3-'+news.id}>{this.getTooltipNews()}</Tooltip>}>
            <td className="text-left" onClick={ evt => { this.showNews(news) } } >{news.title}</td>
          </OverlayTrigger>
          
          <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-4-'+news.id}>{this.getTooltipNews()}</Tooltip>}>
            <td className="text-left" onClick={ evt => { this.showNews(news) } } >{ news.startDate && moment(news.startDate).format('L')}</td>
          </OverlayTrigger>

          <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-4-'+news.id}>{this.getTooltipNews()}</Tooltip>}>
            <td className="text-left" onClick={ evt => { this.showNews(news) } } >{ news.endDate && moment(news.endDate).format('L')}</td>
          </OverlayTrigger>

          <td className="text-left" >{this.getNewsStatus(news) }</td>

        </tr>
      
      </React.Fragment >  
    )
  
  }

  /**
   * --------------------------------------------------------------
   * @param {*} news 
   */
  showNews(news) {
    this.setState({selectetNews: news, showEditNewsModal: true})
  }

  /**
   * --------------------------------------------------------------
   */
  onOpenNewNewsModal () {
    this.setState({      
      showEditNewsModal: true,
      selectetNews: null
    })
  }

  /**
   * --------------------------------------------------------------
   */
  onCloseNewNewsModal () {
    this.setState({
      showEditNewsModal: false,
      selectetNews: null
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} news 
   */
  onSendNews (news) {
    //console.log("FETCHING MESSAGES")
    this.fetchNews(this.state.page,this.state.userId) 
  }

  /**
   * --------------------------------------------------------------
   */
  onCloseMessageModal () {
    this.setState({
      showMessageModal: false,
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} msg 
   */
  onSendReplyMessage (msg) {    
    this.fetchNews(this.state.page, this.state.userId) 
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {

    const {loading,page,totalPages,userId,maxHeight} = this.state
    const newsList = this.getSortedList()

    return (
      <React.Fragment>                
        <div className="panel panel-default panel-border" style={{ minHeight: 100, maxHeight: maxHeight}}>          
          <div className="panel-body">
            <div className="titolo-categoria">
              <h3 className="text-left sectionTitle">                    
                <FontAwesomeIcon size="1x" className="sectionIcons" icon={solidIcons.faNewspaper} />&nbsp;&nbsp;NEWS
              </h3>
            </div>
            
            <div className="col-md-12">
              {loading ? (
                <React.Fragment>
                <h3><strong>Caricamento in corso...</strong></h3>
                <div className="row" style={{padding: "2em", height: "40em"}}>
                  <Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />                  
                </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="row">
                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: 15, marginBottom: 5, marginLeft: 0, verticalAlign: "middle"}}>
                      <div className="mainBtn" style={{ margin: 0, marginBottom: 5, padding: '10px 15px'}} onClick={ (evt) => { this.onOpenNewNewsModal() }}>
                        <FontAwesomeIcon size="1x" className="mainIconsRevNoAct" icon={solidIcons.faPlusCircle} />&nbsp;&nbsp;Nuova News
                      </div>
                      <div style={{ width: "0em"}}></div>
                      <div style={{ width: "50em"}}></div>
                    </div>
                  </div>

                  <div className="row">
                    
                    <table className="table ">

                        <thead>
                        <tr className="backofficeNewsHeader">
                          <td style={{ width: "8rem" }} ></td>
                          <td className="text-left"><strong>Titolo</strong></td>       
                          <td className="col-md-2"><strong>Validità Da</strong></td>
                          <td className="col-md-2"><strong>A</strong></td>
                          <td style={{ width: "1rem" }} ></td>
                        </tr>
                        </thead>

                        <tbody>
                          { newsList.map( (news) =>  this.getNewsRow(news) ) }
                          
                          <tr className="backofficeNewsFooter">
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                          </tr>
                        </tbody>
                                              
                    </table>
                  </div>

                  <div className="row">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div className="col-md-3"></div>
                        <button className={"customBtnPage "+(page === 0 ? 'disableBtnPage' : 'ableBtnPage')} type="button" disabled={page === 0}><FontAwesomeIcon size="2x" className="sectionIcons" onClick={ (evt) => { this.fetchNews(0,userId) } } icon={solidIcons.faAnglesLeft} /></button>
                        <button className={"customBtnPage "+(page === 0 ? 'disableBtnPage' : 'ableBtnPage')} type="button" disabled={page === 0}><FontAwesomeIcon size="2x" className="sectionIcons" onClick={ (evt) => { this.fetchNews(page-1,userId) } } icon={solidIcons.faArrowLeft} /></button>
                        <div><h4> Page:&nbsp;&nbsp;{page+1} / {totalPages}</h4></div>
                        <button className={"customBtnPage "+(page === (totalPages-1) ? 'disableBtnPage' : 'ableBtnPage')} type="button" disabled={page === (totalPages-1)}><FontAwesomeIcon size="2x" className="sectionIcons" onClick={ (evt) => { this.fetchNews(page+1,userId) } } icon={solidIcons.faArrowRight}/></button>
                        <button className={"customBtnPage "+(page === (totalPages-1) ? 'disableBtnPage' : 'ableBtnPage')} type="button" disabled={page === (totalPages-1)} ><FontAwesomeIcon size="2x" className="sectionIcons" onClick={ (evt) => { this.fetchNews(totalPages-1,userId) } } icon={solidIcons.faAnglesRight} /></button>
                      <div className="col-md-3"></div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>          
        </div>
              
        <BackofficeEditNewsModal
          show={this.state.showEditNewsModal}
          onSaveNews={this.onSendNews}          
          onClose={this.onCloseNewNewsModal}
          news={this.state.selectetNews}
          />

      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'BackofficeMessages'})(BackofficeNews))

