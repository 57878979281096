import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import {Router} from 'react-router-dom'
import App from './App'
import { unregister } from './registerServiceWorker'
import history from './history'

import 'animate.css/animate.css'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'


import './index.css'

class AppContainer extends Component {
  render () {
    return (
      <Router history={history}>
        <App />
      </Router>
    )
  }
}

ReactDOM.render(<AppContainer />, document.getElementById('root'))

unregister()
