import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { notify } from "react-notify-toast";
import { Element } from "react-scroll";
import BackOfficeUserDetailVehicleCreateForm from "../components/BackOfficeUserDetailVehicleCreateForm";
import "../components/BackOfficeUserDetailsModalComponent.css";
import "./BackOfficeUserDetailScreen.css";
import BackOfficeUserDetailMarkForm from "../components/BackOfficeUserDetailMarkForm";
import BackOfficeUserDetailVehicleEditForm from "../components/BackOfficeUserDetailVehicleEditForm";
import classNames from "classnames";
import BackofficeMarkDocumentsReviewContainer from "../components/BackofficeDocumentsReview/BackofficeMarkDocumentsReviewContainer";
import BackOfficeUserDetailEditMarkForm from "../components/BackOfficeUserDetailEditMarkForm";
import BackOfficeUserDetailLogsTableContainer from "../containers/BackOfficeUserDetailLogsTableContainer";
import BackOfficeUserDetailMarkLogsContainer from "../containers/BackOfficeUserDetailMarkLogsContainer";
import MarkPaymentStatus from "../components/Payments/MarksPayments.js";
import NumberPlateModal from "../components/NumberPlateModal";
import SostaOnlineApiService from "services/SostaOnlineApiService";
import { MARK_RENEW_NOTIFY_DAYS } from "libs/settings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as solidIcons from "@fortawesome/free-solid-svg-icons";
import * as regularIcons from "@fortawesome/free-regular-svg-icons";
import MarksPersonsPerHouseUnitsModal from '../components/Marks/MarksPersonsPerHouseUnitsModal';
import {
  getMarkStatus,
  getMarkZone,
  getSelectedEndDate,
  getSelectedStartDate,
  MARK_STATUS_PENDING,
  MARK_STATUS_PENDING_EDITABLE,
  MARK_TYPE_FAMILY,
  STATUS_ACTIVE,
  STATUS_CEASED,
  STATUS_EXPIRED,
  STATUS_RENEWED,
  STATUS_WAIT_APPROVE,
  STATUS_WAIT_PAYMENT,
  PRICE_TYPE_PERSON_PER_HOUSING_UNIT,
} from "libs/marks";
import { Oval } from "react-loader-spinner";
import { ADMIN } from "libs/roles";
import BackOfficeUserDetailVehicleForm from "components/BackOfficeUserDetailVehicleForm";
import {
  legalAddressFormat,
  residenceAddressFormat,
  workAddressFormat,
  checkEndDate
} from "libs/utils";
// import { Item } from "react-bootstrap/lib/Breadcrumb";
// import { markTypes } from "redux/modules";

export default class BackOfficeUserDetailScreen extends Component {
  static propTypes = {
    onMarkShortLinkClick: PropTypes.func.isRequired,
    onVehicleShortLinkClick: PropTypes.func.isRequired,
    onMarkRenew: PropTypes.func.isRequired,
    highlightObjects: PropTypes.array.isRequired,
    findObjectById: PropTypes.func.isRequired,      
    onRenewPaymentMethodChange: PropTypes.func.isRequired,
    onRenewMarkClick: PropTypes.func.isRequired,
    onCancelRenewMarkClick: PropTypes.func.isRequired,
    markRenewing: PropTypes.object.isRequired,
    markRenewingErrors: PropTypes.object.isRequired,
    renewPaymentMethodSelected: PropTypes.string,
    userWaitingLists: PropTypes.array,
    onShowChooseMarksVehicleModal: PropTypes.func.isRequired,
    onHideChooseMarksVehicleModal: PropTypes.func.isRequired,
    onVehicleChoosen: PropTypes.func.isRequired,
    showChooseMarksVehicleModal: PropTypes.bool,
    showMarksVehicleModalNumber: PropTypes.object,
    chooseMarkVehicleError: PropTypes.string,
    selectedMarkId: PropTypes.number,
    operator: PropTypes.object,
    onVehicleSubmit: PropTypes.func,
    showValidate: PropTypes.bool,
    selectedMarkType: null,
    showMarksUnit: PropTypes.func.isRequired
  };

  // --------------------------------------------------------------
  constructor(props) {
    super(props);
    this.state = {      
      showCreateVehicle: false,
      showCreateMark: false,
      showCreateReceipt: false,
      markEditing: [],
      editMarkError: props.editMarkError,
      vehicleEditing: {},
      vehicleDeleting: {},
      marksCeasing: {},
      marksDeleting: {},
      marksDenyingApprovation: {},
      marksDenyingApprovationReasons: {},
      marksListingInWait: {},
      marksListingInWaitInstructions: {},
      showUserLogs: false,
      marksApprovations: {},
      markRenewNotifyDays: null,      
      spidEnable: false,
      documentTypes: [],
      markTypes: [],
      enableCash: false,
      enablePagoPa: false,
      enableReclaim: false,
      settings: [],
      prices: [],
      markPrices: [],
      loading: true,
      error: null,
      result: null,
      viewValidate: false,
      date: new Date(),
      receipt: false,
      showMarkPerPersonUnits: false
    };
       

    this.onMarkApprovationCircolazioneChange = this.onMarkApprovationCircolazioneChange.bind(this);
    this.onMarkApprovationPeriodoChange = this.onMarkApprovationPeriodoChange.bind(this);
    this.onMarkApprovationSostaChange = this.onMarkApprovationSostaChange.bind(this);
    this.getBackOfficeUserDetailInitiaVal = this.getBackOfficeUserDetailInitiaVal.bind(this);
    this.onCancelEditMarkClick = this.onCancelEditMarkClick.bind(this);
    this.onEditMarkClick = this.onEditMarkClick.bind(this);
    this.onVehicleEditClick = this.onVehicleEditClick.bind(this);
    this.onDeleteVehicleClick = this.onDeleteVehicleClick.bind(this);
    this.onCancelDeleteVehicleClick = this.onCancelDeleteVehicleClick.bind(this);
    this.onHideCreateVehicleClick = this.onHideCreateVehicleClick.bind(this);
    this.updateCheckPaymentStatus = this.updateCheckPaymentStatus.bind(this);
    this.checkNumberDocumentMandatory = this.checkNumberDocumentMandatory.bind(this);
    this.onShowValidate = this.onShowValidate.bind(this);
    this.showMarksPersonsPerHouseUnits = this.showMarksPersonsPerHouseUnits.bind(this)
  }

  // --------------------------------------------------------------
  async componentDidMount() {    
        
    SostaOnlineApiService.fetchSettings()
      .then((settings) => {
        if (
          settings != null &&
          settings.data != null &&
          settings.data.length > 0
        ) {
          this.setState({ settings: settings.data });

          settings.data.map((setting) => {
            if (setting.name === MARK_RENEW_NOTIFY_DAYS) {
              this.setState({ markRenewNotifyDays: parseInt(setting.value) });
            }
          });
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });

    SostaOnlineApiService.fetchPaymentSettings()
      .then((payments) => {
        if (payments.data.length === 0) {
          this.setState({ enableCash: false });
          this.setState({ enablePagoPa: false });
          this.setState({ enableReclaim: false });
        } else {
          this.setState({ enableCash: payments.data.enableCash });
          this.setState({ enablePagoPa: payments.data.enablePagoPa });
          this.setState({ enableReclaim: payments.data.enableReclaim });
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });

    SostaOnlineApiService.fetchMarkTypes()
      .then(async (response) => {
        this.setState({
          markTypes: response.data,
          prices: await this.fetchMarkTypePrices(
            this.props.user,
            response.data
          ),
          markPrices: await this.fetchMarkPrices(this.props.user),
        });        
      })
      .catch((error) => {
        console.log("ERROR", error);
      });

    SostaOnlineApiService.fetchDocumentTypes()
      .then((response) => {
        this.setState({ documentTypes: response.data });
      })
      .catch((error) => {
        console.log("ERROR", error);
      });  

    SostaOnlineApiService.isSpidEnabled()
      .then((response) => {
        this.setState({ spidEnable: response.data.enable });
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
    }


  // #############################################################

  async onCheckReceipt(markId) {    
    await SostaOnlineApiService.checkMarkReceipt(markId)
    .then((response) => {     
      localStorage.setItem('checkReceipt'+markId, response.data);     
      return response.data;      
    })
    .catch((error) => {
      console.log("ERROR", error);
    });    
  }

  numberOrderPayment(user, markId) {
    let numberOrderPayment = null;    
    if(user.orders.length > 0) {
      for (let index = 0; index < user.orders.length; index++) {
        const element = user.orders[index];        
        if (element.MarkOrders.length > 0) {          
          if (element.MarkOrders[0].markId === markId){            
            numberOrderPayment = element.numberOrderPayment ? element.numberOrderPayment : null
            return {
              numberOrderPayment: numberOrderPayment
            }
          }
        }        
      }      
    }
    return {
      numberOrderPayment: numberOrderPayment
    }
  }

  updateCheckPaymentStatus(name, act) {
    if (name == "loading") {
      this.setState({ loading: act });
    } else if (name == "error") {
      this.setState({ error: act });
    } else if (name == "result") {
      this.setState({ result: act });
    }
  }

  checkNumberDocumentMandatory(arr) {
    let result = 0;
    arr.forEach((ele) => {
      if (ele.MarkTypesDocuments.mandatory == true) {
        result += 1;
      }
    });
    return result;
  }

  onShowValidate() {
    console.log("onShowValidate");
    //this.setState({viewValidate: true})
  }

  // --------------------------------------------------------------
  async componentDidUpdate(prevProps) {   

    if (prevProps.editMarkError != this.props.editMarkError)
      this.setState({ editMarkError: this.props.editMarkError });

    if (prevProps.user != this.props.user) {
      this.setState({
        prices: await this.fetchMarkTypePrices(
          this.props.user,
          this.state.markTypes,
        ),
        markPrices: await this.fetchMarkPrices(this.props.user),
      });
    }

  }

  // --------------------------------------------------------------
  onMarkApprovationCircolazioneChange(id, value) {
    if (!this.state.marksApprovations[id]) {
      this.setState({
        marksApprovations: {
          ...this.state.marksApprovations,
          [id]: {
            circolazione: value,
          },
        },
      });
    } else {
      this.setState({
        marksApprovations: {
          ...this.state.marksApprovations,
          [id]: {
            ...this.state.marksApprovations[id],
            circolazione: value,
          },
        },
      });
    }
  }

  // --------------------------------------------------------------
  onMarkApprovationPeriodoChange(id, value) {
    if (!this.state.marksApprovations[id]) {
      this.setState({
        marksApprovations: {
          ...this.state.marksApprovations,
          [id]: {
            periodo: value,
          },
        },
      });
    } else {
      this.setState({
        marksApprovations: {
          ...this.state.marksApprovations,
          [id]: {
            ...this.state.marksApprovations[id],
            periodo: value,
          },
        },
      });
    }
  }

  // --------------------------------------------------------------
  onMarkApprovationSostaChange(id, value) {
    if (!this.state.marksApprovations[id]) {
      this.setState({
        marksApprovations: {
          ...this.state.marksApprovations,
          [id]: {
            sosta: value,
          },
        },
      });
    } else {
      this.setState({
        marksApprovations: {
          ...this.state.marksApprovations,
          [id]: {
            ...this.state.marksApprovations[id],
            sosta: value,
          },
        },
      });
    }
  }

  // --------------------------------------------------------------
  onVehicleEditClick(vehicle) {
    let vehiclesEditing = {};
    vehiclesEditing[vehicle.id] = true;
    this.setState({ vehicleEditing: vehiclesEditing });
    this.setState({ vehicleDeleting: {} });
  }

  // --------------------------------------------------------------
  onCloseVehicleEdit() {
    this.setState({ vehicleEditing: {} });
    this.props.onCloseVehicleEdit();
  }

  // --------------------------------------------------------------
  onDeleteVehicleClick(vehicle) {
    let vehiclesDeleting = {};
    vehiclesDeleting[vehicle.id] = true;
    this.setState({ vehicleDeleting: vehiclesDeleting });
    this.setState({ vehicleEditing: {} });
  }

  // --------------------------------------------------------------
  onCancelDeleteVehicleClick() {
    this.setState({ vehicleDeleting: {} });
  }

  // --------------------------------------------------------------
  onDeleteMarkClick(mark) {
    let markDeleting = {};
    markDeleting[mark.id] = true;
    this.setState({ marksDeleting: markDeleting, marksCeasing: {} });
  }

  // --------------------------------------------------------------
  onCancelDeleteMarkClick() {
    this.setState({ marksDeleting: {} });
  }

  // --------------------------------------------------------------
  onCloseMarkCease() {
    this.setState({ marksCeasing: {} });
    this.props.onCloseMarkCease();
  }

  // --------------------------------------------------------------
  onCeaseMarkClick(mark) {
    let markCeasing = {};
    markCeasing[mark.id] = true;
    this.setState({ marksCeasing: markCeasing, marksDeleting: {} });
  }

  // --------------------------------------------------------------
  onEditMarkClick(mark) {
    var markEditing = [];
    markEditing[mark.id] = true;
    this.setState({
      markEditing: markEditing,
      marksDeleting: {},
      editMarkError: null,
    });
  }

  // --------------------------------------------------------------
  onCancelEditMarkClick() {
    this.setState({ markEditing: {}, marksDeleting: {}, editMarkError: null });
  }

  // --------------------------------------------------------------
  onCeaseMark(mark) {
    this.props.onCeaseMark(mark);
    this.setState({ marksCeasing: {} });
  }

  // --------------------------------------------------------------
  onCancelCeaseMarkClick() {
    this.setState({ marksCeasing: {} });
  }

  // --------------------------------------------------------------
  onVehicleCancelEditClick(vehicle) {
    let vehiclesEditing = this.state.vehicleEditing;
    vehiclesEditing[vehicle.id] = false;
    this.setState({ vehicleEditing: vehiclesEditing });
  }

  // --------------------------------------------------------------
  onShowCreateVehicleClick() {
    this.setState({ showCreateVehicle: true });
  }

  // --------------------------------------------------------------
  onShowRicevuta() {
    this.setState({ showCreateVehicle: true });
  }

  // --------------------------------------------------------------
  onHideCreateVehicleClick() {
    this.setState({ showCreateVehicle: false });
  }

  // --------------------------------------------------------------
  onShowCreateMarkClick() {
    this.setState({ showCreateMark: true });
  }

  // --------------------------------------------------------------
  onHideCreateMarkClick() {
    this.setState({ showCreateMark: false });
  }

  // --------------------------------------------------------------
  onShowCreateReceiptClick() {
    this.setState({ showCreateReceipt: true });
  }

  // --------------------------------------------------------------
  onHideCreateReceiptClick() {
    this.setState({ showCreateReceipt: false });
  }

  // --------------------------------------------------------------
  onShowDenyMarkActivation(mark) {
    let marksDenyingApprovation = {};
    let marksDenyingApprovationReasons = {};
    marksDenyingApprovation[mark.id] = true;
    marksDenyingApprovationReasons[mark.id] = "";
    this.setState({
      marksDenyingApprovation: marksDenyingApprovation,
      marksDenyingApprovationReasons: marksDenyingApprovationReasons,
    });
  }

  // --------------------------------------------------------------
  onShowListAsWaitMark(mark) {
    let marksListingInWait = {};
    let marksListingInWaitInstructions = {};
    marksListingInWait[mark.id] = true;
    marksListingInWaitInstructions[mark.id] = "";
    this.setState({
      marksListingInWait: marksListingInWait,
      marksListingInWaitInstructions: marksListingInWaitInstructions,
    });
  }

  // --------------------------------------------------------------
  onHideListAsWaitMark(mark) {
    let marksListingInWait = {};
    let marksListingInWaitInstructions = {};
    marksListingInWait[mark.id] = false;
    marksListingInWaitInstructions[mark.id] = "";
    this.setState({
      marksListingInWait: marksListingInWait,
      marksListingInWaitInstructions: marksListingInWaitInstructions,
    });
  }

  // --------------------------------------------------------------
  onListAsWaitInstructionsChange(event, mark) {
    let marksListingInWaitInstructions = {};
    marksListingInWaitInstructions[mark.id] = event.target.value;
    this.setState({
      marksListingInWaitInstructions: marksListingInWaitInstructions,
    });
  }

  // --------------------------------------------------------------
  onHideDenyMarkActivation(mark) {
    let marksDenyingApprovation = {};
    let marksDenyingApprovationReasons = {};
    marksDenyingApprovation[mark.id] = false;
    marksDenyingApprovationReasons[mark.id] = "";
    this.setState({
      marksDenyingApprovation: marksDenyingApprovation,
      marksDenyingApprovationReasons: marksDenyingApprovationReasons,
    });
  }

  // --------------------------------------------------------------
  onDenyMarkActivationNoteChange(event, mark) {
    let marksDenyingApprovationReasons = {};
    marksDenyingApprovationReasons[mark.id] = event.target.value;
    this.setState({
      marksDenyingApprovationReasons: marksDenyingApprovationReasons,
    });
  }

  // --------------------------------------------------------------
  isUserBelongsToCategory(categoryId) {
    if (!this.props.user.categories) {
      return false;
    }

    let filtered = this.props.user.categories.filter((item) => {
      return item.id === categoryId;
    });

    return filtered.length > 0;
  }

  // --------------------------------------------------------------
  async onMarkRenew(mark) {
    await this.props.onMarkRenew(mark);
  }
  
  // --------------------------------------------------------------

  showButtonRenew(item) {    

    if (item.Vehicles[0]) {
      return (      
        !item.Vehicles[0].archived &&     
        !this.state.marksDeleting[item.id] &&
        item.MarkType.renewable &&
        !item.ceased &&
        !this.state.marksCeasing[item.id] &&
        !this.state.markEditing[item.id] &&
        !this.props.markRenewing[item.id] &&
        this.state.markRenewNotifyDays != null &&
        moment(item.endDate)
          .startOf("day")
          .diff(moment().startOf("day"), "days") <=
          this.state.markRenewNotifyDays &&
        !item.renewed &&
        item.MarkType.type !== MARK_TYPE_FAMILY
      );
    }    
    
  }

  // --------------------------------------------------------------
  showButtonModify(item) {
    return (
      !this.state.marksDeleting[item.id] &&
      !item.ceased &&
      !this.state.marksCeasing[item.id] &&
      !this.state.markEditing[item.id] &&
      !this.props.markRenewing[item.id]
    );
  }

  // --------------------------------------------------------------
  showButtonCease(item) {
    return (
      !this.state.marksCeasing[item.id] &&
      !this.state.marksDeleting[item.id] &&
      !item.ceased &&
      !this.state.markEditing[item.id] &&
      !this.props.markRenewing[item.id]
    );
  }

  // --------------------------------------------------------------
  showButtonDelete(item) {
    return (
      !this.state.marksCeasing[item.id] &&
      !this.state.marksDeleting[item.id] &&
      !this.state.markEditing[item.id] &&
      !this.props.markRenewing[item.id] &&
      this.props.operator.role === ADMIN
    );
  }

  // --------------------------------------------------------------
  getBackOfficeUserDetailInitiaVal(item) {
    let result = {
      circolazione: item.circolazione,
      periodo: item.periodo,
      sosta: item.sosta,
      ...item.Vehicles.reduce((accumulator, currentValue, currentIndex) => {
        return {
          ...accumulator,
          ["vehicle" + currentIndex]: currentValue.id + "",
        };
      }, {}),
      addressRoute: item.route,
    };

    return result;
  }  

  // --------------------------------------------------------------
  showPlateNumbers(mark) {
    return (
      <React.Fragment>
        <h5 className="text-left">
          <strong>
            Targ{mark.Vehicles.length > 1 ? "he" : "a"}:&nbsp;
            {mark.Vehicles.map((vehicle, index) => {
              if (vehicle.archived) {
                return (
                  <span key={index} style={{ textDecoration: "line-through" }}>
                    {vehicle.plate_number}&nbsp;
                    <OverlayTrigger
                      shouldUpdatePosition={true}
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-vehicle-0">
                          il veicolo {vehicle.plate_number} è stato eliminato
                        </Tooltip>
                      }
                    >
                      <i className="glyphicon glyphicon-info-sign">{}</i>
                    </OverlayTrigger>
                    &nbsp;
                  </span>
                );
              } else {
                return (
                  <a
                    key={"a-" + index}
                    onClick={() => this.props.onVehicleShortLinkClick(vehicle)}
                  >
                    {vehicle.plate_number}{" "}
                  </a>
                );
              }
            })}
          </strong>
        </h5>

        {mark.virtual && (
          <div className="mark-numberplate-change-button">
            <h5>
              <strong>
                Targa attiva:{" "}
                {mark.ActiveVehicle && mark.ActiveVehicle.archived && (
                  <span style={{ textDecoration: "line-through" }}>
                    {mark.ActiveVehicle.plate_number}&nbsp;
                    <OverlayTrigger
                      shouldUpdatePosition={true}
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-vehicle-1">
                          il veicolo {mark.ActiveVehicle.plate_number} è stato
                          eliminato
                        </Tooltip>
                      }
                    >
                      <i className="glyphicon glyphicon-info-sign">{}</i>
                    </OverlayTrigger>
                  </span>
                )}
                {mark.ActiveVehicle && !mark.ActiveVehicle.archived && (
                  <a
                    onClick={() =>
                      this.props.onVehicleShortLinkClick(mark.ActiveVehicle)
                    }
                  >
                    {mark.ActiveVehicle.plate_number}
                  </a>
                )}
              </strong>
            </h5>

            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <a
                onClick={() => this.props.onShowChooseMarksVehicleModal(mark)}
                className="btn mainBtn"
              >
                MODIFICA TARGA ATTIVA
              </a>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  /**
   * --------------------------------------------------------------
   * @param {*} mark
   * @returns
   */
  getMarkStatus(mark) {
    var result1 = null;
    var result2 = null;
    // aggiornamento paid in Mark

    // -------------------------
    var status = getMarkStatus(mark);

    result1 = (
      <span style={{ color: status.color, fontWeight: "bold", marginLeft: 10 }}>
        {status.status}
      </span>
    );

    if (mark.virtual) {
      result2 = (
        <span style={{ color: "green", fontWeight: "bold", marginLeft: 10 }}>
          {" "}
          VIRTUALE
        </span>
      );
    }

    return (
      <React.Fragment>
        {result1}
        {result2}
      </React.Fragment>
    );
  }

  /**
   * --------------------------------------------------------------
   * @param {*} mark
   * @returns
   */
  getMarkHeaderClass(mark) {
    var result = ["card-marks"];

    var status = getMarkStatus(mark);

    switch (status.status) {
      case STATUS_ACTIVE:
        break;

      case STATUS_CEASED:
        result += " card-marks-c ";
        break;

      case STATUS_RENEWED:
        result += " card-marks-r ";
        break;

      case STATUS_EXPIRED:
        result += " card-marks-e ";
        break;

      case STATUS_WAIT_PAYMENT:
        result += " card-marks-p ";
        break;

      case STATUS_WAIT_APPROVE:
        result += " card-marks-d ";
        break;
    }

    return result;
  }

  /**
   * --------------------------------------------------------------
   * ricavo i prezzi per i permessi
   * @param {*} user
   * @returns
   */
  
  async fetchMarkPrices(user) {
    var prices = [];

    if (user != null && user.marks != null) {
      for (let markKey in user.marks) {
        var mark = user.marks[markKey];

        user.marks[markKey.length - 1].id;

        if (
          // Per i permessi in attesa di approvazione
          ((mark.status == MARK_STATUS_PENDING ||
            mark.status == MARK_STATUS_PENDING_EDITABLE) &&
            mark.active == false) ||
          // per i permessi da rinnovare
          (!mark.ceased &&
            !mark.renewed &&
            //!mark.fatherMark &&
            moment(mark.endDate)
              .startOf("day")
              .diff(moment().startOf("day"), "days") <=
              this.state.markRenewNotifyDays &&
            mark.MarkType.type !== MARK_TYPE_FAMILY)
          // ) ||
          // (mark.id == localStorage.getItem('markIdRenew'))
        ) {
          let markIdNew = mark.id;
          if (localStorage.getItem("markIdRenew")) {            
            markIdNew = localStorage.getItem("markIdRenew");            
          }
          
          var selectedPrice = await SostaOnlineApiService.fetchMarkPriceRecalculate(
            markIdNew
          ).catch((err) => {
            console.log("ERROR:", err);
          });

          if (selectedPrice != null && selectedPrice.data != null) {
            prices[markIdNew] = selectedPrice.data;            
          }
        }
      }

      localStorage.removeItem("markIdRenew");
    }

    return prices;
  }

  /**
   * --------------------------------------------------------------
   * Ricavo i prezzi per i tipi permesso
   * @param {*} user
   * @param {*} markTypes
   * @returns
   */
  async fetchMarkTypePrices(user, markTypes) {    

    let prices = [];

    if (user != null && markTypes != null) {
      for (let markTypeKey in markTypes) {
        var markType = markTypes[markTypeKey];

        prices[markType.id] = null;

        if (
          markType.Prices != null &&
          markType.Prices.length > 0 &&
          user.categories != null &&
          user.categories.length > 0
        ) {
          var userCats = user.categories.filter(
            (category, index) => category.resident == true
          );
          var markTypeCats = markType.Categories.filter(
            (category, index) => category.resident == true
          );

          if (
            userCats != null &&
            userCats.length > 0 &&
            markTypeCats != null &&
            markTypeCats.length > 0
          ) {
            for (let key in markTypeCats) {
              for (let key1 in userCats) {
                if (markTypeCats[key].id == userCats[key1].id) {
                  var selectedPrice = await SostaOnlineApiService.fetchMarkPrice(
                    user.id,
                    markType.id,
                    markTypeCats[key].id,
                    null,
                    null,
                    null
                  ).catch((err) => {
                    console.log("ERROR:", err);
                  });

                  if (selectedPrice != null && selectedPrice.data != null) {
                    prices[markType.id] = selectedPrice.data;
                    break;
                  }
                }
              }

              if (prices[markType.id] != null) {
                break;
              }
            }
          }
        } else {
          prices[markType.id] = { price: markType.price, reasons: [] };
        }
      }
    }

    return prices;
  }

  /**
   * --------------------------------------------------------------
   * @param {*} mark
   * @returns
   */
  getFormatMarkPrice(mark) {    

    var { prices, markPrices } = this.state;

    var result = "";

    // Determinare prezzo tipo permesso
    let priceMarkType = 0;
    this.state.settings.find((element) => {
        if(element.name == 'MarkModifyPrice') {
          priceMarkType = element.value
        }
      }
    )   

    // Se prezzo del "permesso emesso" è uguale al "prezzo tipo permesso" probabilmente è stato eseguito un cambio targa
    // in questo caso il prezzo del cambio targa sostituisce il prezzo del permesso emesso
    // quindi si prende come prezzo quello del "tipo permesso"
    if (markPrices[mark.id] != null && (Number(markPrices[mark.id].price) !== Number(priceMarkType))) {     
      result = Number(markPrices[mark.id].price).toLocaleString("it-IT", {minimumFractionDigits: 2, maximumFractionDigits: 2 });

      if (markPrices[mark.id].reasons != null && markPrices[mark.id].reasons[0] != null) {
        result = result + " ( " + markPrices[mark.id].reasons[0] + " )";
      }
    } else {      
      result = Number(prices[mark.MarkType.id].price).toLocaleString("it-IT", {minimumFractionDigits: 2, maximumFractionDigits: 2 });

      if ( prices[mark.MarkType.id].reasons != null && prices[mark.MarkType.id].reasons[0] != null )
        result = result + " ( " + prices[mark.MarkType.id].reasons[0] + " )";
    }

    return result;
  }

  /**
   * --------------------------------------------------------------
   * @param {*} mark
   * @returns
   */
  async getMarkPrice(mark) {
    var { prices, markPrices } = this.state;    

    var result = "";

    if (markPrices[mark.id] != null) {
      result = markPrices[mark.id].price;
    } else {   
      result = prices[mark.MarkType.id].price;
    }

    return result;
  }

  /**
   * --------------------------------------------------------------
   * @param {*} markTypeId 
   */
  showMarksPersonsPerHouseUnits(show) {
    this.setState({ showMarkPerPersonUnits: show })
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */

  async isMarksPersonsPerHoursActive(marks) {

    var markType = marks
    var isActive = false

    if (markType != null && markType.Prices != null && markType.Prices.length > 0) {     

      var filteredPrices = markType.Prices.filter((price) => price.type == PRICE_TYPE_PERSON_PER_HOUSING_UNIT)

      if (filteredPrices != null && filteredPrices.length > 0){        
        isActive = true
      }
    }

    return isActive
  }

  

  // --------------------------------------------------------------
  render() {    
    const {
      onMarkShortLinkClick,
      highlightObjects,
      findObjectById,      
      onHideChooseMarksVehicleModal,
      showChooseMarksVehicleModal,
      showMarksVehicleModalNumber,
      onVehicleChoosen,
      chooseMarkVehicleError,
      user,
      operator      
    } = this.props;

    const {
      markTypes,
      documentTypes,
      settings,
      prices,
      markPrices,
      loading,
      error,
      result,
      showMarkPerPersonUnits,
    } = this.state;   

    let birthDate = moment(user.birthDate);
    let birthDateRegistry;
    if (this.props.registry.bornDate) {
      birthDateRegistry = moment(this.props.registry.bornDate);
    }

    var residenceAddress = residenceAddressFormat(user);
    var workAddress = workAddressFormat(user);
    var legalAddress = legalAddressFormat(user);

    this.props.showMarksUnit(user.marks, this.state.markTypes);    

    return (
      <div className="panel panel-default">
        <div className="userDetailModal" style={{ padding: "15px" }}>
          {user && user.archived && (
            <div className="alert alert-danger">
              <strong>ATTENZIONE!</strong> Questo utente è stato archiviato e
              non è più utilizzabile.
              <br />
              Qualunque operazione su questo utente non avrà alcun effetto.
            </div>
          )}

          {/* SEZIONE DATI GENERALI UTENTE */}
          <div className="block-info">
            <div className="row">
              <div className="col-md-2">
                {user && (
                  <div style={{ marginLeft: "2rem" }}>
                    <FontAwesomeIcon
                      className="mainIconsNoAction"
                      size="6x"
                      icon={solidIcons.faAddressCard}
                    />
                  </div>
                )}
              </div>
              {/* SEZIONE ANAGRAFICA UTENTE */}
              <div className="col-md-10">
                {user && (
                  <div className="dataUser media-body">
                    <h3 className="title">
                      {user.firstName + " " + user.lastName}
                      &nbsp;&nbsp;
                      <button
                        type="button"
                        className="btn mainBtn"
                        onClick={this.props.onEditProfileClick}
                      >
                        <FontAwesomeIcon size="1x" icon={solidIcons.faEdit} />
                        &nbsp;Modifica
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        className="btn mainBtn"
                        onClick={this.props.onShowUserDocumentsClick}
                      >
                        <FontAwesomeIcon size="1x" icon={solidIcons.faFolder} />
                        &nbsp;Documenti
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        className="btn mainBtn"
                        onClick={() =>
                          this.props.onDownloadUserCredentials(user.marks)
                        }
                      >
                        <i className="glyphicon glyphicon-download">{}</i>{" "}
                        Scarica Dati
                      </button>
                      &nbsp;                      
                      <button type="button" className="btn mainBtn" onClick={() => this.showMarksPersonsPerHouseUnits(true)}>
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip1">Mostra i permessi attivi dei componenti del nucleo abitativo</Tooltip>}>
                          <FontAwesomeIcon size="1x" icon={solidIcons.faUsers} />
                        </OverlayTrigger>
                      </button>                                            
                      <MarksPersonsPerHouseUnitsModal
                        show={showMarkPerPersonUnits}
                        userId={user.id}
                        //markTypeId={this.state.selectedMarkType != null ? this.state.selectedMarkType.id : null}
                        markTypeId={localStorage.getItem("markTypeId")}
                        onClose={() => { this.showMarksPersonsPerHouseUnits(false) }}
                      />
                    </h3>
                    {user.fiscalCode && (
                      <h5>
                        <strong>Codice Fiscale: </strong>
                        {user.fiscalCode + " "}
                        {this.props.registry.fiscalCode && (
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="tooltip1">
                                Questo codice fiscale &egrave; presente
                                nell'Anagrafica Residenti
                              </Tooltip>
                            }
                          >
                            <span
                              style={{ color: "#006f4a" }}
                              className="glyphicon glyphicon-ok"
                            >
                              {}
                            </span>
                          </OverlayTrigger>
                        )}
                        {!this.props.registry.fiscalCode && user.fiscalCode && (
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="tooltip1">
                                Attenzione! Questo codice fiscale non ha alcuna
                                corrispondenza in Anagrafica Residenti
                              </Tooltip>
                            }
                          >
                            <span
                              style={{ color: "#d9534f" }}
                              className="glyphicon glyphicon-remove"
                            >
                              {}
                            </span>
                          </OverlayTrigger>
                        )}
                      </h5>
                    )}
                    {user.firstName && (
                      <h5>
                        <strong>Nome: </strong>
                        {user.firstName + " "}
                        {this.props.registry.firstName &&
                          this.props.registry.firstName.toUpperCase() ===
                            user.firstName.toUpperCase() && (
                            <OverlayTrigger
                              placement="right"
                              overlay={
                                <Tooltip id="tooltip2">
                                  Il nome in Anagrafica Residenti corrisponde a
                                  quello dichiarato in fase di registrazione
                                </Tooltip>
                              }
                            >
                              <span
                                style={{ color: "#006f4a" }}
                                className="glyphicon glyphicon-ok"
                              >
                                {}
                              </span>
                            </OverlayTrigger>
                          )}
                        {this.props.registry.firstName &&
                          this.props.registry.firstName.toUpperCase() !==
                            user.firstName.toUpperCase() && (
                            <OverlayTrigger
                              placement="right"
                              overlay={
                                <Tooltip id="tooltip2">
                                  Il nome in Anagrafica Residenti{" "}
                                  <strong>NON</strong> corrisponde a quello
                                  dichiarato in fase di registrazione: <br />
                                  <strong>
                                    {this.props.registry.firstName}
                                  </strong>
                                </Tooltip>
                              }
                            >
                              <CopyToClipboard
                                text={this.props.registry.firstName}
                                onCopy={() =>
                                  notify.show(
                                    '"' +
                                      this.props.registry.firstName +
                                      '" copiato.',
                                    "custom",
                                    2000,
                                    { background: "#333", text: "white" }
                                  )
                                }
                              >
                                <span
                                  style={{ color: "#d9534f" }}
                                  className="glyphicon glyphicon-remove"
                                >
                                  {}
                                </span>
                              </CopyToClipboard>
                            </OverlayTrigger>
                          )}
                      </h5>
                    )}
                    {user.lastName && (
                      <h5>
                        <strong>Cognome: </strong>
                        {user.lastName + " "}
                        {this.props.registry.lastName &&
                          this.props.registry.lastName.toUpperCase() ===
                            user.lastName.toUpperCase() && (
                            <OverlayTrigger
                              placement="right"
                              overlay={
                                <Tooltip id="tooltip9">
                                  Il Cognome in Anagrafica Residenti corrisponde
                                  a quello dichiarato in fase di registrazione
                                </Tooltip>
                              }
                            >
                              <span
                                style={{ color: "#006f4a" }}
                                className="glyphicon glyphicon-ok"
                              >
                                {}
                              </span>
                            </OverlayTrigger>
                          )}
                        {this.props.registry.lastName &&
                          this.props.registry.lastName.toUpperCase() !==
                            user.lastName.toUpperCase() && (
                            <OverlayTrigger
                              placement="right"
                              overlay={
                                <Tooltip id="tooltip9">
                                  Il Cognome in Anagrafica Residenti{" "}
                                  <strong>NON</strong> corrisponde a quello
                                  dichiarato in fase di registrazione: <br />
                                  <strong>
                                    {this.props.registry.lastName}
                                  </strong>
                                </Tooltip>
                              }
                            >
                              <CopyToClipboard
                                text={this.props.registry.lastName}
                                onCopy={() =>
                                  notify.show(
                                    '"' +
                                      this.props.registry.lastName +
                                      '" copiato.',
                                    "custom",
                                    2000,
                                    { background: "#333", text: "white" }
                                  )
                                }
                              >
                                <span
                                  style={{ color: "#d9534f" }}
                                  className="glyphicon glyphicon-remove"
                                >
                                  {}
                                </span>
                              </CopyToClipboard>
                            </OverlayTrigger>
                          )}
                      </h5>
                    )}
                    {user.birthDate && (
                      <h5>
                        <strong>Data di nascita: </strong>
                        {birthDate.format("L") + " "}
                        {this.props.registry.bornDate &&
                          birthDate.isSame(birthDateRegistry, "day") && (
                            <OverlayTrigger
                              placement="right"
                              overlay={
                                <Tooltip id="tooltip3">
                                  La data di nascita in Anagrafica Residenti
                                  corrisponde a quella dichiarata in fase di
                                  registrazione
                                </Tooltip>
                              }
                            >
                              <span
                                style={{ color: "#006f4a" }}
                                className="glyphicon glyphicon-ok"
                              >
                                {}
                              </span>
                            </OverlayTrigger>
                          )}
                        {this.props.registry.bornDate &&
                          !birthDate.isSame(
                            this.props.registry.bornDate,
                            "day"
                          ) && (
                            <OverlayTrigger
                              placement="right"
                              overlay={
                                <Tooltip id="tooltip3">
                                  La data di nascita in Anagrafica Residenti{" "}
                                  <strong>NON</strong> corrisponde a quella
                                  dichiarata in fase di registrazione: <br />
                                  <strong>
                                    {birthDateRegistry.format("L")}
                                  </strong>
                                </Tooltip>
                              }
                            >
                              <CopyToClipboard
                                text={birthDateRegistry.format("L")}
                                onCopy={() =>
                                  notify.show(
                                    '"' +
                                      birthDateRegistry.format("L") +
                                      '" copiato.',
                                    "custom",
                                    2000,
                                    { background: "#333", text: "white" }
                                  )
                                }
                              >
                                <span
                                  style={{ color: "#d9534f" }}
                                  className="glyphicon glyphicon-remove"
                                >
                                  {}
                                </span>
                              </CopyToClipboard>
                            </OverlayTrigger>
                          )}
                      </h5>
                    )}

                    {user.birthPlace && (
                      <h5>
                        <strong>Luogo di nascita: </strong>
                        {user.birthPlace + " "}
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip id="tooltip4">
                              Luogo di nascita presente in Anagrafica Residenti:
                              <br />{" "}
                              <strong>{this.props.registry.bornPlace}</strong>
                            </Tooltip>
                          }
                        >
                          <CopyToClipboard
                            text={this.props.registry.bornPlace}
                            onCopy={() =>
                              notify.show(
                                '"' +
                                  this.props.registry.bornPlace +
                                  '" copiato.',
                                "custom",
                                2000,
                                { background: "#333", text: "white" }
                              )
                            }
                          >
                            <span
                              style={{ color: "#006f4a" }}
                              className="glyphicon glyphicon-pushpin"
                            >
                              {}
                            </span>
                          </CopyToClipboard>
                        </OverlayTrigger>
                      </h5>
                    )}
                    {user.sex && (
                      <h5>
                        <strong>Sesso:</strong> {user.sex}
                      </h5>
                    )}
                    {residenceAddress && residenceAddress.length > 0 && (
                      <h5>
                        <strong>Indirizzo di residenza:</strong>{" "}
                        {residenceAddress}
                      </h5>
                    )}
                    {user.residenceArea && (
                      <h5>
                        <strong>Area di residenza:</strong>{" "}
                        {user.residenceArea.code}
                      </h5>
                    )}
                    {user.parkSpaceAddress && (
                      <h5>
                        <strong>Indirizzo Posto Auto: </strong>{" "}
                        {user.parkSpaceAddress.name}
                      </h5>
                    )}
                    {user.companyName && (
                      <h5>
                        <strong>Azienda di appartenenza:</strong>{" "}
                        {user.companyName}
                      </h5>
                    )}
                    {legalAddress && legalAddress.length > 0 && (
                      <h5>
                        <strong>Sede Legale Azienda: </strong>
                        {legalAddress}
                      </h5>
                    )}
                    {workAddress && workAddress.length > 0 && (
                      <h5>
                        <strong>Sede Lavoro: </strong>
                        {workAddress}
                      </h5>
                    )}
                    {user.workArea && (
                      <h5>
                        <strong>Area di lavoro:</strong> {user.workArea.code}
                      </h5>
                    )}
                    {user.vatNumber && (
                      <h5>
                        <strong>Partita IVA:</strong> {user.vatNumber}
                      </h5>
                    )}
                    {user.phone && (
                      <h5>
                        <strong>Telefono:</strong> {user.phone}
                      </h5>
                    )}
                    {user.email && (
                      <h5>
                        <strong>Email:</strong> {user.email}&nbsp;
                        {user.userConfirmed && (
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="tooltip1">
                                Email utente confermata
                              </Tooltip>
                            }
                          >
                            <span
                              style={{ color: "#006f4a" }}
                              className="glyphicon glyphicon-ok"
                            >
                              {}
                            </span>
                          </OverlayTrigger>
                        )}
                        {!user.userConfirmed && (
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="tooltip1">
                                Email utente non confermata
                              </Tooltip>
                            }
                          >
                            <span
                              style={{ color: "#d9534f" }}
                              className="glyphicon glyphicon-remove"
                            >
                              {}
                            </span>
                          </OverlayTrigger>
                        )}
                      </h5>
                    )}
                    {this.state.spidEnable && (
                      <h5>
                        <strong>Spid:&nbsp;</strong>
                        {user.spidProfile && (
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="tooltip1">
                                Registrazione Spid attiva
                              </Tooltip>
                            }
                          >
                            <span>
                              <FontAwesomeIcon
                                size="1x"
                                style={{ color: "#006f4a" }}
                                icon={solidIcons.faUser}
                              />
                            </span>
                          </OverlayTrigger>
                        )}
                        {!user.spidProfile && (
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="tooltip1">
                                Registrazione Spid disattiva
                              </Tooltip>
                            }
                          >
                            <span>
                              <FontAwesomeIcon
                                size="1x"
                                style={{ color: "#d9534f" }}
                                icon={solidIcons.faUser}
                              />
                            </span>
                          </OverlayTrigger>
                        )}
                      </h5>
                    )}
                  </div>
                )}
              </div>

              {/* SEZIONE CATEGORIE UTENTE */}
              <div
                style={{ marginTop: "20px" }}
                className="col-md-6 col-md-offset-2"
              >
                {user.categories && user.categories.length > 0 && (
                  <div className="dataUser">
                    <h4>Categorie di questo Utente: </h4>
                    {user.categories.map((item) => (
                      <div key={item.id} className="">
                        <h5 style={{ color: "#174c88" }}>
                          <i className="glyphicon glyphicon-chevron-right pull-left">
                            {}
                          </i>{" "}
                          <strong>
                            {item.label.charAt(0).toUpperCase() +
                              item.label.slice(1)}
                          </strong>
                        </h5>
                        <h5>
                          {item.description.charAt(0).toUpperCase() +
                            item.description.slice(1)}
                        </h5>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* SEZIONE MOSTRA NASCONDI LOG UTENTE */}
              <div
                style={{ marginTop: "20px" }}
                className="col-md-6 col-md-offset-2"
              >
                <button
                  onClick={() =>
                    this.setState({ showUserLogs: !this.state.showUserLogs })
                  }
                  type="button"
                  className="mainBtn"
                >
                  <FontAwesomeIcon size="1x" icon={regularIcons.faFileAlt} />
                  {!this.state.showUserLogs && (
                    <span>&nbsp;&nbsp;Log Utente</span>
                  )}
                  {this.state.showUserLogs && (
                    <span>&nbsp;&nbsp;Nascondi Log Utente</span>
                  )}
                </button>
              </div>
            </div>
          </div>

          {/* SEZIONE LOG UTENTE */}
          {this.state.showUserLogs && (
            <div className="block-info">
              <div className="row">
                <div className="col-md-2">
                  <div style={{ marginLeft: "2rem" }}>
                    <FontAwesomeIcon
                      className="mainIconsNoAction"
                      size="6x"
                      icon={solidIcons.faFileAlt}
                    />
                  </div>
                </div>
                <div className="col-md-10">
                  <h3 className="title">
                    Log Utente{" "}
                    <span
                      className="margin-span"
                      style={{ marginRight: "2rem" }}
                    >
                      {}
                    </span>
                  </h3>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-10">
                  {user && user.id && (
                    <div style={{ margin: 0, padding: 0 }}>
                      <BackOfficeUserDetailLogsTableContainer
                        userId={user.id}
                        style={{ margin: 0, padding: 0 }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* SEZIONE VEICOLI UTENTE */}
          <div className="block-info">
            {/* ICONE E PULSANTI VEICOLI */}
            <div className="row">
              <div className="col-md-2">
                {user.vehicles && (
                  <div style={{ marginLeft: "2rem" }}>
                    <FontAwesomeIcon
                      className="mainIconsNoAction"
                      size="6x"
                      icon={solidIcons.faCar}
                    />
                  </div>
                )}
              </div>
              <div className="col-md-10">
                <h3 className="title">
                  Veicoli{" "}
                  <span className="margin-span" style={{ marginRight: "2rem" }}>
                    {}
                  </span>
                  {!this.state.showCreateVehicle && (
                    <a
                      className="btn mainBtn"
                      onClick={this.onShowCreateVehicleClick.bind(this)}
                    >
                      Nuovo Veicolo
                    </a>
                  )}
                </h3>
              </div>
            </div>
            {/* CREAZIONE NUOVO VEICOLO */}
            <div className="row">
              {this.state.showCreateVehicle && (
                <BackOfficeUserDetailVehicleCreateForm
                  user={user}
                  onVehicleSubmit={this.props.onVehicleSubmit}
                  onHideCreateVehicleClick={this.onHideCreateVehicleClick}
                />
              )}
            </div>
            {/* LISTA VEICOLI UTENTE */}
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-10">
                <div className="cards-container">
                  {user.vehicles
                    ? user.vehicles.map((item) => (
                        <div
                          key={item.id}
                          className={classNames("col-md-6", {
                            "animated flash":
                              highlightObjects.indexOf(`vehicle-${item.id}`) !==
                              -1,
                          })}
                        >
                          <Element name={`vehicle-${item.id}`} />

                          {/* DATI VEICOLO */}
                          {this.state.vehicleEditing &&
                            !this.state.vehicleEditing[item.id] &&
                            user &&
                            user.marks &&
                            markTypes &&
                            documentTypes && (
                              <BackOfficeUserDetailVehicleForm
                                markTypes={markTypes}
                                documentTypes={documentTypes}
                                marks={user.marks}
                                vehicle={item}
                                initialValues={item}
                                operator={operator}
                                vehicleDeleting={this.state.vehicleDeleting}
                                onVehicleEditClick={this.onVehicleEditClick}
                                onDeleteVehicleClick={this.onDeleteVehicleClick}
                                onCancelDeleteVehicleClick={
                                  this.onCancelDeleteVehicleClick
                                }
                                onDeleteVehicle={this.props.onDeleteVehicle}
                                vehiclesDeleteResults={
                                  this.props.vehiclesDeleteResults
                                }
                                onUpdateVehicleChangeRequest={
                                  this.props.onVehicleSubmit
                                }
                              />
                            )}

                          {/* MODIFICA DATI VEICOLO */}
                          {this.state.vehicleEditing &&
                            this.state.vehicleEditing[item.id] && (
                              <BackOfficeUserDetailVehicleEditForm
                                vehicle={item}
                                onCloseVehicleEdit={this.onCloseVehicleEdit.bind(
                                  this
                                )}
                                status={
                                  this.props.vehiclesEditsResults[item.id]
                                }
                                initialValues={item}
                                onCancelClick={() =>
                                  this.onVehicleCancelEditClick(item)
                                }
                                onSubmit={(values) =>
                                  this.props.onEditVehicleSubmit(values)
                                }
                                operator={operator}
                              />
                            )}
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </div>
          </div>

          {/* SEZIONE PERMESSI UTENTE */}
          <div className="block-info">
            {/* INTESTAZIONE E PULSANTI PERMESSI */}
            <div className="row">
              <div className="col-md-2">
                {user.marks && (
                  <div style={{ marginLeft: "2rem" }}>
                    <FontAwesomeIcon
                      className="mainIconsNoAction"
                      size="6x"
                      icon={solidIcons.faWallet}
                    />                    
                  </div>
                )}
              </div>
              <div className="col-md-10">
                <h3 className="title">
                  Permessi{" "}
                  <span
                    className="margin-span"
                    style={{ marginRight: "2rem" }}
                  />
                  {!this.state.showCreateMark && (
                    <a
                      className="btn mainBtn"
                      onClick={this.onShowCreateMarkClick.bind(this)}
                    >
                      Nuovo Permesso
                    </a>
                  )}
                </h3>
              </div>
            </div>

            {/* SEZIONE CREAZIONE PERMESSO */}
            <div className="row" style={{ marginBottom: "2vh" }}>
              <div className="col-md-2"></div>
              {this.state.showCreateMark && (
                <div className="static-modal col-md-10">
                  <div className="my-modal1" style={{ display: "block" }}>
                    <div
                      className="modal-dialog"
                      style={{
                        marginTop: "0",
                        marginLeft: "0",
                        marginRight: "0",
                      }}
                    >
                      <div className="modal-content my-modal2" role="document">
                        <div className="modal-header">
                          <div className="row justify-content-between">
                            <div className="col-md-6">
                              <strong>Crea Permesso:</strong>
                            </div>
                            <div className="col-md-offset-11 text-center">
                              {this.state.showCreateMark && (
                                <a
                                  className="glyphicon glyphicon-remove chiudi-btn"
                                  onClick={this.onHideCreateMarkClick.bind(
                                    this
                                  )}
                                >
                                  {}
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="modal-body">
                          {this.state.showCreateMark && (
                            <div className="text-center">
                              <BackOfficeUserDetailMarkForm
                                addresses={this.props.addresses}
                                initialValues={{ quantity: 1, total: 0 }}
                                loading={this.props.markLoading}
                                markSuccess={this.props.markSuccess}
                                markCreateError={this.props.markError}
                                onUserCategorySelected={this.props.onCreateMarkUserCategorySelected}
                                areas={this.props.areas}
                                markTypes={this.props.markTypes}
                                user={user}
                                onSubmit={this.props.onMarkSubmit}
                                onResetMarkSubmitForm={
                                  this.props.onResetMarkSubmitForm
                                }
                                settings={settings}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="row">
              <div className="col-md-2"></div>

              {/* SEZIONE LISTA PERMESSI */}
              <div className="col-md-10">
                <div className="cards-container">
                  {user.marks
                    ? user.marks.map((item) => {

                        this.onCheckReceipt(item.id)

                        const statoAbbonamento = getMarkStatus(item);
                        const situazionePagamento = (
                          <MarkPaymentStatus markId={item.id} />
                        );
                        const fatherMark = item.fatherMark
                          ? findObjectById(item.fatherMark, user.marks)
                          : null;
                        const markZone = getMarkZone(user, item)
                        
                        const checkNumberOrderPayment = this.numberOrderPayment(user, item.id)

                        if(item.MarkType) {
                         console.log('item.MarkType.pagopaSpont: ' , item.MarkType.pagopaSpont);
                        } else {
                          console.log('item.MarkType.pagopaSpont: ' , item);
                        }

                        return (
                          <div key={`mark-${item.id}`}>
                            {/*  SEZIONE PERMESSI ATTIVI */}
                            {item.active && (
                              <div
                                className={classNames("col-md-6", {
                                  "animated flash":
                                    highlightObjects.indexOf(
                                      `mark-${item.id}`
                                    ) !== -1,
                                })}
                              >
                                <Element name={`mark-${item.id}`} />
                                <div className={this.getMarkHeaderClass(item)}>
                                  {/* DATI GENERALI PERMESSO */}
                                  <OverlayTrigger
                                    shouldUpdatePosition={true}
                                    placement="left"
                                    overlay={
                                      <Tooltip id="tooltip6">
                                        <BackOfficeUserDetailMarkLogsContainer
                                          markId={item.id}
                                        />
                                      </Tooltip>
                                    }
                                  >
                                    <div className="icon-tooltip-div">
                                      <i className="glyphicon glyphicon-info-sign">
                                        {}
                                      </i>
                                    </div>
                                  </OverlayTrigger>
                                  {this.props.downloading && (
                                    <div
                                      className="caption"
                                      style={{ padding: 10, maxHeight: 80 }}
                                    >
                                      <div className="row text-center">
                                        <h4>Caricamento in corso...</h4>
                                      </div>
                                      <div
                                        className="row"
                                        style={{
                                          padding: "2em",
                                          height: "40em",
                                        }}
                                      >
                                        <Oval
                                          secondaryColor="#02afff"
                                          color="#174c88"
                                          height={40}
                                          width="100%"
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {!this.props.downloading && (
                                    <div
                                      className="caption"
                                      style={{ padding: 10 }}
                                    >
                                      <h4>
                                        <strong style={{color: item.MarkType.color == null || item.MarkType.color.toUpperCase() == "#FFFFFF" ? "#5a5a5a" : item.MarkType.color}}>
                                          N° {item.id}&nbsp;&nbsp;
                                          {item.MarkType.label}
                                        </strong>
                                        <strong>
                                          &nbsp;&nbsp;{this.getMarkStatus(item)}
                                        </strong>
                                      </h4>
                                      <h5>
                                        <strong>
                                          {item.Category.label.toUpperCase()}
                                        </strong>
                                      </h5>

                                      {
                                        /* DATI GENERALI PAGAMENTO */                                
                                      }
                                      {!loading ? (
                                        <h5 className="small" style={{marginTop: "2vh", marginBottom: "2vh", }}>
                                          {statoAbbonamento.status == STATUS_WAIT_PAYMENT ? (
                                            situazionePagamento !=
                                              "No data available" ||
                                            situazionePagamento != null ||
                                            situazionePagamento != undefined ||
                                            situazionePagamento !=
                                              "Loading..." ? (
                                              <div className="row table-responsive bg-warning" style={{paddingBlock: "10px", margin: "-10px"}}>
                                                <div className="col-md-12 text-left">
                                                  {ADMIN ? (
                                                    <div>
                                                      <label>
                                                        Situazione XPay GestPay
                                                        Nexi
                                                      </label>
                                                      <MarkPaymentStatus
                                                        markId={item.id}
                                                        updateCheckPaymentStatus={
                                                          this.props
                                                            .updateCheckPaymentStatus
                                                        }
                                                      />
                                                    </div>
                                                  ) : (
                                                    <label>
                                                      Pagamento in corso di
                                                      completamento
                                                    </label>
                                                  )}
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </h5>
                                      ) : (
                                        ""
                                      )}

                                      {item.fatherMark && (
                                        <h5>
                                          <strong>
                                            Derivato da N°:{" "}
                                            <a onClick={() => onMarkShortLinkClick(item)}>
                                              {item.fatherMark}
                                            </a>
                                          </strong>
                                        </h5>
                                      )}

                                      {item.note && item.ceased && (
                                        <h5>
                                          <strong>Motivo rifiuto:</strong>{" "}
                                          {item.note}
                                        </h5>
                                      )}

                                      <h5>
                                        <strong>Zona: {markZone}</strong>
                                      </h5>

                                      {this.showPlateNumbers(item)}

                                      {item.issuedDate &&
                                        statoAbbonamento.status == "ATTIVO" && (
                                          <h5>
                                            <strong>Emesso il: </strong>{" "}
                                            {moment(item.issuedDate).format(
                                              "L"
                                            )}
                                          </h5>
                                        )}

                                        {checkNumberOrderPayment.numberOrderPayment && (
                                          <h5>
                                            <strong>Codice pagamento: </strong>{" "}
                                              {checkNumberOrderPayment.numberOrderPayment && (
                                              checkNumberOrderPayment.numberOrderPayment
                                            )}
                                          </h5>
                                        )}

                                      {((item.paid != null && item.paid) ||
                                        item.price == null ||
                                        parseFloat(item.price) <= 0 ||
                                        item.status == "CEASED") && (
                                        <h5>
                                          <strong>Valido</strong>
                                          {item.startDate && (
                                            <React.Fragment>
                                              <strong> dal </strong>{" "}
                                              {moment(item.startDate).format(
                                                "L"
                                              )}{" "}
                                            </React.Fragment>
                                          )}
                                          {item.endDate && (
                                            <React.Fragment>
                                              <strong> al </strong>{" "}
                                              {moment(item.endDate).format("L")}{" "}
                                            </React.Fragment>
                                          )}
                                        </h5>
                                      )}

                                      {(item.paid == null ||
                                        item.paid == false) &&
                                        item.price != null &&
                                        parseFloat(item.price) > 0 &&
                                        item.status != "CEASED" && (
                                          <h5>
                                            <strong>Valido </strong>
                                            {getSelectedStartDate(item) && (
                                              <React.Fragment>
                                                {" "}
                                                <strong> dal </strong>
                                                {getSelectedStartDate(
                                                  item
                                                ).format("L")}{" "}
                                              </React.Fragment>
                                            )}
                                            {getSelectedEndDate(
                                              item,
                                              settings
                                            ) && (
                                              <React.Fragment>
                                                {" "}
                                                <strong> fino al </strong>
                                                {getSelectedEndDate(item, settings).format("L")}
                                              </React.Fragment>
                                            )}
                                          </h5>
                                        )}

                                      {/* SEZIONE PULSANTI RINNOVA MODIFICA CESSA ELIMINA */}
                                      {(this.showButtonRenew(item) ||
                                        this.showButtonModify(item) ||
                                        this.showButtonCease(item) ||
                                        this.showButtonDelete(item)) && (
                                        <div>
                                          <br />
                                          {/* this.mark.ActiveVehicle && !this.mark.ActiveVehicle.archived && */}                                                                              
                                          {this.showButtonRenew(item) && (
                                            <button
                                              onClick={() =>
                                                this.props.onRenewMarkClick(
                                                  item
                                                )
                                              }
                                              type="button"
                                              className="btn btn-success btn-xs btn-update btn-add-card"
                                            >
                                              Rinnova
                                            </button>
                                          )}
                                          {this.showButtonModify(item) && (
                                            <button
                                              onClick={() =>
                                                this.onEditMarkClick(item)
                                              }
                                              type="button"
                                              className="btn btn-xs btn-update btn-add-card"
                                            >
                                              <FontAwesomeIcon
                                                size="1x"
                                                icon={solidIcons.faEdit}
                                              />
                                              &nbsp;Modifica
                                            </button>
                                          )}
                                          {this.showButtonCease(item) && (
                                            <button
                                              onClick={() =>
                                                this.onCeaseMarkClick(item)
                                              }
                                              type="button"
                                              className="btn btn-danger btn-xs btn-update btn-add-card"
                                            >
                                              <FontAwesomeIcon
                                                size="1x"
                                                icon={solidIcons.faCircleStop}
                                              />
                                              &nbsp;Cessa
                                            </button>
                                          )}
                                          {this.showButtonDelete(item) && (
                                            <button
                                              onClick={() =>
                                                this.onDeleteMarkClick(item)
                                              }
                                              type="button"
                                              className="btn btn-danger btn-xs btn-update btn-add-card"
                                            >
                                              <FontAwesomeIcon
                                                size="1x"
                                                icon={solidIcons.faTrash}
                                              />
                                              &nbsp;Elimina
                                            </button>
                                          )}
                                          <br />
                                          <br />
                                        </div>
                                      )}

                                      {/* SEZIONE PAGAMENTI */}
                                      {this.props.markRenewing[item.id] && (
                                        <div>
                                          <div className="row">
                                            <div className="col-md-12 text-left">
                                              <label>
                                                Conferma Rinnovo Permesso.
                                              </label>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-12 text-left">
                                              <label>Totale</label>

                                              <div style={{ marginTop: 12 }}>
                                                {(markPrices[item.id] != null || prices[item.MarkType.id] != null) &&
                                                  <h4>€{" "} { this.getFormatMarkPrice(item) }</h4>
                                                }
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-12 text-left">
                                              <label>Metodo di Pagamento</label>
                                              <div className="radio">
                                                <label>
                                                  <input
                                                    type="radio"
                                                    value="SPORTELLO_POS"
                                                    checked={
                                                      this.props
                                                        .renewPaymentMethodSelected ===
                                                      "SPORTELLO_POS"
                                                    }
                                                    onChange={
                                                      this.props
                                                        .onRenewPaymentMethodChange
                                                    }
                                                  />{" "}
                                                  POS
                                                </label>
                                              </div>
                                              {this.state.enableCash && (
                                                <div className="radio">
                                                  <label>
                                                    <input
                                                      type="radio"
                                                      value="SPORTELLO_CONTANTI"
                                                      checked={
                                                        this.props
                                                          .renewPaymentMethodSelected ===
                                                        "SPORTELLO_CONTANTI"
                                                      }
                                                      onChange={
                                                        this.props
                                                          .onRenewPaymentMethodChange
                                                      }
                                                    />{" "}
                                                    CONTANTI
                                                  </label>
                                                </div>
                                              )}
                                              {this.state.enablePagoPa && (
                                                <div className="radio">
                                                  <label>
                                                    <input
                                                      type="radio"
                                                      value="SPORTELLO_PAGOPA"
                                                      checked={
                                                        this.props
                                                          .renewPaymentMethodSelected ===
                                                        "SPORTELLO_PAGOPA"
                                                      }
                                                      onChange={
                                                        this.props
                                                          .onRenewPaymentMethodChange
                                                      }
                                                    />{" "}
                                                    PAGOPA
                                                  </label>
                                                </div>
                                              )}
                                              {this.state.enableReclaim && (
                                                <div className="radio">
                                                  <label>
                                                    <input
                                                      type="radio"
                                                      value="SPORTELLO_BONIFICO"
                                                      checked={
                                                        this.props
                                                          .renewPaymentMethodSelected ===
                                                        "SPORTELLO_BONIFICO"
                                                      }
                                                      onChange={
                                                        this.props
                                                          .onRenewPaymentMethodChange
                                                      }
                                                    />{" "}
                                                    BONIFICO
                                                  </label>
                                                </div>
                                              )}
                                            </div>

                                            {this.props.markRenewingErrors[
                                              item.id
                                            ] && (
                                              <div className="text-danger text-center">
                                                {
                                                  this.props.markRenewingErrors[
                                                    item.id
                                                  ]
                                                }
                                              </div>
                                            )}
                                          </div>
                                          <button 
                                            type="button"
                                            onClick={async () =>
                                              this.props.onMarkRenew({
                                                ...item,
                                                price: await this.getMarkPrice(
                                                  item
                                                ),
                                              })
                                            }
                                            className="btn btn-success btn-xs btn-update btn-add-card"
                                          >Rinnova</button>
                                          <button
                                            type="button"
                                            onClick={() =>
                                              this.props.onCancelRenewMarkClick()
                                            }
                                            className="btn btn-default btn-xs btn-update btn-add-card"
                                          >
                                            Annulla Rinnovo
                                          </button>
                                        </div>
                                      )}

                                      {/* SEZIONE MODIFICA PERMESSI */}
                                      {(this.state.markEditing[item.id] ||
                                        this.state.editMarkError != null) && (
                                        <div>
                                          <BackOfficeUserDetailEditMarkForm
                                            markTypeId={item.MarkType.id}
                                            mark={item}
                                            addresses={this.props.addresses}
                                            initialValues={this.getBackOfficeUserDetailInitiaVal(
                                              item
                                            )}
                                            onCancel={() =>
                                              this.onCancelEditMarkClick()
                                            }
                                            loading={this.props.markLoading}
                                            areas={this.props.areas}
                                            user={user}
                                            area={markZone}
                                            editMarkError={
                                              this.state.editMarkError
                                            }
                                            onSubmit={async (values) => {
                                              await this.props.onMarkEditSubmit(
                                                values
                                              );
                                              !this.state.editMarkError &&
                                                this.onCancelEditMarkClick();
                                            }}
                                          />
                                        </div>
                                      )}

                                      {/* SEZIONE PULSANTI CONFERME CESSA */}
                                      {this.state.marksCeasing[item.id] && (
                                        <div>
                                          <div
                                            className="alert alert-danger"
                                            style={{
                                              marginTop: "2vh",
                                              marginBottom: "2vh",
                                              marginLeft: "10%",
                                              textAlign: "center",
                                              width: "80%",
                                            }}
                                          >
                                            Confermi di voler cessare questo
                                            permesso?
                                          </div>
                                          {this.props.markCeaseResults[
                                            item.id
                                          ] === "loading" && (
                                            <span>
                                              Cessazione del permesso in
                                              corso...
                                            </span>
                                          )}
                                          {this.props.markCeaseResults[
                                            item.id
                                          ] === "error" && (
                                            <span>
                                              Errore durante la cessazione del
                                              permesso
                                            </span>
                                          )}
                                          {this.props.markCeaseResults[
                                            item.id
                                          ] !== "success" && (
                                            <div>
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  this.onCeaseMark(item)
                                                }
                                                className="btn btn-danger btn-xs btn-update btn-add-card"
                                              >
                                                <FontAwesomeIcon
                                                  size="1x"
                                                  icon={solidIcons.faTrash}
                                                />
                                                &nbsp;Cessa Permesso
                                              </button>
                                              <button
                                                onClick={() =>
                                                  this.onCancelCeaseMarkClick()
                                                }
                                                type="button"
                                                className="btn btn-default btn-xs btn-update btn-add-card"
                                              >
                                                {" "}
                                                Annulla Cessazione
                                              </button>
                                            </div>
                                          )}
                                          {this.props.markCeaseResults[
                                            item.id
                                          ] === "success" && (
                                            <div>
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  this.onCloseMarkCease()
                                                }
                                                className="btn btn-danger btn-xs btn-update btn-add-card"
                                              >
                                                Chiudi
                                              </button>
                                            </div>
                                          )}

                                          <br />
                                          <br />
                                        </div>
                                      )}

                                      {/* SEZIONE PULSANTI CONFERME ELIMINA */}
                                      {this.state.marksDeleting[item.id] && (
                                        <div>
                                          <div
                                            className="alert alert-danger"
                                            style={{
                                              marginTop: "2vh",
                                              marginBottom: "2vh",
                                              marginLeft: "10%",
                                              textAlign: "center",
                                              width: "80%",
                                            }}
                                          >
                                            Confermi di voler eliminare questo
                                            permesso?
                                          </div>
                                          {this.props.markDeleteResults[
                                            item.id
                                          ] === "loading" && (
                                            <span>
                                              Eliminazione del permesso in
                                              corso...
                                            </span>
                                          )}
                                          {this.props.markDeleteResults[
                                            item.id
                                          ] === "error" && (
                                            <span>
                                              Errore durante l{"'"}eliminazione
                                              del permesso
                                            </span>
                                          )}
                                          <button
                                            type="button"
                                            onClick={() =>
                                              this.props.onDeleteMark(item)
                                            }
                                            className="btn btn-danger btn-xs btn-update btn-add-card"
                                          >
                                            <FontAwesomeIcon
                                              size="1x"
                                              icon={solidIcons.faTrash}
                                            />
                                            &nbsp;Elimina Permesso
                                          </button>
                                          <button
                                            onClick={() =>
                                              this.onCancelDeleteMarkClick()
                                            }
                                            type="button"
                                            className="btn btn-default btn-xs btn-update btn-add-card"
                                          >
                                            {" "}
                                            Annulla Eliminazione
                                          </button>
                                          <br />
                                          <br />
                                        </div>
                                      )}

                                     
                                      {/* SEZIONE PULSANTI PERMESSO ACCETTAZIONE RICEVUTA */}
                                      {!this.state.markEditing[item.id] && (
                                        <div className="button-download">
                                          {!item.virtual && (
                                            <a
                                              onClick={() =>
                                                this.props.onDownloadMark(item)
                                              }
                                              className="btn neutralBtn"
                                            >
                                              <i className="glyphicon glyphicon-download">
                                                {}
                                              </i>{" "}
                                              Permesso
                                            </a>
                                          )}
                                          <a
                                            onClick={() =>
                                              this.props.onDownloadMarkRelease(
                                                item
                                              )
                                            }
                                            className="btn neutralBtn"                                            
                                          >
                                            <i className="glyphicon glyphicon-download">
                                              {}
                                            </i>{" "}
                                            Accettazione
                                          </a>                                          
                                          {/* {(this.onCheckReceipt(item.id)!==true) && (
                                            <a
                                              onClick={() =>
                                                this.props.onMarkReceiptDownload(
                                                  item
                                                )
                                              }
                                              className="btn neutralBtn"
                                            >
                                              <i className="glyphicon glyphicon-download">
                                                {}
                                              </i>{" "}
                                              Ricevuta !!!
                                            </a>
                                          )} */}
                                          {(localStorage.getItem("checkReceipt"+item.id) === 'true') && (
                                            <a
                                              onClick={() =>
                                                this.props.onMarkReceiptDownload(
                                                  item
                                                )
                                              }
                                              className="btn neutralBtn"                                              
                                            >
                                              <i className="glyphicon glyphicon-download">
                                                {}
                                              </i>{" "}
                                              Ricevuta                                                                                       
                                            </a>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}

                            {/*  SEZIONE PERMESSI NON ATTIVI / IN ATTESA DI APPROVAZIONE */}
                            {!item.active && (
                              <div
                                className={classNames("col-md-6", {
                                  "animated flash":
                                    highlightObjects.indexOf(
                                      `mark-${item.id}`
                                    ) !== -1,
                                })}
                              >
                                <Element name={`mark-${item.id}`} />
                                <div className={this.getMarkHeaderClass(item)}>
                                  {/* DATI GENERALI PERMESSO */}

                                  <OverlayTrigger
                                    placement="left"
                                    shouldUpdatePosition={true}
                                    overlay={
                                      <Tooltip id="tooltip7">
                                        <BackOfficeUserDetailMarkLogsContainer
                                          markId={item.id}
                                        />
                                      </Tooltip>
                                    }
                                  >
                                    <div className="icon-tooltip-div">
                                      <i className="glyphicon glyphicon-info-sign">
                                        {}
                                      </i>
                                    </div>
                                  </OverlayTrigger>
                                  <div
                                    className="caption capiton-temporaney"
                                    style={{ padding: 10 }}
                                  >
                                    <h4>
                                      <strong
                                        style={{
                                          color:
                                            item.MarkType.color == null ||
                                            item.MarkType.color.toUpperCase() ==
                                              "#FFFFFF"
                                              ? "#5a5a5a"
                                              : item.MarkType.color,
                                        }}
                                      >
                                        N° {item.id}&nbsp;&nbsp;
                                        {item.MarkType.label}&nbsp;
                                      </strong>
                                      {fatherMark && fatherMark.renewed ? (
                                        <strong style={{ color: "orange" }}>
                                          RINNOVO PERMESSO IN{" "}
                                          {STATUS_WAIT_APPROVE}
                                        </strong>
                                      ) : (
                                        <strong style={{ color: "orange" }}>
                                          PERMESSO IN {STATUS_WAIT_APPROVE}
                                        </strong>
                                      )}

                                      {item.ceased && (
                                        <span
                                          style={{
                                            color: "red",
                                            marginLeft: 10,
                                          }}
                                        >
                                          {" "}
                                          {STATUS_CEASED}
                                        </span>
                                      )}
                                    </h4>

                                    <h5>
                                      <strong>
                                        {item.Category.label.toUpperCase()}
                                      </strong>
                                    </h5>

                                    {item.fatherMark && (
                                      <h5>
                                        <strong>
                                          Derivato da N°:{" "}
                                          <a
                                            onClick={() =>
                                              onMarkShortLinkClick(item)
                                            }
                                          >
                                            {item.fatherMark}
                                          </a>
                                        </strong>
                                      </h5>
                                    )}

                                    <h5>
                                      <strong>Zona: {markZone}</strong>
                                    </h5>

                                    {this.showPlateNumbers(item)}

                                    <h5>
                                      <strong>Richiesto il:</strong>{" "}
                                      {moment(item.createdAt).format("L")}
                                    </h5>

                                    {item.MarkType && (
                                      <h5>
                                        <strong>Valido </strong>
                                        {/* {getSelectedStartDate(item) && <React.Fragment> <strong> dal  </strong>{getSelectedStartDate(item).format("L")}</React.Fragment>} */}
                                        {getSelectedStartDate(item) && (
                                          <React.Fragment>
                                            {" "}
                                            <strong> dal </strong>
                                            {getSelectedStartDate(
                                              item,
                                              settings
                                            ).format("L")}
                                          </React.Fragment>
                                        )}
                                        {getSelectedEndDate(item, settings) && (
                                          <React.Fragment>
                                            {" "}
                                            <strong> fino al </strong>
                                            {checkEndDate(
                                              getSelectedEndDate(
                                                item,
                                                settings
                                              ).format("L")
                                            )}
                                          </React.Fragment>
                                        )}
                                      </h5>
                                    )}
                                    {(markPrices[item.id] != null ||
                                      prices[item.MarkType.id] != null) && (
                                      <h5>
                                        <strong>Prezzo: </strong> €{" "}
                                        {this.getFormatMarkPrice(item)}
                                      </h5>
                                    )}
                                    <div className="row">
                                      <div className="form-group col-md-4">
                                        <label>Circolazione</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={
                                            this.state.marksApprovations[
                                              item.id
                                            ]
                                              ? this.state.marksApprovations[
                                                  item.id
                                                ].circolazione
                                              : item.circolazione != null
                                              ? item.circolazione
                                              : ""
                                          }
                                          onChange={(e) =>
                                            this.onMarkApprovationCircolazioneChange(
                                              item.id,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="form-group col-md-4">
                                        <label>Periodo</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={
                                            this.state.marksApprovations[
                                              item.id
                                            ]
                                              ? this.state.marksApprovations[
                                                  item.id
                                                ].periodo
                                              : item.periodo != null
                                              ? item.periodo
                                              : ""
                                          }
                                          onChange={(e) =>
                                            this.onMarkApprovationPeriodoChange(
                                              item.id,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="form-group col-md-4">
                                        <label>Sosta</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={
                                            this.state.marksApprovations[
                                              item.id
                                            ]
                                              ? this.state.marksApprovations[
                                                  item.id
                                                ].sosta
                                              : item.sosta != null
                                              ? item.sosta
                                              : ""
                                          }
                                          onChange={(e) =>
                                            this.onMarkApprovationSostaChange(
                                              item.id,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </div>

                                    {/* SEZIONE REVISIONE DOCUMENTI */}
                                    {/* Controlla il numero dei documenti OBBLIGATORI (mandatory == true) */}
                                    {item.MarkType.DocumentTypes.length > 0 &&
                                      Number(
                                        this.checkNumberDocumentMandatory(
                                          item.MarkType.DocumentTypes
                                        )
                                      ) > 0 &&
                                      ((fatherMark && !fatherMark.renewed) ||
                                        !fatherMark) && (
                                        <div style={{ marginBottom: "15px" }}>
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Tooltip id="tooltip8">
                                                <div
                                                  style={{
                                                    padding: "10px",
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  <div>
                                                    Totale documenti richiesti:{" "}
                                                    <strong>
                                                      {
                                                        item.documentsReview
                                                          .totalDocumentsRequested
                                                      }
                                                    </strong>
                                                  </div>
                                                  <div>
                                                    Totale documenti Caricati:{" "}
                                                    <strong>
                                                      {
                                                        item.documentsReview
                                                          .totalDocumentsUploaded
                                                      }
                                                    </strong>
                                                  </div>
                                                  <div
                                                    style={{
                                                      marginBottom: "10px",
                                                    }}
                                                  >
                                                    Totale documenti Approvati:{" "}
                                                    <strong
                                                      className={classNames({
                                                        "text-danger":
                                                          item.documentsReview
                                                            .totalDocumentsApproved !==
                                                          item.documentsReview
                                                            .totalDocumentsRequested,
                                                      })}
                                                    >
                                                      {
                                                        item.documentsReview
                                                          .totalDocumentsApproved
                                                      }
                                                    </strong>
                                                  </div>
                                                  {item.documentsReview.documentTypes.map(
                                                    (
                                                      documentTypeItem,
                                                      documentTypeIndex
                                                    ) => {
                                                      return (
                                                        <div
                                                          key={`mark-${item.id}-documents-review-${documentTypeIndex}`}
                                                        >
                                                          {
                                                            documentTypeItem.label
                                                          }
                                                          :{" "}
                                                          {documentTypeItem
                                                            .documents.length >
                                                          0 ? (
                                                            documentTypeItem.documents.map(
                                                              (
                                                                documentItem,
                                                                documentIndex
                                                              ) => {
                                                                return (
                                                                  <span
                                                                    key={`mark-${item.id}-documents-review-${documentTypeIndex}-document-${documentIndex}`}
                                                                  >
                                                                    {documentItem.documentApproved ? (
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "#006f4a",
                                                                        }}
                                                                        className="glyphicon glyphicon-ok"
                                                                      />
                                                                    ) : (
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "#d9534f",
                                                                        }}
                                                                        className="glyphicon glyphicon-remove"
                                                                      />
                                                                    )}
                                                                    <br />
                                                                  </span>
                                                                );
                                                              }
                                                            )
                                                          ) : (
                                                            <span
                                                              style={{
                                                                color:
                                                                  "#d9534f",
                                                              }}
                                                              className="glyphicon glyphicon-remove"
                                                            />
                                                          )}
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              </Tooltip>
                                            }
                                          >
                                            {// item.documentsReview.totalDocumentsRequested === item.documentsReview.totalDocumentsUploaded &&
                                            //   item.documentsReview.totalDocumentsApproved === item.documentsReview.totalDocumentsRequested ? (
                                            Number(this.checkNumberDocumentMandatory(item.MarkType.DocumentTypes)) === item.documentsReview.totalDocumentsUploaded &&
                                            item.documentsReview.totalDocumentsApproved === Number(this.checkNumberDocumentMandatory(item.MarkType.DocumentTypes)) ? (
                                              <span className="text-success">
                                                Verifica dei documenti Completa
                                                <span
                                                  style={{
                                                    color: "#006f4a",
                                                    marginLeft: "8px",
                                                    marginRight: "8px",
                                                  }}
                                                  className="glyphicon glyphicon-ok"
                                                />
                                              </span>
                                            ) : (
                                              <span className="text-danger">
                                                Verifica dei documenti necessaria
                                                <span
                                                  style={{
                                                    color: "#d9534f",
                                                    marginLeft: "8px",
                                                    marginRight: "8px",
                                                  }}
                                                  className="glyphicon glyphicon-remove"
                                                />
                                              </span>
                                            )}
                                          </OverlayTrigger>

                                          <a
                                            className="btn btn-file-browser-confirm"
                                            onClick={() =>
                                              this.props.onOpenReviewDocumentsModal(
                                                item
                                              )
                                            }
                                          >
                                            Revisiona Documenti
                                          </a>
                                          {this.props.reviewingMark &&
                                            this.props
                                              .onCloseReviewDocumentsModal &&
                                            this.props
                                              .showReviewDocumentsModal && (
                                              <BackofficeMarkDocumentsReviewContainer
                                                onApprovationChanged={
                                                  this.props
                                                    .onApprovationChanged
                                                }
                                                userName={
                                                  user.firstName +
                                                  "_" +
                                                  user.lastName
                                                }
                                                onClose={
                                                  this.props
                                                    .onCloseReviewDocumentsModal
                                                }
                                                open={
                                                  this.props
                                                    .showReviewDocumentsModal
                                                }
                                                mark={this.props.reviewingMark}
                                              />
                                            )}
                                        </div>
                                      )}

                                    {/* SEZIONE NOTE  */}
                                    {item.MarkNotes.length > 0 && (
                                      <div
                                        className="row text-left"
                                        style={{ marginBottom: "1em" }}
                                      >
                                        <div
                                          className="row text-left"
                                          style={{ marginLeft: "1em" }}
                                        >
                                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-left ">
                                            <h5 className="text-info">
                                              <strong>
                                                Note di questo permesso:
                                              </strong>
                                            </h5>
                                          </div>
                                        </div>
                                        {item.MarkNotes.map((note, index) => {
                                          return (
                                            <React.Fragment
                                              key={"note-" + note.id}
                                            >
                                              {index === 0 && (
                                                <div
                                                  key="note-header"
                                                  className="row noteRow"
                                                >
                                                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-2 col-xs-3 text-left ">
                                                    <strong>DATA</strong>
                                                  </div>
                                                  <div className="col-xl-9 col-lg-9 col-md-9 col-sm-10 col-xs-9 text-left">
                                                    <strong>NOTA</strong>
                                                  </div>
                                                </div>
                                              )}

                                              <div
                                                key={"note-" + note.id}
                                                className="row noteRow"
                                              >
                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-2 col-xs-3 text-left ">
                                                  {moment(
                                                    note.createdAt
                                                  ).fromNow()}
                                                </div>
                                                <div className="col-xl-9 col-lg-9 col-md-9 col-sm-10 col-xs-9 text-left ">
                                                  {note.notes}
                                                </div>
                                              </div>
                                            </React.Fragment>
                                          );
                                        })}
                                      </div>
                                    )}

                                    {/* SEZIONE PULSANTI ATTIVA / ATTESA / NEGA  */}
                                    {!this.state.marksDenyingApprovation[item.id] && !this.state.marksListingInWait[item.id] 
                                    && (
                                        <div>                                                                                    
                                          {/* {Number(this.checkNumberDocumentMandatory(item.MarkType.DocumentTypes)) === item.documentsReview.totalDocumentsUploaded &&
                                           item.documentsReview.totalDocumentsApproved ===  Number(this.checkNumberDocumentMandatory(item.MarkType.DocumentTypes)) &&
                                            ( */}
                                              <button
                                                onClick={async () => { await this.props.onMarkActivateClick({...item, circolazione: this.state.marksApprovations[item.id] != null ? this.state.marksApprovations[item.id].circolazione : item.circolazione != null ? item.circolazione: "",
                                                      periodo: this.state.marksApprovations[item.id] != null ? this.state.marksApprovations[item.id].periodo : item.periodo != null ? item.periodo : "",
                                                      sosta: this.state.marksApprovations[item.id] != null ? this.state.marksApprovations[item.id].sosta : item.sosta != null ? item.sosta : "",
                                                      price: await this.getMarkPrice(item),
                                                      paid: item.MarkType.pagopaSpont ? 1 : null,
                                                    }
                                                  );
                                                }}
                                                className="btn btn-success btn-xs btn-update"
                                              >
                                                <FontAwesomeIcon
                                                  size="1x"
                                                  icon={solidIcons.faPlay}
                                                />
                                                &nbsp;&nbsp;Attiva
                                              </button>
                                            {/* )} */}
                                          <button
                                            onClick={() => {
                                              this.onShowListAsWaitMark(item);
                                            }}
                                            className="btn btn-warning btn-xs btn-update"
                                          >
                                            <FontAwesomeIcon
                                              size="1x"
                                              icon={solidIcons.faPause}
                                            />
                                            &nbsp;&nbsp;Attesa
                                          </button>
                                          <button
                                            onClick={() => {
                                              this.onShowDenyMarkActivation(
                                                item
                                              );
                                            }}
                                            className="btn btn-danger btn-xs btn-update"
                                          >
                                            <FontAwesomeIcon
                                              size="1x"
                                              icon={solidIcons.faStop}
                                            />
                                            &nbsp;&nbsp;Nega
                                          </button>
                                        </div>
                                      )}
                                    {this.state.marksDenyingApprovation[
                                      item.id
                                    ] && (
                                      <div>
                                        <input
                                          className=""
                                          placeholder="Motivazione..."
                                          required
                                          type="text"
                                          value={
                                            this.state
                                              .marksDenyingApprovationReasons[
                                              item.id
                                            ]
                                          }
                                          onChange={(event) =>
                                            this.onDenyMarkActivationNoteChange(
                                              event,
                                              item
                                            )
                                          }
                                        />
                                        <button
                                          onClick={() =>
                                            this.props.onDenyMarkActivationClick(
                                              this.state
                                                .marksDenyingApprovationReasons[
                                                item.id
                                              ],
                                              item
                                            )
                                          }
                                          className="btn btn-danger btn-xs btn-update"
                                        >
                                          Conferma Rifiuto
                                        </button>
                                        <button
                                          onClick={() => {
                                            this.onHideDenyMarkActivation(item);
                                          }}
                                          className="btn btn-xs btn-update"
                                        >
                                          Annulla
                                        </button>
                                      </div>
                                    )}
                                    {this.state.marksListingInWait[item.id] && (
                                      <div>
                                        <textarea
                                          rows="4"
                                          className="form-control"
                                          placeholder="Istruzioni..."
                                          required
                                          type="text"
                                          value={
                                            this.state
                                              .marksListingInWaitInstructions[
                                              item.id
                                            ]
                                          }
                                          onChange={(event) =>
                                            this.onListAsWaitInstructionsChange(
                                              event,
                                              item
                                            )
                                          }
                                        />
                                        <br />
                                        {!this.props.marksNotesAddedResults[
                                          item.id
                                        ] && (
                                          <button
                                            onClick={() =>
                                              this.props.onListAsWaitConfirm(
                                                this.state
                                                  .marksListingInWaitInstructions[
                                                  item.id
                                                ],
                                                item,
                                                this.state.marksApprovations[
                                                  item.id
                                                ]
                                              )
                                            }
                                            className="btn btn-warning btn-xs btn-update"
                                          >
                                            Invia Istruzioni
                                          </button>
                                        )}
                                        {!this.props.marksNotesAddedResults[
                                          item.id
                                        ] && (
                                          <button
                                            onClick={() => {
                                              this.onHideListAsWaitMark(item);
                                            }}
                                            className="btn btn-xs btn-update"
                                          >
                                            Annulla
                                          </button>
                                        )}
                                        {this.props.marksNotesAddedResults[
                                          item.id
                                        ] && (
                                          <span className="text-primary">
                                            Nota Inviata Correttamente
                                          </span>
                                        )}
                                        {this.props.marksNotesAddedResults[
                                          item.id
                                        ] && (
                                          <button
                                            onClick={() => {
                                              this.onHideListAsWaitMark(item);
                                              this.props.onCloseWaitConfirm(
                                                item
                                              );
                                            }}
                                            className="btn btn-xs btn-update"
                                          >
                                            Chiudi
                                          </button>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <NumberPlateModal
          open={showChooseMarksVehicleModal}
          closeModal={onHideChooseMarksVehicleModal}
          mark={showMarksVehicleModalNumber}
          onVehicleChoosen={onVehicleChoosen}
          error={chooseMarkVehicleError}
        />
      </div>
    );
  }
}
