/**
 *  LIBRERIA PER I DOCUMENTI
 */


export const DOCUMENT_TYPE_GENERIC = 'GENERIC'
export const DOCUMENT_TYPE_VEHICLE = 'VEHICLE'
export const DOCUMENT_TYPE_IDCARD  = 'IDCARD'
export const DOCUMENT_TYPE_DRIVER  = 'DRIVER'
export const DOCUMENT_TYPE_ALL     = 'ALL'


