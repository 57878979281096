import { getWindowHeight } from 'libs/utils';
import React, { Component }        from 'react'
import FrontOfficeNavMenuComponent from "../components/FrontOfficeNavMenuComponent";


export default class UserMainNavigatorScreen extends Component {

    // ----------------------------------------------------------------
    //
    //
    constructor(props) {
        super(props)

        this.state = {
        minHeight: 0
        }

        this.handleResize = this.handleResize.bind(this)
    }
    

    // ----------------------------------------------------------------
    //
    //
    componentDidMount () {    
        window.addEventListener('resize', this.handleResize)
        this.setState({ minHeight : getWindowHeight()  })
    }

    // ----------------------------------------------------------------
    //
    //
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    // ----------------------------------------------------------------
    //
    //
    handleResize ( ) {        
        this.setState({ "minHeight" : getWindowHeight() })    
    }

    // ----------------------------------------------------------------
    //
    //
    render() {        
        const {minHeight} = this.state


        return (
            <div style={{ minHeight: minHeight }}>
                <div className="backoffice">
                    <FrontOfficeNavMenuComponent {...this.props} />
                    <div className="col-md-10 col-md-offset-1" >
                        {this.props.children}
                    </div>
                </div>
            </div>

        );
    }
}