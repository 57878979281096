import React, {Component} from 'react'
import ReactTable from 'react-table'
import VehicleTypeFilter from '../components/ReactTable/Filters/VehicleTypeFilter'
import { Oval } from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import { getGridHeight } from 'libs/utils'

export default class BackOfficeVehiclesScreen extends Component {

  /**
   * ----------------------------------------------------------------
   * @param {*} props 
   */
  constructor (props) {
    super(props)

    this.state = {
      maxHeight: 0,
      columns: [
        {
          Header: 'Nome',
          accessor: 'User.firstName',
          filterable: true,
          sortable: false,
          width: 140,
          Cell: (row) => {
            return (<div>{row.value}</div>)
          }
        },
        {
          Header: 'Cognome',
          accessor: 'User.lastName',
          filterable: true,
          sortable: false,
          width: 140,
          Cell: (row) => {
            return (<div>{row.value}</div>)
          }
        },
        {
          Header: 'Targa',
          accessor: 'plate_number',
          filterable: true,
          sortable: false,
          width: 140,
          Cell: (row) => {
            return (<div>{row.value}</div>)
          }
        },
        {
          Header: 'Tipo di Proprietà',
          accessor: 'VehicleOwnershipType.name',
          filterable: true,
          sortable: false,
          Cell: (row) => {
            return (<div>{row.value}</div>)
          },
          Filter: ({ filter, onChange }) => <VehicleTypeFilter filter={filter} onChange={onChange} />
        }
      ]
    }

    this.onFetchData   = this.onFetchData.bind(this)
    this.getTotalPages = this.getTotalPages.bind(this)
    this.handleResize  = this.handleResize.bind(this)
  }

  /**
   * ----------------------------------------------------------------
   */
    componentDidMount () {    
      window.addEventListener('resize', this.handleResize)
      this.setState({ maxHeight : getGridHeight() })
    }
  
    /**
     * ----------------------------------------------------------------
     */
    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize)
    }
  
    /**
     * ----------------------------------------------------------------
     */
    handleResize ( ) {        
      this.setState({ maxHeight : getGridHeight() })    
    }

  /**
   * ----------------------------------------------------------------
   * @param {*} state 
   * @param {*} instance 
   */
  onFetchData (state, instance) {
    let filterObj = {}
    for (let i in state.filtered) {
      let f = state.filtered[i]

      if (f.id === 'plate_number') {
        filterObj['plate_number'] = f.value
      }

      if (f.id === 'User.firstName') {
        filterObj['firstName'] = f.value
      }

      if (f.id === 'User.lastName') {
        filterObj['lastName'] = f.value
      }

      if (f.id === 'VehicleOwnershipType.name') {
        filterObj['ownershipTypeId'] = f.value
      }
    }

    let sortObj = {}
    for (let i in state.sorted) {
      let s = state.sorted[i]
      sortObj[s.id] = s.desc ? 'desc' : 'asc'
    }

    this.props.fetchVehicles(filterObj, state.pageSize, state.page)
  }

  /**
   * ----------------------------------------------------------------
   * @returns 
   */
  getTotalPages () {
    const {pagination} = this.props

    if (!pagination) {
      return 0
    }

    if (pagination.total === 0) {
      return 0
    }

    return Math.ceil(pagination.total / pagination.limit)
  }

  /**
   * ----------------------------------------------------------------
   * @returns 
   */
  render () {
    const {
      columns,
      maxHeight
    } = this.state
    return (<div>
      <ReactTable
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            onClick: (e, handleOriginal) => {
              if (!rowInfo) {
                return
              }
              this.props.onRowClick(rowInfo.index, rowInfo.original)
            }}
        }}
        manual
        filterable
        loading={this.props.loading}
        noDataText={'Nessun Utente Presente'}
        onFetchData={this.onFetchData}
        pages={this.getTotalPages()}
        className="-highlight"
        data={this.props.vehicles ? this.props.vehicles : []}
        style={{maxHeight: maxHeight}}
        defaultPageSize={100}
        nextText={<FontAwesomeIcon  className="mainIconsNoAction"  size="2x" icon={solidIcons.faArrowRight} />}
        previousText={<FontAwesomeIcon  className="mainIconsNoAction"  size="2x" icon={solidIcons.faArrowLeft} />}
        loadingText={<Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />}
        rowsText="elementi"
        ofText="di"
        pageText="Pagina"
        columns={columns} />
    </div>)
  }
}
