import React, {Component} from 'react'
import {reduxForm, Field} from "redux-form";
import {STATE_MAIL_SENDED, STATE_SEND_MAIL, STATE_CHANGE_PASSWORD, STATE_PASSWORD_CHANGED} from '../containers/PasswordRecoveryContainer'
import {NavLink} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'

import SplashBtn from '../components/SplashBtn';
import { Button } from 'react-bootstrap';
import { getWindowHeight } from 'libs/utils';


class PasswordRecovery extends Component {

    // ----------------------------------------------------------------
    //
    //
    constructor(props) {
        super(props)

        this.state = {
        minHeight: 0
        }

        this.handleResize = this.handleResize.bind(this)
    }
    

    // ----------------------------------------------------------------
    //
    //
    componentDidMount () {    
        window.addEventListener('resize', this.handleResize)
        this.setState({ minHeight : getWindowHeight()  })
    }

    // ----------------------------------------------------------------
    //
    //
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    // ----------------------------------------------------------------
    //
    //
    handleResize ( ) {        
        this.setState({ "minHeight" : getWindowHeight() })    
    }

    /**
     * ----------------------------------------------------------------
     */
    backToHome(evt) {
        this.props.history.push("/splash")
    }

    render() {

        const {minHeight} = this.state

        return(
            <form onSubmit={this.props.handleSubmit} style={{ minHeight: minHeight }}>
                <div>
                    <div className="col-md-4 col-md-offset-4 ">
                        <br/>
                        <div className="panel panel-default panel-border">
                            <div className="panel-body">
                                {this.props.currentState === STATE_SEND_MAIL && <div>

                                    <div className="text-recovey-password">
                                        <h2>Recupera password</h2>
                                        <FontAwesomeIcon size="6x" className="sectionIcons" icon={solidIcons.faKey} />
                                        <br/><br/>
                                    </div>

                                    <p>
                                        Inserisci la mail con cui ti sei registrato,
                                        ti manderemo una mail per resettare la password
                                    </p>

                                    <Field required component="input" name="email" type="text" className="inputLogin" placeholder="E-mail" aria-describedby="basic-addon1" />

                                    {this.props.formError && <div><span style={{color: 'red'}}>{this.props.formError}</span></div>}
                                    <p>{ }</p>

                                    <Button onClick={(evt) => {this.backToHome(evt)}} className="mainBtn"><FontAwesomeIcon size="1x" icon={solidIcons.faHome} />&nbsp;&nbsp;Torna alla Home</Button>
                                    <SplashBtn><FontAwesomeIcon size="1x" icon={solidIcons.faPaperPlane} />&nbsp;&nbsp;Invio</SplashBtn>
                                    
                                </div>}

                                {this.props.currentState === STATE_MAIL_SENDED && <div>
                                    <div className="testo">
                                        <h2>E-mail Inviata</h2>
                                        <FontAwesomeIcon size="6x" className="sectionIcons" icon={solidIcons.faKey} />
                                        <br/><br/>
                                    </div>

                                    {!this.props.formError && <p>Ti abbiamo inviato una e-mail, segui le istruzioni presenti all'interno per continuare</p>}
                                    {this.props.formError && <div><span style={{color: 'red'}}>{this.props.formError}</span></div>}

                                    <Button onClick={(evt) => {this.backToHome(evt)}} className="mainBtn"><FontAwesomeIcon size="1x" icon={solidIcons.faHome} />&nbsp;&nbsp;Torna alla Home</Button>
                                </div>}

                                {this.props.currentState === STATE_CHANGE_PASSWORD && <div>
                                    <div className="testo">
                                        <h2>Richiedi nuova password</h2>
                                        <FontAwesomeIcon size="6x" className="sectionIcons" icon={solidIcons.faKey} />
                                        <br/><br/>
                                    </div>

                                    <p>Inserisci la tua nuova password</p>                               

                                    <Field required component="input" name="password"         type="password" className="inputLogin" placeholder="Nuova Password"  aria-describedby="basic-addon1" />
                                    <Field required component="input" name="repeatedPassword" type="password" className="inputLogin" placeholder="Ripeti password" aria-describedby="basic-addon1" />

                                    {this.props.formError && <div><span style={{color: 'red'}}>{this.props.formError}</span></div>}

                                    <Button onClick={(evt) => {this.backToHome(evt)}} className="mainBtn"><FontAwesomeIcon size="1x" icon={solidIcons.faHome} />&nbsp;&nbsp;Torna alla Home</Button>
                                    <SplashBtn><FontAwesomeIcon size="1x" icon={solidIcons.faPaperPlane} />&nbsp;&nbsp;Invio</SplashBtn>                                
                                </div>}

                                {this.props.currentState === STATE_PASSWORD_CHANGED && <div>
                                    <div className="testo">
                                        <h2>Password Modificata con Successo</h2>
                                        <FontAwesomeIcon size="6x" className="sectionIcons" icon={solidIcons.faKey} />
                                        <br/><br/>
                                    </div>
                                    <p>La tua password è stata modificata correttamente.</p>
                                    <Button onClick={(evt) => {this.backToHome(evt)}} className="mainBtn"><FontAwesomeIcon size="1x" icon={solidIcons.faHome} />&nbsp;&nbsp;Torna alla Home</Button>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

export default reduxForm({
    form: 'passwordRecovery'
})(PasswordRecovery)