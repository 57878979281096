import React, {Component} from 'react'
import Select from 'react-virtualized-select'
//import createFilterOptions from 'react-select-fast-filter-options'

import 'react-virtualized/styles.css'
import 'react-virtualized-select/styles.css' 
import 'react-data-grid-addons/node_modules/react-select/dist/react-select.css'

export default class VirtualizedSelectFormComponent extends Component {
  constructor (props) {
    super(props)
    const filterOptions = props.options
    this.state = {
      //filterOptions: createFilterOptions({filterOptions})
    }
  }

  render () {
    return (
      <div>
        <Select
          style={this.props.style}
          ref={this.props.name}
          simpleValue
          searchable
          placeholder={this.props.placeholder}
          options={this.props.options}
          required={this.props.required}
          multi={this.props.multi}
          value={this.props.input.value}
          onChange={this.props.input.onChange} />
      </div>
    )
  }
}
