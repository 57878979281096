import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import Modal from 'react-responsive-modal'
import "react-responsive-modal/styles.css";
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import { Oval } from 'react-loader-spinner'
import SostaOnlineApiService from '../../services/SostaOnlineApiService'

const fileDownload = require('js-file-download')

export default class BackofficeVehicleDocumentsReview extends Component {
  static propTypes = {
    onApprovationChanged: PropTypes.func,
    changeRequest:        PropTypes.object.isRequired,
    open:                 PropTypes.bool.isRequired,
    onClose:              PropTypes.func.isRequired,
    userId:               PropTypes.number
  }

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor (props) {
    super(props)
    
    this.onRowSelected               = this.onRowSelected.bind(this)
    this.onDocumentDownloadClick     = this.onDocumentDownloadClick.bind(this)
    this.onDocumentPreviewClick      = this.onDocumentPreviewClick.bind(this)
    this.onDocumentApprovationChange = this.onDocumentApprovationChange.bind(this)
    this.getDocumentTypeLabel        = this.getDocumentTypeLabel.bind(this)

    this.state = {
      loading:       false,
      error:         null,
      selectedRows:  [],        
      documents:     [],      
      documentTypes: [],

      columns: [
        {
          Header: (
            <div className="documents-browser-header-list">
              Tipo
            </div>
          ),
          className: 'no-horizontal-border',
          accessor: 'Document.DocumentTypeId',
          filterable: true,
          sortable: true,          
          filterMethod: (filter, row) => (row[filter.id].toLowerCase().includes(filter.value.toLowerCase())),
          Cell: row => {
            const isActive = row && this.state.selectedRows.indexOf(row.index) !== -1
            return (
              <div className={classNames('documents-browser-file-cell', {'white-text': isActive})}>
                {this.getDocumentTypeLabel(row.value)}
              </div>
            )
          }
        },

        {
          Header: (
            <div className="documents-browser-header-list">
              Approvazione
            </div>
          ),
          width: 320,
          className: 'no-horizontal-border',
          accessor: 'approved',
          filterable: false,
          sortable: true,
          Cell: row => {
            const isActive = row && this.state.selectedRows.indexOf(row.index) !== -1
            return (
              <div className={classNames('documents-browser-file-cell', {'white-text': isActive})}>
                {row.value ? (
                  <span style={{ display: "flex" , justifyContent: "space-between", marginRight: 20}}>
                    <span style={{ flex: 50 , textAlign: "left", marginTop: 10}} className="text-success" >Approvato</span>
                    <a    style={{ flex: 50 , textAlign: "center "}} className="btn mainBtn" onClick={() => this.onDocumentApprovationChange(row.original)}>
                      <FontAwesomeIcon size="1x" icon={solidIcons.faMinusCircle} />&nbsp;&nbsp;
                      Disapprova
                    </a>
                  </span>
                ) : (
                  <span style={{ display: "flex" , justifyContent: "space-between", marginRight: 20}}>
                    <span className="text-danger" style={{ flex: 50 , textAlign: "left", marginTop: 10 }}>Non Approvato</span>
                    <a className="btn mainBtn" style={{ flex: 50, textAlign: "ceenter" }} onClick={() => this.onDocumentApprovationChange(row.original)}>
                      <FontAwesomeIcon size="1x" icon={solidIcons.faCheckCircle} />&nbsp;&nbsp;
                      Approva
                    </a>
                  </span>
                )}
              </div>
            )
          }
        }

      ]
    }
  }

  /**
   * --------------------------------------------------------------
   * @param {*} documentTypeId 
   * @returns 
   */
  getDocumentTypeLabel(documentTypeId) {
    var label = ""
    var documentTypes  = this.state.documentTypes     

    if ( documentTypes  &&  documentTypes.length > 0 ) {

      documentTypes = documentTypes.filter( dt => dt.id == documentTypeId ) 

      if ( documentTypes && documentTypes.length > 0 ) {        
        label = documentTypes[0].label
      }
    } 

    return label
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  async componentDidMount() {
    var documentTypes = await SostaOnlineApiService.fetchDocumentTypes()    
    this.setState({ documentTypes: documentTypes.data })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} index 
   * @param {*} row 
   */
  onRowSelected (index, row) {
    const newSelectedRows = []
    newSelectedRows.push(index)
    this.setState({selectedRows: newSelectedRows})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} document 
   */
  async onDocumentDownloadClick (document) {    
    const {userId} = this.props    
    const documentResponse = await SostaOnlineApiService.downloadDocument(document.id)
    fileDownload(documentResponse, userId + '_' + document.name + '.' + document.mimeType.split('/')[1])
  }

  /**
   * --------------------------------------------------------------
   * @param {*} document 
   */
  onDocumentPreviewClick (document) {
    //console.log(document)
  }

  /**
   * --------------------------------------------------------------
   * @param {*} vehicleChangeRequestDocument 
   */
  async onDocumentApprovationChange (vehicleChangeRequestDocument) {
    
    try {
      this.setState({loading: true})

      await SostaOnlineApiService.updateVehicleChangeRequestDocumentApprovation(vehicleChangeRequestDocument.DocumentId, vehicleChangeRequestDocument.VehiclesChangeRequestId, !vehicleChangeRequestDocument.approved)
      
      if (this.props.onApprovationChanged) {
        await this.props.onApprovationChanged()        
      }

      this.setState({loading: false})
    } 
    catch (e) {      
      this.setState({
        error: 'Errore nella modifica dell\'approvazione.', 
        loading: false
      })
    }
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {
    const {
      open,
      onClose
    } = this.props

    const {
      loading,
      error,
      selectedRows,
      columns
    } = this.state

    let documents = []

    if ( this.props.changeRequest && this.props.changeRequest.VehiclesChangeRequestsDocuments )
      documents = this.props.changeRequest.VehiclesChangeRequestsDocuments

    return (
      <Modal
        open={open}
        onClose={onClose}
        showCloseIcon={false}
        closeOnEsc={true}        
        closeOnOverlayClick={false}
        classNames={{ modal: 'app-modal-container' }}        
        focusTrapped={true}    
        animationDuration={500}        
        center
      >
        <h4 className="app-modal-title">
          <div style={{ flex: 10, textAlign : 'left'}}>
            <FontAwesomeIcon className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faFileCircleCheck} />
          </div>          
          <div style={{ flex: 80, textAlign : 'center' }}><strong>REVISIONE DOCUMENTI VEICOLO</strong></div>          
          <div style={{ flex: 10, textAlign : 'right'}}>
            <FontAwesomeIcon  onClick={ evt => onClose() } className="mainIconsRev"  size="1x" icon={solidIcons.faXmark} />
          </div>          
        </h4> 
        <div className="panel-body">
          <div className="col-md-12" style={{minHeight: '350px', padding: '0'}}>
            <div className="col-md-12" style={{marginBottom: '10px'}}>
              {selectedRows.length > 0 && (
                <div>
                  <a onClick={() => this.onDocumentDownloadClick(documents[selectedRows[0]].Document)} className="btn mainBtn">
                    <FontAwesomeIcon size="1x" icon={solidIcons.faDownload} />&nbsp;&nbsp;Scarica Documento
                  </a>
                </div>
              )}
            </div>

            <div style={{marginLeft: '0px', marginRight: '0px', minWidth: '650px', width: '100%'}}>
              {error ? (
                <span className="text-danger">{error}</span>
              ) : (
                <ReactTable
                  minRows={6}
                  loading={loading}
                  noDataText={'Nessun Documento Presente'}
                  className="-highlight no-horizontal-border"
                  data={documents}
                  showPagination={false}
                  style={{maxHeight: '500px', width:'100%', margin: '0px', padding: '0px'}}
                  defaultPageSize={documents.length}
                  loadingText={<Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />}
                  getTrGroupProps={(state, rowInfo, column) => {
                    const isActive = rowInfo && selectedRows.indexOf(rowInfo.index) !== -1
                    const activeStyle = {}

                    if (isActive) {
                      activeStyle.backgroundColor = '#2196F3'
                    }
                    return {
                      style: {
                        borderBottom: 0,
                        ...activeStyle
                      }
                    }
                  }}
                  getTdProps={(state, rowInfo, column, instance) => {
                    const isActive = rowInfo && selectedRows.indexOf(rowInfo.index) !== -1
                    const activeStyle = {}
                    if (isActive) {
                      activeStyle.color = '#FFFFF'
                    }
                    return {
                      onClick: (e, handleOriginal) => {
                        if (!rowInfo) {
                          return
                        }
                        this.onRowSelected(rowInfo.index, rowInfo.original)
                      }
                    }
                  }}
                  columns={columns} />
              )}
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}
