import React, {Component} from 'react'
import './HeaderComponent.css'

export default class HeaderComponent extends Component {
    render() {
        return(
            <div>
                <div className="header-nav-color-1"></div>
                <div className="header-nav-color-2"></div>
                <div className="header-nav-color-3"></div>
            </div>
        );
    }
}