import React, {Component}  from 'react'
import moment              from 'moment'
import { NavLink }         from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  * as solidIcons    from '@fortawesome/free-solid-svg-icons'
import { NEWS_SECTIONS, getHtmlMsgFromString, getNewsStyle } from 'libs/news'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SostaOnlineApiService from 'services/SostaOnlineApiService';
import { residenceAddressFormat, workAddressFormat } from 'libs/utils';


const TextUser = (props) => <div className={props.bold ? 'text-bold-user' : 'text-regular-user'} style={{textAlign: 'center'}}>{props.children}</div>

export default class UserAreaProfile extends Component {

  constructor(props) {
    super(props)
    this.state = {
      news: []
    }
  }

  componentDidMount() {
    SostaOnlineApiService.fetchNewsMe(NEWS_SECTIONS.USERPROFILE.value)
      .then( news => {
        this.setState({ news: news.data})
      })  
  }

  
  /**
   * ----------------------------------------------------------------
   * @returns 
   */
  render () {

    let birthDate        = moment(this.props.user.birthDate)
    var residenceAddress = residenceAddressFormat(this.props.user)
    var workAddress      = workAddressFormat(this.props.user)
    var news = this.state.news

    return (
      <div className="row">

        <div className="col-md-8">
          { this.props.user && 
            <div className="panel panel-default panel-border-login">

              <h2 className="login-title">
                {this.props.user.firstName.toUpperCase() + ' ' + this.props.user.lastName.toUpperCase()}
              </h2>

              <div className="panel-body">

                <div className="row">
                  <div className="col-md-3" style={{marginBottom: 20}}>
                    <FontAwesomeIcon  size="6x" className="sectionIcons"   icon={solidIcons.faAddressCard} />
                  </div>

                  <div className="col-md-9 user-details-card">   
                    <div className="row">
                      {this.props.user.fiscalCode && <div className="text-regular-user">CODICE FISCALE</div>}
                      {this.props.user.fiscalCode && <div className="text-bold-user">{this.props.user.fiscalCode}</div>}
                      {this.props.user.phone && <div className="text-regular-user">TELEFONO</div>}
                      {this.props.user.phone && <div className="text-bold-user">{this.props.user.phone}</div>}
                      {this.props.user.email && <div className="text-regular-user">E-MAIL</div>}
                      {this.props.user.email && <div className="text-bold-user">{this.props.user.email}</div>}
                      {this.props.user.birthDate && <div className="text-regular-user">DATA DI NASCITA </div>}
                      {this.props.user.birthDate && <div className="text-bold-user">{birthDate.format('L')}</div>}
                      {this.props.user.birthPlace && <div className="text-regular-user">LUOGO DI NASCITA</div>}
                      {this.props.user.birthPlace && <div className="text-bold-user">{this.props.user.birthPlace}</div>}
                      {this.props.user.sex && <div className="text-regular-user">SESSO </div>}
                      {this.props.user.sex && <div className="text-bold-user">{this.props.user.sex}</div>}
                      { residenceAddress && residenceAddress.length > 0 && <div className="text-regular-user">INDIRIZZO DI RESIDENZA</div>}
                      { residenceAddress && residenceAddress.length > 0 && <div className="text-bold-user"> {residenceAddress}</div>}
                      { workAddress && workAddress.length > 0 && <div className="text-regular-user">INDIRIZZO DI LAVORO </div>}
                      { workAddress && workAddress.length > 0 && <div className="text-bold-user">{workAddress}</div>}
                      {(this.props.user.companyName && this.props.user.companyLegalAddress) && <div className="text-regular-user">AZIENDA </div>}
                      {(this.props.user.companyName && this.props.user.companyLegalAddress) && <div className="text-bold-user">{this.props.user.companyName + ', ' + this.props.user.companyLegalAddress}</div>}
                      {this.props.user.vatNumber && <div className="text-regular-user">PARTITA IVA </div>}
                      {this.props.user.vatNumber && <div className="text-bold-user">{this.props.user.vatNumber}</div>}                                    
                    </div>

                    <div className="row" style={{paddingTop: 15}}>
                      <div className="col-sm-offset-3 col-sm-6 col-md-offset-0 col-md-12 col-lg-offset-2 col-lg-8">
                        <NavLink className="mainBtn" to="/user-area/password-change" style={{ display: "flex",  justifyContent : "center", marginLeft: 0 }}>
                          <FontAwesomeIcon className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faKey} style={{paddingRight: 10}}/>
                          <strong>CAMBIA PASSWORD</strong>
                        </NavLink>                  
                      </div>
                    </div>

                    <div className="row">
                      {(this.props.user.categories && this.props.user.categories.length > 0) &&               
                        <div className="col-sm-offset-3 col-sm-6 col-md-offset-0 col-md-12 col-lg-offset-2 col-lg-8">
                          <div className="title-user-categories">
                            <strong style={{fontSize: 18}}>
                              {this.props.user.categories.length > 1 ? "LE TUE CATEGORIE UTENTI SONO" 
                                :"LA TUA CATEGORIA UTENTE E'"
                              }
                            </strong>
                          </div>
                        </div>
                      }
                    </div>

                    {(this.props.user.categories && this.props.user.categories.length > 0) && 
                      this.props.user.categories.map( item => 
                        <div className="row" key={item.id}>
                          <div className="col-sm-offset-3 col-sm-6 col-md-offset-0 col-md-12 col-lg-offset-2 col-lg-8">
                            
                              <div className="user-card-btn" style={{ marginLeft: 0  }}>
                                <div className="user-btn-personalization1">
                                  <span><strong>{item.label.charAt(0).toUpperCase() + item.label.slice(1)}</strong></span>
                                </div>
                                <div className="user-btn-personalization2">
                                  <span><strong>{item.description.charAt(0).toUpperCase() + item.description.slice(1)}</strong></span>
                                </div>
                              </div>
                            
                          </div>
                        </div>
                      )
                    }

                  </div>
                </div>
                                    
                

              </div>

            </div>
          }
        </div>

        <div className="col-md-4 ">
          <div className="information-container hidden-xs hidden-sm" style={{display:'flex', justifyContent: 'center'}}>
            <div style={{ width:'85%' }}>
              <div style={{marginTop: 15, marginBottom: 10, display:'flex', alignItems: 'center', justifyContent:'center'}}>
                <span className="threesom-span">
                  <FontAwesomeIcon  size="2x" className="sectionIcons"   icon={solidIcons.faCaretLeft} />
                </span>
                <div>
                  <TextUser bold>UTENTE</TextUser>
                  <TextUser>
                    Questi sono i tuoi dati di registrazione e la categoria a cui è associato il tuo profilo utente
                  </TextUser>
                </div>
              </div>
            </div>
          </div>

          {news != null && news.length > 0 && 
          <div className="information-container">
            <div style={{marginLeft: 10}}>
              <div style={{marginTop: "5em", marginBottom: 10}}>
                <TextUser bold>COMUNICAZIONI</TextUser>
                <div style={{ margin: 0 }}>
                  
                  <Carousel
                    autoPlay
                    interval={8000}
                    infiniteLoop
                    stopOnHover
                    emulateTouch
                    showThumbs={false}
                  >
                  { news.map(  n => 
                    <div className={"boxLabel " + (getNewsStyle(n))}>
                      {n.thumbImage == null ? (
                      <div>
                        {n.startDate && moment(n.startDate).format('L')} - {n.title}<br/><br/>
                        { getHtmlMsgFromString(n.content)}<br/><br/><br/>
                      </div>
                      ):(
                        <img style={{width:'100%', height:'100%', objectFit:'contain'}} src={n.thumbImage}></img>
                      )}
                      </div>     
                  )} 
                  </Carousel>
                  
                </div>
              </div>
            </div>
          </div>
          }

        </div>
      </div>
    )
  }
}

UserAreaProfile.defaultProps = {}

UserAreaProfile.propTypes = {}
