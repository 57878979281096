import React, {Component}    from 'react'
import {connect}             from 'react-redux'
import BackOfficeMarkScreen  from '../screens/BackOfficeMarkScreen'
import SostaOnlineApiService from '../services/SostaOnlineApiService'
import {actions as authActions} from '../redux/modules/Auth'
import { bindActionCreators } from 'redux'
import { reportActions } from 'redux/modules/Report' 


const mapStateToProps = (state) => {
  return {
    state: state.users,
    stateAuth: state.auth,
    stateReport : state.report
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authActions:   bindActionCreators(authActions,   dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  };
};

class BackOfficeMarksTableContainer extends Component {

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor (props) {
    super(props)
    this.state = {
      marks : [],
      count : 0,
      filters: {},
      loading: false
    }

    this.fetchMarks = this.fetchMarks.bind(this)
    this.onRowClick = this.onRowClick.bind(this)
  }

  /**
   * --------------------------------------------------------------
   */
  componentDidMount () {
    this.setState({loading: true})
    this.fetchMarks()
    this.fetchThingsToDo()
  }

  /**
   * --------------------------------------------------------------
   */
   fetchThingsToDo () {
    SostaOnlineApiService.fetchThingsToDo().then(data => {      
      this.props.reportActions.fetchThingsToDo(data.data)
    })
    .catch( error => {
      console.log("ERROR",error)
    })
  }   

  /**
   * --------------------------------------------------------------
   * @param {*} filterObj 
   * @param {*} limit 
   * @param {*} offset 
   */
  fetchMarks (filterObj, limit, offset) {
    this.setState({loading: true})
    let string = ''
    for (let key in filterObj) {
      string = string + key + '=' + filterObj[key] + '&'
    }

    SostaOnlineApiService.fetchMarks(string, limit || 100, offset || 0).then((data) => {     
      this.setState({
        marks: data.data,
        pagination: data.pagination,
        loading: false            
      })
    })
    .catch( error => {
      console.log("ERROR",error)
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} rowIdx 
   * @param {*} row 
   * @returns 
   */
  onRowClick (rowIdx, row) {
    if (rowIdx <= -1 || !row.userId) {
      return
    }

    this.props.history.push('/backoffice/users/' + row.userId + '?scrollTo=mark-' + row.id)
  }

  render () {
    return (
      <BackOfficeMarkScreen
        loading={this.state.loading}
        fetchMarks={this.fetchMarks}
        marks={this.state.marks}
        rows={this.state.marks}
        pagination={this.state.pagination}
        onRowClick={this.onRowClick} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeMarksTableContainer)
