import React, {Component} from 'react'
import {reduxForm} from "redux-form";
import { Button } from 'react-bootstrap';
import SostaOnlineApiService from '../../../services/SostaOnlineApiService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import { Oval } from 'react-loader-spinner'
import { getWindowHeight, validateEmail } from 'libs/utils';


const STATE_SEND_MAIL = "STATE_SEND_MAIL"
const STATE_MAIL_SENT = "STATE_MAIL_SENT"


class RegistrationSendConfirmEmail extends Component {

  /**
   * ----------------------------------------------------------------
   * @param {*} props 
   */
  constructor (props) {
    super(props)

    this.state = {      
      email : null,      
      currentState: STATE_SEND_MAIL,
      errorMsg: null,
      loading: false,
      minHeight: 0
    }    

    this.handleResize = this.handleResize.bind(this)

  }

  /**
   * ----------------------------------------------------------------
   */
  componentDidMount () {

    window.addEventListener('resize', this.handleResize)    

    this.setState({
      email : null,      
      currentState: STATE_SEND_MAIL,
      errorMsg: null,
      loading: false,
      minHeight : getWindowHeight()
    })
  }

  // ----------------------------------------------------------------
  //
  //
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  // ----------------------------------------------------------------
  //
  //
  handleResize ( ) {        
    this.setState({ "minHeight" : getWindowHeight() })    
  }

  
  /**
   * ----------------------------------------------------------------
   * @param {*} e 
   * @returns 
   */
  onSendConfirmEmail(e) {    

    if ( this.state.email == null || ! validateEmail(this.state.email) ) {
      this.setState({errorMsg: "L'email specificata non è valida"})
      return
    }

    this.setState({loading: true})
    
    SostaOnlineApiService.sendConfirmEmail(this.state.email)
      .then(data => {        
        this.setState({loading: false})
      })
      .catch(err => {        
        this.setState(
          {
            loading: false,
            errorMsg: err.error
          }
        )
      })
    


    this.setState({currentState: STATE_MAIL_SENT})
    
  }

  /**
   * ----------------------------------------------------------------
   * @param {*} email 
   */
  onChangeEmail(email) {    
    this.setState({
      email: email,
      errorMsg: null
    })
  }

  /**
   * ----------------------------------------------------------------
   */
  backToHome(evt) {
    this.props.history.push("/splash")
  }

  /**
   * ----------------------------------------------------------------
   * @returns 
   */
  render () {

    const {
      currentState,      
      errorMsg,
      loading,
      minHeight
    } = this.state    

    if (loading) {
      return (
        <div>
          <div className="row"><h2>Caricamento in corso...</h2></div>
          <div className="row" style={{padding: "2em", height: "40em"}}>
            <Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />
          </div>
        </div>
      )
    }
    
    return (
      
        <div className="col-md-6 col-md-offset-3 " style={{ minHeight: minHeight }}>
          <br/>
          <div className="panel panel-default panel-border">
            <div className="panel-body">

              {currentState === STATE_MAIL_SENT && 
                <div>

                    <div className="text-recovey-password">
                        <h2>Resoconto Invio Email di Conferma</h2>

                        { !errorMsg && 
                        <h4> 
                          Se l'indirizzo email che hai inserito è lo stesso che hai fornito in fase di registrazione<br/>
                          a breve dovresti ricevere una email con un link di conferma per finalizzare la registrazione utente.
                        </h4>}
                        
                        {errorMsg && 
                          <h4 style={{color: 'red'}}>{errorMsg}</h4>
                        }
                    </div>
          
                    <h4>{ }</h4>

                    <Button className="mainBtn" onClick={ (evt) => this.backToHome(evt) }>
                      <FontAwesomeIcon size="1x" icon={solidIcons.faHome} />&nbsp;&nbsp;Torna alla Home
                    </Button>

                </div>
              }

              {currentState === STATE_SEND_MAIL &&
              <div>

                <div className="text-recovey-password">
                    <h2>Richiedi Invio Email di Conferma</h2>
                    <FontAwesomeIcon size="6x" className="sectionIcons" icon={solidIcons.faEnvelopeCircleCheck} />
                    <br/><br/>
                </div>

                <h4>Inserisci l'email che hai fornito durante la registrazione</h4>

                <input 
                    required                       
                    name="email" 
                    type="text" 
                    className="mainInput" 
                    placeholder="Email"                       
                    onChange={ (evt) => { this.onChangeEmail(evt.target.value) }}/>

                {errorMsg && <div><h4 style={{color: 'red'}}>{errorMsg}</h4></div>}

                <p>{ }</p>
              
                <Button className="mainBtn" onClick={ (evt) => this.backToHome(evt) }>
                  <FontAwesomeIcon size="1x" icon={solidIcons.faHome} />&nbsp;&nbsp;Torna alla Home
                </Button>

                <Button className="mainBtn" onClick={ (evt) => this.onSendConfirmEmail(evt) }>
                  <FontAwesomeIcon size="1x" icon={solidIcons.faPaperPlane} />&nbsp;&nbsp;Invio
                </Button>
              </div>
              }

          </div>
        </div>
      </div>      
      
    )
    
  }

}


export default reduxForm({form: 'RegistrationSendConfirmEmail'})(RegistrationSendConfirmEmail)
