import React, {Component} from 'react'
import './LoadingComponent.css'
import { Oval } from 'react-loader-spinner'

export default class Loading extends Component {
    render() {
        return(
          <div>            
              {
                <div>
                  <div className="row text-center col-md-12" style={{padding: "2em"}}><h1>Caricamento in corso...</h1></div>
                  <div className="row text-center col-md-12" style={{padding: "2em", height: "200em"}}>
                    <Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />
                  </div>
                </div>
              }            
          </div>
        );
    }
}
