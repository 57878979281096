/**
 *  LIBRERIA DI FUNZIONI TRASVERSALI E GENERALI
 */


 import isValidDomain from 'is-valid-domain'

/**
 * ----------------------------------------------------------------
 * @param {*} length 
 * @returns 
 */
export function randomPassword (length = 5) {
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let text = ''

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return text
}

/**
 * ----------------------------------------------------------------
 */
export function formatCityString(city,province) {
  return city + ' (' + province + ')'
}

/**
 * ----------------------------------------------------------------
 * @param {*} email 
 * @returns 
 */
export function validateEmail(email) {
  var check = false
  var validRegex =  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;          

  if (email.match(validRegex)) {      
    
    var emailTokens = email.split("@")      

    if ( emailTokens.length > 1 && isValidDomain(emailTokens[1]) )
      check = true
    
  }     

  return check
  
}

/**
 * ----------------------------------------------------------------
 * @returns 
 */
export function getWindowHeight() {
  const {innerHeight} = window;
  return innerHeight - 130
}


/**
 * ----------------------------------------------------------------
 * @returns 
 */
export function getWindowWidth() {
  const {innerWidth} = window;
  return innerWidth 
}

/**
 * ----------------------------------------------------------------
 * @returns 
 */
export function getGridHeight() {  
  return getWindowHeight() - 100
}

/**
 * ----------------------------------------------------------------
 * @param {*} field 
 * @returns 
 */
export function clearSpecialChars(field) {
  const specialChars = /[!"£$%&/()=?^[\]{}\s\\<>;\-_'éèòàìù*+=@,:.°§ç#|¶–•…]/;
  
  if ( field != null ) {
    field = field.replace( specialChars, '' )
  }

  return field
}

/**
 * ----------------------------------------------------------------
 * @param {*} civicNumber 
 */
export function parseCivicNumber(civicNumber) {   
    
  if ( civicNumber != null ) {
    civicNumber = clearSpecialChars(civicNumber)
    civicNumber = civicNumber.substring(0,10).toUpperCase()
  }

  return civicNumber
}

/**
 * ----------------------------------------------------------------
 * @param {*} fiscalCode 
 */
export function parseFiscalCode(fiscalCode) {   
     
  if ( fiscalCode != null ) {
    fiscalCode = clearSpecialChars(fiscalCode)
    fiscalCode = fiscalCode.substring(0,20).toUpperCase()
  }

  return fiscalCode
}

/**
 * ----------------------------------------------------------------
 * @param {*} cap 
 */
export function parseCap(cap) {   
     
  if ( cap != null ) {
    cap = clearSpecialChars(cap)
    cap = cap.replace( /[a-zA-Z]/, '' )
    cap = cap.substring(0,10).toUpperCase()
  }

  return cap
}

/**
 * ----------------------------------------------------------------
 * @param {*} vat 
 */
export function parseVatNumber(vat) {   
     
  if ( vat != null ) {
    vat = clearSpecialChars(vat)
    vat = vat.substring(0,20).toUpperCase()
  }

  return vat
}

/**
 * ----------------------------------------------------------------
 * @param {*} phone 
 */
export function parsePhoneNumber(phone) {   
     
  if ( phone != null ) {
    phone = clearSpecialChars(phone)
    phone = phone.replace( /[a-zA-Z]/, '' )
    phone = phone.substring(0,100).toUpperCase()
  }

  return phone
}

/**
 * ----------------------------------------------------------------
 * @param {*} email 
 * @returns 
 */
export function parseEmail(email) {

  const specialChars =  /[!"£$%&/()=?^[\]{}\s\\<>;'éèòàìù*+=,:°§ç#|¶–•…]/;
  
  if ( email != null ) {
    email = email.replace( specialChars, '' )
    email = email.substring(0,250) 
  }

  return email
}


/**
 * ----------------------------------------------------------------
 * @param {*} price 
 * @returns 
 */
export function parsePrice(price) { 
  return Number(price == null || price.length <= 0 ? 0 : parseFloat(price).toFixed(2))
}


/**
   * ----------------------------------------------------------------
   * @returns 
   */
export function residenceAddressFormat(user) {
  var address = ''

  if ( user.residenceAddress ) 
    address = address + ' ' + user.residenceAddress

  if ( user.residenceCivicNumber )
    address = address + ' N° ' + user.residenceCivicNumber
  
  if ( user.residenceInside )
    address = address + ' Int. ' + user.residenceInside

  if ( user.residenceCap )
    address = address + ' , ' + user.residenceCap

  if ( user.residenceCity )
    address = address + ' ' + user.residenceCity

  return address
}

/**
 * ----------------------------------------------------------------
 * @returns 
 */
export function workAddressFormat(user) {
  var address = ''

  if ( user.workAddress ) 
    address = address + ' ' + user.workAddress

  if ( user.workCivicNumber )
    address = address + ' N° ' + user.workCivicNumber

  if ( user.workInside )
    address = address + ' Int. ' + user.workInside

  if ( user.workCap )
    address = address + ' , ' + user.workCap

  if ( user.workCity )
    address = address + ' ' + user.workCity

  return address

}

/**
 * ----------------------------------------------------------------
 * @returns 
 */
export function legalAddressFormat(user) {
  var address = ''

  if ( user.companyLegalAddress ) 
    address = address + ' ' + user.companyLegalAddress

  if ( user.companyLegalCivicNumber )
    address = address + ' N° ' + user.companyLegalCivicNumber

  if ( user.companyLegalInside )
    address = address + ' Int. ' + user.companyLegalInside

  if ( user.companyLegalCap )
    address = address + ' , ' + user.companyLegalCap

  if ( user.companyLegalCity )
    address = address + ' ' + user.companyLegalCity

  return address

}

/**
 * ----------------------------------------------------------------
 * 
 */

export const formatDate = (dataValue) => {

  // dataValue = dataValue.replaceAll("-","/");

  if(dataValue && (dataValue.slice(0,4).search('/') !== -1)) {
      const dd = dataValue.substr(0,2);
      const mm = dataValue.substr(3,2);
      const YYYY = String(dataValue).substr(6,4); 
      dataValue = YYYY + '/' + mm + '/' + dd
  }    
  return dataValue;
} 


/**
 * ----------------------------------------------------------------
 * @param {*} data 
 * @returns 
 */

export const checkEndDate = (data)=> {   
  const yearNow = new Date().toLocaleDateString('en-CA').substring(0,4);
  const yearExp = data.slice(-4);
  let dataDiff = (yearNow - yearExp);     

  if(dataDiff < 0) {
    dataDiff = 0;
  }   

  let dmy = data.split("/");  
  let a = parseInt(dmy[2]);
  let b = a + dataDiff;
  let year = b.toString();
  
  let endDateMarkType = dmy[0]+"/"+dmy[1]+"/"+year; 
  
  return endDateMarkType;
}

/**
 * ----------------------------------------------------------------
 * @param {*} data 
 * @returns 
 */

export const renewDate = (data)=> {      
  let workedDate = data.split("/");
  workedDate = (Number(workedDate[2]) + 1)+"/"+workedDate[1]+"/"+workedDate[0];
  workedDate = new Date(workedDate);
  workedDate = workedDate.getTime();  
  return workedDate;
}