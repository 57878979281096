import React, {Component} from 'react'
import {reduxForm} from 'redux-form'
import { Oval } from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import { getActiveMarks, getBackofficeCardVehicleHeaderClass, getRequestedDocumentTypes, getValidPlateNumberContent, getVehicleIcon, getVehicleStatus, VEHICLE_STATUS_APPROVED, VEHICLE_STATUS_DENIED, STATUS_VEHICLE_DESCR_DENIED, VEHICLE_STATUS_REPLY, VEHICLE_STATUS_REQUEST } from 'libs/vehicles'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import BackOfficeUserDetailVehicleLogsContainer from 'containers/BackOfficeUserDetailVehicleLogsContainer'
import fileDownload from 'js-file-download'
import SostaOnlineApiService from 'services/SostaOnlineApiService'
import PropTypes from 'prop-types'
import moment from 'moment'
import BackofficeVehicleDocumentsReview from './BackofficeDocumentsReview/BackofficeVehicleDocumentsReview'
import classNames from 'classnames'
import { scroller } from 'react-scroll'


class BackOfficeUserDetailVehicleForm extends Component {

  static propTypes = {
    markTypes: PropTypes.array.isRequired,
    documentTypes: PropTypes.array.isRequired,
    marks: PropTypes.array.isRequired,
    vehicle: PropTypes.object.isRequired,
    initialValues: PropTypes.object.isRequired,
    operator: PropTypes.object.isRequired,
    vehicleDeleting: PropTypes.object.isRequired,
    onVehicleEditClick: PropTypes.func.isRequired,
    onDeleteVehicleClick: PropTypes.func.isRequired,
    onCancelDeleteVehicleClick: PropTypes.func.isRequired,
    onDeleteVehicle: PropTypes.func.isRequired,
    onUpdateVehicleChangeRequest: PropTypes.func,
    vehiclesDeleteResults: PropTypes.object.isRequired    
  }

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      errors: false,      
      changeRequestAction: '',
      note: null,
      updateMessage: null,
      updateError: null,
      showReviewDocuments: false,      
    }


    this.onCloseReviewDocuments = this.onCloseReviewDocuments.bind(this)
  }

  /**
   * --------------------------------------------------------------
   */
  async componentDidMount () {    
    this.setState({loading: false})   
  }

  /**
   * --------------------------------------------------------------
   * @param {*} vehicle 
   * @returns 
   */
  getVehicleStatus(vehicle) {

    var status = ''

    if ( vehicle.VehiclesChangeRequests && vehicle.VehiclesChangeRequests.length > 0  ) {
      var vehicleStatus = vehicle.VehiclesChangeRequests.sort((a, b) => (a.id > b.id ) ? -1 : 1)
      var statusTmp     = getVehicleStatus(vehicleStatus[0],this.props.operator.role)
      
      status = ( <div style={{color:statusTmp.color}} >{statusTmp.status}</div> )
    }

    return status
  }

  /**
   * --------------------------------------------------------------
   * @param {*} vehicle 
   * @returns 
   */
  getVehicleChangeRequest(vehicle) {
    var changeRequest = null

    if ( vehicle && vehicle.VehiclesChangeRequests && vehicle.VehiclesChangeRequests.length > 0  ) {
      var changeRequests = vehicle.VehiclesChangeRequests.sort((a, b) => (a.id > b.id ) ? -1 : 1)
      changeRequest = changeRequests[0]            
    }

    return changeRequest

  }

  /**
   * --------------------------------------------------------------
   * @param {*} vehicle 
   * @returns 
   */
  showVehicleModifyButton(vehicle) {
    var result = true

    if ( vehicle.VehiclesChangeRequests && vehicle.VehiclesChangeRequests.length > 0  ) {
      var vehicleStatus = vehicle.VehiclesChangeRequests.sort((a, b) => (a.id > b.id ) ? -1 : 1)
      var status        = getVehicleStatus(vehicleStatus[0],this.props.operator.role)

      switch (status.code) {
        case VEHICLE_STATUS_REQUEST:
        case VEHICLE_STATUS_REPLY:
        case VEHICLE_STATUS_APPROVED:
          result= false
          break;
      }

    }

    return result
  }


  /**
   * --------------------------------------------------------------
   * @param {*} vehicle 
   * @returns 
   */
  getVehicleChangeRequestTitle(vehicle) {
    var result = ""
    
    if ( vehicle.VehiclesChangeRequests && vehicle.VehiclesChangeRequests.length > 0  ) {
      var vehicleStatus = vehicle.VehiclesChangeRequests.sort((a, b) => (a.id > b.id ) ? -1 : 1)
      var status        = getVehicleStatus(vehicleStatus[0],this.props.operator.role)

      switch (status.code) {
        case VEHICLE_STATUS_REQUEST:
        case VEHICLE_STATUS_REPLY:
          result = "NUOVI DATI IN ATTESA DI APPROVAZIONE"
          break;
        case VEHICLE_STATUS_APPROVED:
          result = "NUOVI DATI APPROVATI"          
          break;
      }

    }

    return result
    
  }

  /**
   * --------------------------------------------------------------
   * @param {*} vehicle 
   * @returns 
   */
  showVehiclesChangeRequests(vehicle) {
    var result = false

    if ( vehicle.VehiclesChangeRequests && vehicle.VehiclesChangeRequests.length > 0  ) {
      var vehicleStatus = vehicle.VehiclesChangeRequests.sort((a, b) => (a.id > b.id ) ? -1 : 1)
      var status        = getVehicleStatus(vehicleStatus[0],this.props.operator.role)

      switch (status.code) {
        case VEHICLE_STATUS_REQUEST:
        case VEHICLE_STATUS_REPLY:
        case VEHICLE_STATUS_APPROVED:
          result= true
          break;
      }

    }

    return result
  }

  /**
   * --------------------------------------------------------------
   * @param {*} vehicle 
   * @returns 
   */
  showVehiclesChangeRequestsButtons(vehicle) {
    var result = false

    if ( vehicle.VehiclesChangeRequests && vehicle.VehiclesChangeRequests.length > 0  ) {
      var vehicleStatus = vehicle.VehiclesChangeRequests.sort((a, b) => (a.id > b.id ) ? -1 : 1)
      var status        = getVehicleStatus(vehicleStatus[0],this.props.operator.role)

      switch (status.code) {
        case VEHICLE_STATUS_REQUEST:        
          result= true
          break;
      }

    }

    return result

  }
  
  /**
   *  --------------------------------------------------------------
   * @param {*} doc 
   */
  documentDownload (doc) {    
    
    if (doc != null) {      
      this.setState({ downloading: doc.id})
      SostaOnlineApiService.downloadDocument(doc.id)
      .then(data => {    
        fileDownload(data, doc.path, doc.mimeType)
      })
      .then( doc => {
        this.setState({ downloading: null})
      })
      .catch( error => {
        this.setState({ downloading: null})
      })
    }    
  }

  /**
   * --------------------------------------------------------------
   * AGGIORNA LO STATO DELLA VEHICLE CHANGE REQUEST
   * @param {*} status 
   */
  updateVehiclesChangeRequests( status ) {

    this.setState({ updateError: null,  updateMessage: null})

    var changeRequest = this.getVehicleChangeRequest(this.props.vehicle)
    var note          = this.state.note    

    switch ( status ) {
      case VEHICLE_STATUS_DENIED:
      case VEHICLE_STATUS_REPLY:
        if ( !note || note.trim().length <= 0 ) {
          if ( status == VEHICLE_STATUS_DENIED) {
            this.setState({ updateError: "Motivazione rifiuto mancante" })
          }
          else {
            this.setState({ updateError: "Messaggio di istruzioni mancante" })
          }
        }
        else {
          note = note.trim()
          SostaOnlineApiService.updateVehicleChangeRequest(changeRequest.id, status, note)
          .then( data => {            
            this.setState({ changeRequestAction: status })
            if ( this.props.onUpdateVehicleChangeRequest )
              this.props.onUpdateVehicleChangeRequest()
          })
          .catch( result => {
            console.log( "ERROR" , result.error )
            this.setState({ updateError:  result.error  })
          })
        }
        break;

      case VEHICLE_STATUS_APPROVED:
        SostaOnlineApiService.updateVehicleChangeRequest(changeRequest.id, status, null)
          .then( data => {            
            this.setState({ changeRequestAction: status })
            if ( this.props.onUpdateVehicleChangeRequest )
              this.props.onUpdateVehicleChangeRequest()
          })
          .catch( result => {
            console.log( "ERROR" , result.error )
            this.setState({ updateError:  result.error  })
          })        
        break;
                    
      default:
        this.setState({ updateError: "Tipo richiesta errato" })
        break;
      
    }

  }

  /**
   * --------------------------------------------------------------
   * 
   */
  onCloseReviewDocuments() {
    this.setState({ showReviewDocuments: false })
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  getVehicleActiveMarks() {
    var marks = []

    if ( this.props.marks && this.props.marks.length > 0 && this.props.vehicle ) {
      marks = this.props.marks.filter( mark => 
        mark.Vehicles && mark.Vehicles.filter( v => v.id == this.props.vehicle.id ).length > 0        
      )
      marks = getActiveMarks(marks)      
    }

    return marks
  }


  /**
   * --------------------------------------------------------------
   * @returns 
   */
  getRequestedDocumentTypes() {

    var documentTypes = []
    var marks         = this.getVehicleActiveMarks()

    if ( marks && this.props.markTypes && this.props.documentTypes ) {
      documentTypes = getRequestedDocumentTypes(marks, this.props.markTypes, this.props.documentTypes)

    }

    return documentTypes
  }

  /**
   * --------------------------------------------------------------
   * @param {*} element 
   */
   scrollTo(element) {
    scroller.scrollTo(element, {
      duration: 1000,
      delay: 100,
      smooth: true
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} vehicle 
   * @returns 
   */
  areRequiredDocumentsOk(vehicle) {
    var result = false

    var changeRequest          = this.getVehicleChangeRequest(vehicle)
    var approvedDocuments      =  changeRequest.VehiclesChangeRequestsDocuments.filter( vcrd => vcrd.approved )
    var requestedDocumentTypes = this.getRequestedDocumentTypes()

    var checkedDocmentTypes = []

    requestedDocumentTypes.map( rdt => {
      approvedDocuments.map( ad => {
        if ( rdt.id == ad.Document.DocumentTypeId )
          checkedDocmentTypes.push(rdt.id)
      })
    })

    // rimuovo gli elementi doppi
    checkedDocmentTypes = checkedDocmentTypes.filter(function(elem, pos) {
      return checkedDocmentTypes.indexOf(elem) == pos;
    });   

    if ( checkedDocmentTypes.length == requestedDocumentTypes.length )
      result = true

    return result
  }
  
  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {
    const {
      vehicle,
      operator,
      vehicleDeleting,
      onVehicleEditClick,
      onDeleteVehicleClick,
      onCancelDeleteVehicleClick,
      onDeleteVehicle,
      vehiclesDeleteResults,
      onUpdateVehicleChangeRequest
    } = this.props

    const {
      changeRequestAction,
      loading,
      errors,
      updateMessage,
      updateError,
      showReviewDocuments
    } = this.state

    if (loading) {
      return (
      <div className="box">
        <div className="row"><h4>Caricamento in corso...</h4></div>
        <div className="row" style={{padding: "2em", height: "14em"}}>
          <Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />
        </div>
      </div>
      )
    }

    if (errors) {
      return (
      <div className="box text-danger">
        Errore nel caricamento dei dati...
      </div>
      )
    }

    var changeRequest = this.getVehicleChangeRequest(vehicle)    

    return (
      <div className={getBackofficeCardVehicleHeaderClass(vehicle,operator.role)}>
                                      
        <div className="row">
          
          <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10">
            <strong>{this.getVehicleStatus(vehicle)}</strong>
          </div>

          <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-sx-2">
            <OverlayTrigger
              placement="left"
              shouldUpdatePosition={true}
              overlay={<Tooltip id="tooltip5"><BackOfficeUserDetailVehicleLogsContainer vehicleId={vehicle.id}  /></Tooltip>}
              >
              <div className="icon-tooltip-div"><i className="glyphicon glyphicon-info-sign">{}</i></div>
            </OverlayTrigger>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sx-12">
            
            {/* DATI GENERALI VEICOLO */}
            <div className="row">
              <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-sx-1"></div>
              <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-sx-10">
                <div style={{ fontSize: 13, margin: "1em" }}>
                  {vehicle.VehicleOwnershipType && vehicle.VehicleOwnershipType.name}
                </div>
                <h3 className="text-muted">{vehicle.plate_number}</h3>
                <div style={{  display: 'flex', alignContent: "center"}}> 
                  <div style={{ flex: 30, textAlign: 'right', marginRight: '15px' }}>
                    {getVehicleIcon(vehicle.VehiclePowerSupply)} 
                  </div>
                  <div style={{ flex: 70 , textAlign: 'left', marginLeft: '15px', marginTop: '0px' }}>
                    {vehicle.VehiclePowerSupply && vehicle.VehiclePowerSupply.name}
                  </div>
                </div>
              </div>
              <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-sx-1"></div>
            </div>
            
            {/* PULSANTI E MESSAGGI ELIMINAZIONE VEICOLO*/}
            {/* TODO Rendere impossibile la cancellazione nel caso il veicolo sia associato ad uno o più permessi validi */}
            {vehicleDeleting && vehicleDeleting[vehicle.id] && 
              <div className="row" style={{ margin: "1em" , padding: "1em"}}>
                  <div className="row">
                    <div className="col-md-12" >                                    
                        <div style={{ fontSize: 13, margin: "1em" }}>Sei sicuro di voler eliminare questo veicolo?</div>                                    
                        {vehiclesDeleteResults[vehicle.id] === 'loading' && 
                          <div className="col-md-12" >Eliminazione del veicolo in corso...</div>
                        }
                        {vehiclesDeleteResults[vehicle.id] === 'error' && 
                          <div className="col-md-12" >Errore durante l'eliminazione del veicolo</div>
                        }
                    </div>
                  </div>
                  <div className="row">
                    <div  className="col-md-12">
                      <button onClick={() => onDeleteVehicle(vehicle)}     type="button" className="btn btn-danger btn-xs btn-update btn-add-card"><FontAwesomeIcon size="1x" icon={solidIcons.faTrash} />&nbsp;Elimina Veicolo</button>
                      <button onClick={() => onCancelDeleteVehicleClick()} type="button" className="btn btn-default btn-xs btn-update btn-add-card"> Annulla</button>
                    </div>
                  </div>                                
              </div>
            }
            
            {/* PULSANTI ELIMINA / MODIFICA */}
            { vehicleDeleting && !vehicleDeleting[vehicle.id] && this.showVehicleModifyButton(vehicle) &&
            <div className="row" style={{ margin: "1em" , padding: "1em"}}>
              <div className="row">
                <div className="col-md-12">
                  <button onClick={() => onVehicleEditClick(vehicle)}   type="button" className="btn btn-xs btn-update btn-add-card">
                    <FontAwesomeIcon size="1x" icon={solidIcons.faEdit} />&nbsp;Modifica
                  </button>                            
                  <button onClick={() => onDeleteVehicleClick(vehicle)} type="button" className="btn btn-danger btn-xs btn-update btn-add-card">
                    <FontAwesomeIcon size="1x" icon={solidIcons.faTrash} />&nbsp;Elimina
                  </button>
                </div>
              </div>
            </div>                
            }
         
            {/* SEZIONE CHANGE REQUEST */}
            { this.showVehiclesChangeRequests(vehicle) &&             
            <div className="row card-vehicles-request-detail" >

              <div className="row" style={{ marginBottom: "1em" }}>
                <strong>{this.getVehicleChangeRequestTitle(vehicle)}</strong>
              </div>
              
              <div className="row" style={{ marginBottom: "1em" }}>
                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-sx-1"></div>
                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-sx-10">
                  <div style={{ fontSize: 13, margin: "1em" }}>
                    {changeRequest.VehicleOwnershipType && <strong>{changeRequest.VehicleOwnershipType.name}</strong>}
                  </div>
                  <h3 className="text-muted"  style={{color: 'black'}}>{changeRequest.plate_number}</h3>
                  <div style={{  display: 'flex', alignContent: "center"}}> 
                    <div style={{ flex: 30, textAlign: 'right', marginRight: '15px' }}>
                      {getVehicleIcon(changeRequest.VehiclePowerSupply)} 
                    </div>
                    <div style={{ flex: 70 , textAlign: 'left', marginLeft: '15px', marginTop: '0px' }}>
                      {changeRequest.VehiclePowerSupply && <strong>{changeRequest.VehiclePowerSupply.name}</strong>}
                    </div>
                  </div>
                </div>
                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-sx-1"></div>
              </div>
              
              {/* REVISIONE DOCUMENTI */}
              { changeRequest.VehiclesChangeRequestsDocuments && changeRequest.VehiclesChangeRequestsDocuments.length > 0 &&              
                <div className="row" style={{ marginBottom: "2em" }}>
                  <div className="col-md-12">    
                    <OverlayTrigger
                      placement="top"                      
                      overlay={(
                        <Tooltip id="tooltipVCRD" >
                          <div style={{ padding: "10px", textAlign: "left", width: "100%" }}>
                            <div>Documenti richiesti:{' '}<strong>{this.getRequestedDocumentTypes().length}</strong></div>
                            <div>Documenti Caricati:{' '}<strong>{changeRequest.VehiclesChangeRequestsDocuments.length}</strong></div>
                            <div style={{ marginBottom: '10px' }}>
                              Documenti Approvati:{' '}

                              <strong className={classNames({
                                'text-danger': changeRequest.VehiclesChangeRequestsDocuments.filter( vcrd => vcrd.approved ).length !== changeRequest.VehiclesChangeRequestsDocuments.length
                                })}>                              
                                {changeRequest.VehiclesChangeRequestsDocuments.filter( vcrd => vcrd.approved ).length}
                              </strong>
                              
                            </div>                          
                            { this.getRequestedDocumentTypes().map( docType =>                              
                                <div key={"requested-doc-"+docType.id}>
                                  {docType.label}                                 
                                  { changeRequest.VehiclesChangeRequestsDocuments.filter( vcrd => vcrd.approved && vcrd.Document && vcrd.Document.DocumentTypeId == docType.id ).length > 0 ?
                                    <span  style={{ color: '#006f4a', marginLeft: '8px', marginRight: '8px' }} className="glyphicon glyphicon-ok" />
                                    :
                                    <span  style={{ color: '#d9534f', marginLeft: '8px', marginRight: '8px' }} className="glyphicon glyphicon-remove" />
                                  }
                                </div>
                            )}
                            
                          </div>
                        </Tooltip>
                      )}
                    >                                         
                      <span className={ this.areRequiredDocumentsOk(vehicle) ? "text-success" : "text-danger"}>
                        <strong>
                        { this.showVehiclesChangeRequestsButtons(vehicle) ? "Verifica dei documenti necessaria" : "Stato revisione documenti" }
                        </strong>
                        {this.areRequiredDocumentsOk(vehicle) ? 
                        <span  style={{ color: '#006f4a', marginLeft: '8px', marginRight: '8px' }} className="glyphicon glyphicon-ok" />
                        :
                        <span  style={{ color: '#d9534f', marginLeft: '8px', marginRight: '8px' }} className="glyphicon glyphicon-remove" />
                        }
                      </span>                     
                                      
                    </OverlayTrigger> 
                    
                    { this.showVehiclesChangeRequestsButtons(vehicle) &&
                      <React.Fragment>
                        <div className="btn btn-file-browser-confirm" onClick={() => this.setState({ showReviewDocuments: true })}>
                            Revisiona Documenti
                        </div>
                        { showReviewDocuments &&
                          <BackofficeVehicleDocumentsReview
                            onApprovationChanged={onUpdateVehicleChangeRequest}
                            userId={vehicle.userId}
                            onClose={this.onCloseReviewDocuments}
                            open={showReviewDocuments}
                            changeRequest={changeRequest}
                          />
                        }
                      </React.Fragment>
                    }

                  </div>
                </div>
              }
              {/* PERMESSI SU CUI E' ATTIVO/ASSEGNATO IL VEICOLO */}
              { this.getVehicleActiveMarks().length > 0 &&
                <div className="row text-left" style={{ marginBottom: "1em" }}>
                  <div className="row text-left"  style={{ marginLeft: '1em' }}>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-left ">
                      <h5 className="text-info"><strong >Permessi associati al veicolo:</strong></h5>
                    </div>
                  </div>
                  {this.getVehicleActiveMarks().map( ( mark , index ) =>                   
                    <React.Fragment key={"vehicle-active-mark-"+mark.id}>
                    { index === 0  &&
                      <div key="note-header" className="row noteRow" >
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-2 col-xs-3 text-left "><strong>ID</strong></div>
                        <div className="col-xl-9 col-lg-9 col-md-9 col-sm-10 col-xs-9 text-left "><strong>TIPO</strong></div>
                      </div>
                    }
                    <div className="row noteRow" >
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-2 col-xs-3 text-left ">
                        <div className="btn btn-primary btn-xs btn-update" style={{ marginLeft: 0 }} onClick={ () => this.scrollTo("mark-"+mark.id)}>
                          <FontAwesomeIcon size="1x" icon={solidIcons.faArrowAltCircleRight} />&nbsp;&nbsp;{mark.id}
                        </div>
                      </div>
                      <div className="col-xl-9 col-lg-9 col-md-9 col-sm-10 col-xs-9 text-left ">{mark.MarkType.name}</div>
                    </div>
                    </React.Fragment>
                  )}
                </div>
              }

              {/* NOTE RICHIESTA */}
              { changeRequest && changeRequest.VehiclesChangeRequestsNotes && changeRequest.VehiclesChangeRequestsNotes.length > 0 && 
                <div className="row text-left" style={{ marginBottom: "1em" }}>
                  <div className="row text-left"  style={{ marginLeft: '1em' }}>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-left ">
                      <h5 className="text-info"><strong >Note di questa richiesta:</strong></h5>
                    </div>
                  </div>
                  { changeRequest.VehiclesChangeRequestsNotes.map( ( note, index ) =>  {
                      return(
                        <React.Fragment key={"note-"+note.id}>
                        { index === 0  &&
                          <div key="note-header" className="row noteRow" style={{ margin: "1em" }}>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-2 col-xs-3 text-left "><strong>DATA</strong></div>
                            <div className="col-xl-9 col-lg-9 col-md-9 col-sm-10 col-xs-9 text-left "><strong>NOTA</strong></div>
                          </div>
                        }

                        <div key={"note-"+note.id} className="row noteRow"  style={{ margin: '1em' }}>
                          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-2 col-xs-3 text-left ">{ moment(note.createdAt).fromNow() }</div>
                          <div className="col-xl-9 col-lg-8 col-md-8 col-sm-10 col-xs-9 text-left ">{note.note}</div>
                        </div>
                        </React.Fragment>
                      )                      
                    })
                  }                  
                </div>
              }

              {/* PULSANTI AZIONI */}
              { this.showVehiclesChangeRequestsButtons(vehicle) &&  
              <div className="row" style={{ marginBottom: "1em" }}>
                <div className="col-md-12">
                  <button onClick={() => { this.setState({ changeRequestAction: 'showConfirmApprove' ,  updateError: null, updateMessage: null }) } }   type="button" className="btn btn-success btn-xs btn-update">
                    <FontAwesomeIcon size="1x" icon={solidIcons.faPlay} />&nbsp;Approva
                  </button>                            
                  <button onClick={() => { this.setState({ changeRequestAction: 'showConfirmWait',  updateError: null, updateMessage: null }) } } type="button" className="btn btn-warning btn-xs btn-update ">
                    <FontAwesomeIcon size="1x" icon={solidIcons.faPause} />&nbsp;Attesa
                  </button>
                  <button onClick={() => { this.setState({ changeRequestAction: 'showConfirmDeny' ,  updateError: null, updateMessage: null }) } } type="button" className="btn btn-danger btn-xs btn-update">
                    <FontAwesomeIcon size="1x" icon={solidIcons.faStop} />&nbsp;Rifiuta
                  </button>
                </div>
              </div>
              }

              { changeRequestAction === 'showConfirmApprove' && 
              <div style={{ marginBottom: "1em" }}>
                  Si sta per approvare la richiesta di modifica, proseguire?
                  <br/><br/>
                  <button onClick={() => { this.updateVehiclesChangeRequests(VEHICLE_STATUS_APPROVED) } }   type="button" className="btn btn-success btn-xs btn-update">
                  Conferma Approvazione
                </button>
              </div>
              }

              { changeRequestAction === 'showConfirmWait' && 
              <div style={{ marginBottom: "1em"}}>                
                <textarea
                  rows="4"
                  name="note"
                  className="form-control"
                  placeholder="Invia un messaggio all'utente con le istruzioni..."
                  required 
                  type="text"                  
                  onChange={(e) =>this.setState({ note: e.target.value})} />
                <br/>
                <button onClick={() => { this.updateVehiclesChangeRequests(VEHICLE_STATUS_REPLY) } }   type="button" className="btn btn-warning btn-xs btn-update">
                  Invia Istruzioni
                </button>
              </div>
              }

              { changeRequestAction === 'showConfirmDeny' && 
              <div style={{ marginBottom: "1em" }}>
                Si sta per negare la richiesta di modifica
                <br/><br/>
                <textarea
                  rows="4"
                  name="note"
                  className="form-control"
                  placeholder="Motivazione..."
                  required 
                  type="text"                  
                  onChange={(e) =>this.setState({ note: e.target.value})} />
                <br/>
                <button onClick={() => { this.updateVehiclesChangeRequests(VEHICLE_STATUS_DENIED) } }   type="button" className="btn btn-danger btn-xs btn-update">
                  Conferma Rifiuto
                </button>

              </div>
              }

              {/* MESSAGGI */}
              { updateMessage && 
                <div style={{ marginBottom: "1em", fontWeight: 'bold' }}>
                {updateMessage}
                </div>
              }

              { updateError && 
                <div className="textError" style={{ marginBottom: "1em", fontWeight: 'bold' }}>
                {updateError}
                </div>
              }


            </div>              
            }
                                      
          </div>  
        </div>                                                    
      </div>
    )
  }
}

export default reduxForm({
  form: 'BackOfficeUserDetailVehicleForm'
})(BackOfficeUserDetailVehicleForm)
