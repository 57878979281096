import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch } from 'react-router-dom'
import UserAreaProfile from './Profile/UserAreaProfile'
import UserAreaVehicles from './Vehicles/UserAreaVehicles'
import UserAreaMarks from './Mark/UserAreaMarks'

import './UserArea.css'
import RenewModal from '../../components/RenewModal'
import NumberPlateModal from '../../components/NumberPlateModal'
import OrdersHistory from './OrdersHistory/OrdersHistory'
import Documents from './Documents/Documents'
import PasswordChange, { STATE_SEND_OTP } from './Password/PasswordChange'
import Messages from './Messages/Messages'

export default class UserArea extends Component {
  static propTypes = {
    user: PropTypes.object,
    onRenewMark: PropTypes.func.isRequired,
    onOpenRenewMarkModal: PropTypes.func.isRequired,
    onCloseRenewMarkModal: PropTypes.func.isRequired,
    showRenewMarkModal: PropTypes.bool,
    renewingMark: PropTypes.object,
    renewErrors: PropTypes.string,    
    onShowChooseMarksVehicleModal: PropTypes.func.isRequired,
    onHideChooseMarksVehicleModal: PropTypes.func.isRequired,
    onVehicleChoosen: PropTypes.func.isRequired,
    showChooseMarksVehicleModal: PropTypes.bool,
    showMarksVehicleModalNumber: PropTypes.object,
    chooseMarkVehicleError: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.state = {
      vehicleEditing: {}
    }

    this.isUserBelongsToCategory = this.isUserBelongsToCategory.bind(this)
  }

  isUserBelongsToCategory(categoryId) {
    if (!this.props.user.categories) {
      return false
    }

    let filtered = this.props.user.categories.filter(item => {
      return item.id === categoryId
    })

    return filtered.length > 0
  }

  onVehicleEditClick(vehicle) {
    let vehiclesEditing = {}
    vehiclesEditing[vehicle.id] = true
    this.setState({ vehicleEditing: vehiclesEditing })
  }

  render() {
    const {
      renewingMark,
      showRenewMarkModal,
      onRenewMark,
      onCloseRenewMarkModal,
      renewErrors,
      onHideChooseMarksVehicleModal,
      showChooseMarksVehicleModal,
      showMarksVehicleModalNumber,
      onVehicleChoosen,
      chooseMarkVehicleError,
      settingsMessages
    } = this.props
    return (
      <div>
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            { !this.props.user.userConfirmed && <div>
              <h5 className="title-danger text-center">
                <strong>Attenzione! </strong>
                <br/>
                <br/>
                Non hai ancora confermato la tua mail,{' '}
                il download e l'acquisto dei permessi sarà disponibile solo a mail confermata            
              </h5>
            </div>}
          </div>
        </div>
        
        <Switch>
          <Route path={'/user-area/profile'}  component={(props) => <div>
                <UserAreaProfile {...this.props} {...props} />                
              </div>
            } 
          />
          <Route path={'/user-area/vehicles'} component={(props) => <UserAreaVehicles {...this.props} {...props} />} />
          <Route path={'/user-area/marks'}    component={(props) => <UserAreaMarks
                onOpenRenewMarkModal={this.onOpenRenewMarkModal}
                onCloseRenewMarkModal={this.onCloseRenewMarkModal}
                {...this.props}
                {...props}
              />
            } 
          />
          <Route path={'/user-area/password-change'} component={(props) => <PasswordChange user={this.props.user} action={STATE_SEND_OTP} />} />
          <Route path={'/user-area/order-history'}   component={(props) => <OrdersHistory  user={this.props.user} /> } />
          <Route path={'/user-area/documents'}       component={(props) => <Documents {...this.props} {...props} />} />
          { settingsMessages.enable && 
            <Route path={'/user-area/messages'}      component={(props) =>  <Messages user={this.props.user}/>} />
          }
          <Redirect to={'/user-area/marks'} />
        </Switch>

        <RenewModal
          mark={renewingMark}
          show={showRenewMarkModal}
          onRenewMark={onRenewMark}
          renewErrors={renewErrors}
          onClose={onCloseRenewMarkModal}
        />
        
        <NumberPlateModal
          open={showChooseMarksVehicleModal}
          closeModal={onHideChooseMarksVehicleModal}
          mark={showMarksVehicleModalNumber}
          onVehicleChoosen={onVehicleChoosen}
          error={chooseMarkVehicleError}          
        />
                
      </div>
    )
  }
}
