import React, { Component } from 'react'
import PropTypes from 'prop-types'
import "react-responsive-modal/styles.css";
import { reduxForm } from 'redux-form';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SostaOnlineApiService from 'services/SostaOnlineApiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reportActions } from 'redux/modules/Report' 
import {actions as authActions} from 'redux/modules/Auth'
import { Oval } from 'react-loader-spinner';

moment.locale('it')

/**
 * --------------------------------------------------------------------------
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  return {
    backofficeValues: state.form.backofficeSettings,
    auth: state.auth,
    stateReport : state.report   
  }
}

/**
 * --------------------------------------------------------------------------
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    authActions:   bindActionCreators(authActions,   dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}

class MarksPersonsPerHouseUnits extends Component {

  static propTypes = {    
    userId:     PropTypes.object.isRequired,   
    markTypeId: PropTypes.number.isRequired    
  }

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = {
      userId: this.props.userId,
      errors: null,
      items: []      
    }        
       
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  stateInit() {
    this.setState({      
      userId: this.props.userId,
      errors: null,
      items: []   
    })    

  }

  /**
   * --------------------------------------------------------------
   */
  componentDidMount() {
    this.stateInit()
    this.fetchData()
  }

  /**
   * --------------------------------------------------------------
   * @param {*} prevProps 
   */
   componentDidUpdate(prevProps) {    
    
    if ( prevProps.userId != this.props.userId  ) {
      this.stateInit()
      this.fetchData()
    }
        
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  async fetchData() {

    var items = []
// questa dove viene usata?
    SostaOnlineApiService.fetchMarksPersonsPerHouseUnits(
      this.props.userId,
      this.props.markTypeId
    )
    .then(  marksPersonsHouseUnits => {      

      if ( marksPersonsHouseUnits != null && marksPersonsHouseUnits.data != null ) {        

        for ( let key in marksPersonsHouseUnits.data ) {
          var item = marksPersonsHouseUnits.data[key]
          items.push(item)          
        }
      }

      this.setState({ items: items })

    })
    .catch( error => {
      console.log("ERROR",error)
    })
    

  }

  

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {

    const {        
      userId,
      markTypeId      
    } = this.props

    const {
      items,
      errors, 
    } = this.state

    return (
      <React.Fragment>
              
        { ( userId  == null || markTypeId == null ) &&
        <div className="col-md-12">
          <div className="row">          
            <div className="text-center" style={{ margin: "1em" }}>
              <div className="row"><h2>Caricamento in corso...</h2></div>
              <div className="row" style={{padding: "2em", height: "40em"}}>
                <Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />
              </div>
            </div>
          </div>          
        </div>
        }

        { userId != null && markTypeId != null &&
        <div className="col-md-12">
          <div className="app-modal-body">                                  
            
            <React.Fragment>
              
              <div className="row" style={{ marginBottom: "1em" }}>
                <div className="col-xs-2"><h5 style={{ marginTop: 10 , marginBottom: 5, verticalAlign: "top" }}><strong>Id Permesso</strong></h5></div>
                <div className="col-xs-2"><h5 style={{ marginTop: 10 , marginBottom: 5, verticalAlign: "top" }}><strong>Id Utente</strong></h5></div>
                <div className="col-xs-4"><h5 style={{ marginTop: 10 , marginBottom: 5, verticalAlign: "top" }}><strong>Nome Utente</strong></h5></div>
                <div className="col-xs-4"><h5 style={{ marginTop: 10 , marginBottom: 5, verticalAlign: "top" }}><strong>Cognome Utente</strong></h5></div>
              </div>

              {items.map( ( item , index ) => 
                <div className="row" key={index} style={{ marginBottom: "1em" }}>
                  <div className="col-xs-2">                    
                    <OverlayTrigger placement="top" overlay={<Tooltip id="1">Clicca per accedere al permesso dell'utente { item.User.firstName+" "+item.User.lastName }</Tooltip>}> 
                      {/* TODO trovare il modo per sostiturire il window.location cn l'oggetto NavLink che al momento in questa modale non sembra funzionare */}                     
                      <Button className="mainBtn" onClick={( ) => { window.location.href = "/backoffice/users/"+item.User.id+"?scrollTo=mark-"+item.id }} >
                        <FontAwesomeIcon size="1x" className="mainIconsRevNoAction" icon={solidIcons.faWallet} />&nbsp;{item.id}
                      </Button>
                    </OverlayTrigger>  
                  </div>    
                  <div className="col-xs-2">                    
                    <OverlayTrigger placement="top" overlay={<Tooltip id="1">Clicca per accedere al profilo dell'utente { item.User.firstName+" "+item.User.lastName }</Tooltip>}>
                      {/* TODO trovare il modo per sostiturire il window.location cn l'oggetto NavLink che al momento in questa modale non sembra funzionare */}                                     
                      <Button className="mainBtn" onClick={( ) => { window.location.href = "/backoffice/users/"+item.User.id }} >
                        <FontAwesomeIcon size="1x" className="mainIconsRevNoAction" icon={solidIcons.faUser} />&nbsp;{item.User.id}
                      </Button>
                    </OverlayTrigger>                    
                  </div>
                  <div className="col-xs-4">{item.User.firstName}</div>
                  <div className="col-xs-4">{item.User.lastName}</div>
                </div>
              )}

              <div className="row" style={{ marginBottom: "1em" }}>
                { errors && 
                  <h4 className="col-xs-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                    {errors}
                  </h4>
                }               
              </div>              
              
              <div className="row" style={{ marginBottom: "1em" }}>
                <div className="col-xs-12 text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                  {/* <Button className="btn alertBtn" onClick={() => { this.onCancelEdit() } }>Annulla</Button>{' '} */ }                  
                </div>
              </div>          

            </React.Fragment>            
          </div>
        </div>           
        }  
              
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'MarksPersonsPerHouseUnits'})(MarksPersonsPerHouseUnits))
