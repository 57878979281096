import React, {Component} from 'react'
import PropTypes from 'prop-types'
import SostaOnlineApiService from '../../../services/SostaOnlineApiService'

export default class MarkTypeFilter extends Component {
  static propTypes = {
    filter: PropTypes.any,
    onChange: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      markTypes: []
    }
  }

  async componentDidMount () {
    this.setState({loading: true})
    const markTypes = await SostaOnlineApiService.fetchMarkTypes()
    this.setState({
      markTypes: markTypes.data,
      loading: false
    })
  }

  render () {
    const {filter, onChange} = this.props
    const {loading, markTypes} = this.state

    if (loading) {
      return (
        <div className="text-info">Caricamento in corso...</div>
      )
    }

    return (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : ''}
      >
        <option value="">Tutti</option>
        {markTypes.map((item, index) => {
          return (
            <option
              key={item.id + '-' + index}
              value={item.id}>
              {item.label}
            </option>
          )
        })}
      </select>
    )
  }
}
