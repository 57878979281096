import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'
import queryString from 'query-string'
import SostaOnlineApiService from '../../../services/SostaOnlineApiService'

export default class EnableSpidProfile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      success: false,
      params: queryString.parse(props.location.search)
    }
  }

  componentDidMount () {
    SostaOnlineApiService.enableSpidProfile(this.state.params.userId, this.state.params.key).then(data => {
      this.setState({loading: false, success: true})
    }).catch(err => {
      this.setState({loading: false})
    })
  }

  render () {
    if (this.state.loading) {
      return (<h3>Caricamento in corso...</h3>)
    }

    return (<div className="main-container container">
      <div className="col-md-8 col-md-offset-2">
        <div className="panel panel-default registration-panel-default panel-border">
          <div className="panel-body text-center">
            <h3 className="title3">Mail SPID Confermata</h3>
            <div className="panel-body flex-grow panel-2">La tua e-mail è stata confermata correttamente, Profilo SPID abilitato</div>
            <NavLink to={'/'} className="successBtn">Vai al login</NavLink>
          </div>
        </div>
      </div>
    </div>)

  }
}
