import React, { Component } from 'react'
import ResellerNavMenuComponent from '../../components/Reseller/Navmenu/ResellerNavMenuComponent'
import './ResellerNavigatorScreen.css'
import { getWindowHeight } from 'libs/utils';
import SostaOnlineApiService from 'services/SostaOnlineApiService'
import ResellerModal from '../../components/Reseller/Modals/ResellerModal'
import * as solidIcons from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import history from '../../history';
import { isNumber } from 'lodash';
import { formatDate } from 'libs/utils';

const mapStateToProps = (state) => {
  return {
    stateAuth: state.auth
  }
}
export default class ResellerNavigatorScreen extends Component {
  // ----------------------------------------------------------------
  //
  //
  constructor(props) {
    super(props);
    this.state = {
      minHeight: 0,
      badgeData: {},
      isModalOpen: false // Definisce lo stato iniziale per la modale
    };
    this.handleResize = this.handleResize.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  // Funzione per mostrare la modale con i record
  handleButtonClick() {

    this.setState({ isModalOpen: !this.state.isModalOpen });
    //this.state.isModalOpen ? console.log('La modale è aperta: ', this.state.isModalOpen) : console.log('La modale è chiusa: ', this.state.isModalOpen);
  }

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  }


  // ----------------------------------------------------------------
  //
  //
  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    this.setState({ minHeight: getWindowHeight() })

    // Componente Nav Reseller per ottenere i dati degli abbonamenti del reseller
    // Quindi compilare lista da mostrare nei tabs della modale se aperta con il click nel bottone, altrimenti direttamente un riassunto nel bottone
    this.fetchBadgeData();
    // Sarebbe inutile caricare tutti questi dati all'apertura se serve solo quando si apre la modale
    // TODO: ottimizzare la funzione in modo che suddivida la query in base alla modale aperta
  }

  // ----------------------------------------------------------------
  //
  //
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  // ----------------------------------------------------------------
  //
  //
  handleResize() {
    this.setState({ "minHeight": getWindowHeight() })
  }

  // Funzione per ottenere i dati degli abbonamenti e compilare una lista
  // TODO: da eliminare, qui solo per retrocompatibilità (non so se era stata utilizzata da Bruno)
  /*async oldgetMarksDetails(recordsMarksResellers) {
    // Effettua la query per ottenere il nome dell'abbonamento in base al markId->markTypeId nella tabella markTypes
    // Effettua la query per ottenere la targa del veicolo in base al markId->vehicleId nella tabella Vehicles
    // Restituisce il nome dell'abbonamento
    let abbonamento = [];
    let recordMarkData = []
    let recordTypeData = []
    let recordVehicleData = []
    let recordVehicleDataZero = []
    let markName = ''
    let idTipoVeicolo = 0
    let recordVehicleTypeData = []
    let recordVehicleTypeDataName = ''
    let datiUtente = ''
    let firstName = ''
    let lastName = ''
    let fiscalCode = ''
    let questaTarga = ''
    let recordUtenteData = []
    for (const record of recordsMarksResellers) {
      try {
        const idPermesso = record.markId;
        await SostaOnlineApiService.fetchMarkResellers(idPermesso).then(async (recordMark) => {
          recordMarkData = recordMark.data;
          //console.log('Permesso1:', recordMark);
          //console.log('Permesso2:', recordMarkData);
        }).catch((err) => {
          console.log(err);
        });

        await SostaOnlineApiService.fetchMarkType(recordMarkData.markTypeId).then(async (recordType) => {
          recordTypeData = recordType.data;
          //console.log('Tipo:', recordType);
          markName = recordTypeData.name;
          //console.log('Tipo e Nome Abbonamento:', recordMarkData.markTypeId, ',', markName);
        }).catch((err) => {
          console.log(err);
        });

        // Funzione per generare la targa e il tipo di veicolo
        await SostaOnlineApiService.getVehicle(recordMarkData.activeVehicleId).then(async (recordVehicle) => {
          recordVehicleData = recordVehicle.data;
          //console.log('recordVehicle:', recordVehicle);
          //console.log('Veicolo:', recordVehicleData);
          recordVehicleDataZero = recordVehicleData[0];
          //console.log('Dati Veicolo:', recordVehicleDataZero);
          if (recordVehicleDataZero == null || recordVehicleDataZero == undefined || recordVehicleDataZero.plate_number == undefined || recordVehicleDataZero.plate_number == null || recordVehicleDataZero.plate_number == '') {

            if (recordVehicleData.plate_number != undefined && recordVehicleData.plate_number != null && recordVehicleData.plate_number != '') {
              questaTarga = recordVehicleData.plate_number;
              idTipoVeicolo = recordVehicleData.VehiclePowerSupplyId;
              if (idTipoVeicolo != 0) {
                // Funzione per generare i nomi dei tipi di veicoli
                await SostaOnlineApiService.fetchVehiclePowerSupply(idTipoVeicolo).then(async (recordVehicleType) => {
                  recordVehicleTypeData = recordVehicleType.data;
                  //console.log('Tipo Veicolo:', recordVehicleType);
                  //console.log('Tipo Veicolo:', recordVehicleTypeData);
                  recordVehicleTypeDataName = recordVehicleTypeData.name;
                  //console.log('Nome Tipo Veicolo:', recordVehicleTypeDataName);
                }).catch((err) => {
                  console.log(err);
                });
                questaTarga = questaTarga + ' [' + recordVehicleTypeDataName + ']';
              } else {
                questaTarga = questaTarga + '[atomica]';
              }
            }
          } else {
            idTipoVeicolo = recordVehicleDataZero.VehiclePowerSupplyId;

            if (idTipoVeicolo != 0) {
              // Funzione per generare i nomi dei tipi di veicoli
              await SostaOnlineApiService.fetchVehiclePowerSupply(idTipoVeicolo).then(async (recordVehicleType) => {
                recordVehicleTypeData = recordVehicleType.data;
                //console.log('Tipo Veicolo:', recordVehicleType);
                //console.log('Tipo Veicolo:', recordVehicleTypeData);
                recordVehicleTypeDataName = recordVehicleTypeData.name;
                //console.log('Nome Tipo Veicolo:', recordVehicleTypeDataName);
              }).catch((err) => {
                console.log(err);
              });
              questaTarga = recordVehicleDataZero.plate_number + ' [' + recordVehicleTypeDataName + ']';
            } else {
              questaTarga = recordVehicleDataZero.plate_number + '[atomica]';
            }
          }
        }).catch((err) => {
          console.log(err);
        });

        // Funzione per generare i nomi degli utenti dai loro id
        const idUtente = record.userId;
        // Effettua la query per ottenere il nome e il cognome dell'utente in base all'userId
        await SostaOnlineApiService.fetchUserReseller(idUtente).then(async (recordUtente) => {
          recordUtenteData = recordUtente.data;
          //console.log('Utente:', recordUtenteData);
          datiUtente = recordUtenteData.firstName + ' ' + recordUtenteData.lastName + ' (' + recordUtenteData.email + ')' + ' [' + recordUtenteData.fiscalCode + ']';
          firstName = recordUtenteData.firstName
          lastName = recordUtenteData.lastName
          fiscalCode = recordUtenteData.fiscalCode
          // Restituisce il nome, il cognome e altri dati salienti
        }).catch((err) => {
          console.log(err);
        });

        abbonamento.push({ markNum: idPermesso, markName: markName, markPlate: questaTarga, userData: datiUtente, userFirstName: firstName, userLastName: lastName, userCf: fiscalCode });
        //console.log('Dati Abbonamento:', abbonamento);
      } catch (error) {
        console.error('Error:', error);
      }
    }

    return abbonamento;
  }*/

  async fetchBadgeData() {
    let reseller = this.props.user;
    let budgetValue = reseller.budget;
    //console.log ( "ResellerNavigatorScreen: fetchBadgeData: thisBudget:",thisBudget )
    let buttonColor = 'Red';
    let marksBudgetValue = 0;
    let residualBudget = 0;
    let iconType = solidIcons.faSquare;
    let marksTotalValue = 0;
    let marksResellersTotal = 0;
    let MarksResellers = { length: 0, rows: null };
    let theBudget = 0;
    let thePercentage = 0;
    let theTotal = 0;
    let theResidual = 0;
    let badge = {};
    let countMarksResellersTotal;

    try {

      let actualDate = new Date();
      let dateFinal = (formatDate(actualDate.toISOString().split('T')[0]));      
      let questaData = moment(dateFinal, 'YYYY/MM/DD').format('YYYY/MM/DD');

      let resellerName = await SostaOnlineApiService.getMarksResellersName(reseller.id);      
      let temp = await SostaOnlineApiService.getMarksResellersList(reseller.id, resellerName.data);   
      //console.log('ResellerNavigatorScreen: fetchBadgeData:temp', temp);
      countMarksResellersTotal = temp.data && temp.data.total ? temp.data.total : 0;               
      
      // const records = await SostaOnlineApiService.getMarksResellersList(reseller.id, resellerName.data, questaData);
      const records = await SostaOnlineApiService.getMarksResellersList(reseller.id, resellerName.data, questaData);    

      if (records) {
        const recordsMarksResellers = records.data.rows;       
        marksResellersTotal = records.data.total;        
        marksTotalValue = recordsMarksResellers.reduce((acc, recordsMarksResellers) => acc + parseFloat(recordsMarksResellers.price), 0);
        
        // Calcola qui tutti i dati necessari direttamente da recordsMarksResellers
        // Esempio:
        if (budgetValue == -1) {
          buttonColor = 'Green';
          iconType = solidIcons.faInfinity;
          marksBudgetValue = '∞'
          residualBudget = '∞'
        } else if (budgetValue > marksTotalValue) {
          buttonColor = 'Green';
          marksBudgetValue = budgetValue;
          iconType = solidIcons.faCheckSquare;
          residualBudget = budgetValue - marksTotalValue;
        } else if (budgetValue <= marksTotalValue) {
          buttonColor = 'Red';
          marksBudgetValue = budgetValue;
          iconType = solidIcons.faSquare;
          residualBudget = budgetValue - marksTotalValue;
        } else if (budgetValue === null || budgetValue === undefined || budgetValue === 0) {
          buttonColor = 'Red';
          marksBudgetValue = 0;
          iconType = solidIcons.faSquare;
          residualBudget = 0;
        }
        // Imposta lo stato del componente con i valori ottenuti
        if (recordsMarksResellers != null && recordsMarksResellers.length != 0 && recordsMarksResellers != undefined && recordsMarksResellers != []) {
          // agiamo sull'impossibile
          MarksResellers = recordsMarksResellers //this.getMarksDetails(recordsMarksResellers);
          //console.log('ResellerNavigatorScreen: fetchBadgeData: MarksResellers:', MarksResellers)
        }
        // Codice per aprire la finestra modale con 3 tab
        // Tab 1 (predefinito): Situazione reseller, budget attuale (fido massimo permessi) vs totale importi permessi generati, numero totale permessi generati vs totale permessi consentiti
        // Tab 2 : Nuove notifiche (messaggi, permessi, veicoli)
        // Tab 3 : Numero utenti Creati, con ultimi 5 utenti, ultimi 5 veicoli generati, ultimi permessi generati
        // aggiorno i dati
      }
      //console.log ("ResellerNavigatorScreen fetchBadgeData: marksBudgetValue residualBudget marksBudgetValue", marksBudgetValue, residualBudget, marksBudgetValue)
      theBudget = marksBudgetValue == '∞' ? '∞' : this.formatNumber(marksBudgetValue);
      theResidual = residualBudget == '∞' ? '∞' : this.formatNumber(residualBudget);
      thePercentage = marksBudgetValue == '∞' ? '0‰' : this.percentage(marksBudgetValue, marksTotalValue, 1) + "%";
      theTotal = this.formatNumber(marksTotalValue)
      badge = {
        MarksResellers,
        marksResellersTotal,
        marksTotalValue,
        marksBudgetValue,
        buttonColor,
        iconType,
        theBudget,
        thePercentage,
        theTotal,
        theResidual,
        countMarksResellersTotal
      }
      this.setState({
        badgeData: badge
      });
    } catch (error) {
      console.error('Error:', error);
    }
  }

  percentage(maxValue, nowValue, mode) {
    if (mode) {
      return ((nowValue / maxValue) * 100).toFixed(2);
    } else {
      return (((maxValue - nowValue) / nowValue) * 100).toFixed(2);
    }
  }

  formatNumber(number, mode = 2) {
    //console.log("ResellerNavigatorScreen: formatNumber number",number)
    let numberText = isNumber(number) ? "€ " + number.toFixed(2) : number === null || number === undefined ? 0 : number;
    let formattedNumber = numberText;
    if (!isNumber(formattedNumber)) {
      // Elimina i ,00 se ci sono
      formattedNumber = formattedNumber.replace(/\.00$/, '');
      // Aggiunge il separator
      formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    return formattedNumber;
  }

  render() {

    if (this.props) {
      const {
        user,
        stateAuth,
        reportActions
      } = this.props

      const { minHeight, isModalOpen, badgeData } = this.state
      //console.log("ResellerNavigatorScreen: state:", this.state)
      //console.log("ResellerNavigatorScreen: props:", this.props)

      //const { MarksResellers, marksBudgetValue, marksTotalValue, marksResellersTotal, buttonColor, iconType } = badgeData;
      if (user, badgeData) {
        return (
          <div style={{ minHeight: (minHeight + 130) }}>
            <div className="reseller">
              <div className="row">
                <ResellerNavMenuComponent reportActions={reportActions} stateAuth={stateAuth} badgeData={badgeData} isModalOpen={isModalOpen} onHandleButtonClick={this.handleButtonClick} onMarksWaitingClick={() => { this.props.onMarksWaitingClick() }} {...this.props} />
                {/* 
                <div className="col-sm-2 main-content">
                  <ResellerMenuComponent {...this.props} />
                </div>*/}
                <div className="col-sm-1">
                </div>
                <ResellerModal reportActions={reportActions} stateAuth={stateAuth} badgeData={badgeData} isModalOpen={isModalOpen} onCloseModal={this.handleCloseModal} onHandleButtonClick={this.handleButtonClick} {...this.props} /> {/* Utilizza il componente con le Tabs nella modale */}
                <div className="col-sm-10 sidebar-right">
                  {this.props.children}
                </div>
              </div >
            </div >
          </div >

        );
      }
      return null;
    }
  }
}