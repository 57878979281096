import React, {Component} from 'react'
import ReactTable         from 'react-table'
import MarkTypeFilter     from '../components/ReactTable/Filters/MarkTypeFilter'
import { Oval } from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import { getGridHeight, getWindowHeight } from 'libs/utils'
import Parser from 'html-react-parser'
import MarkStatusFilter from 'components/ReactTable/Filters/MarkStatusFilter'
import { getMarkStatus } from 'libs/marks'

export default class BackOfficeMarkScreen extends Component {
  
  /**
   * ----------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props);
    this.state = {
      maxHeight: 0,
      showUserDetailModal: false,
      selectedUser: null,
      columns: [
        {
          Header: 'ID',
          accessor: 'id',
          filterable: true,
          sortable: false,
          width: 80,
          Cell: (row) => {
            return (<div>{row.value}</div>)
          }
        },
        {
          Header: 'Stato',
          accessor: '',
          filterable: true,
          sortable: false,
          minWidth: 100,
          Cell: (row) => {            
            var status = getMarkStatus(row.value)          
            return (<div style={{ 'color': status.color }} >{status.status}</div>)
          },
          Filter: ({ filter, onChange }) => <MarkStatusFilter filter={filter} onChange={onChange} />
        },
        {
          Header: 'Nome',
          accessor: 'User.firstName',
          filterable: true,
          sortable: false,
          minWidth: 100,
          Cell: (row) => {
            return (<div>{row.value}</div>)
          }
        },
        {
          Header: 'Cognome',
          accessor: 'User.lastName',
          filterable: true,
          sortable: false,
          minWidth: 100,
          Cell: (row) => {
            return (<div>{row.value}</div>)
          }
        },
        {
          Header: 'Tipo Permesso',
          accessor: 'MarkType.label',
          filterable: true,
          sortable: false,
          minWidth: 200,
          Cell: (row) => {
            return (<div>{row.value}</div>)
          },
          Filter: ({ filter, onChange }) => <MarkTypeFilter filter={filter} onChange={onChange} />
        },        
        {
          Header: 'Veicoli',
          accessor: 'Vehicles',
          filterable: true,
          sortable: false,
          minWidth: 130,
          Cell: (row) => {
            var vehicles = ''

            row.value.map((item, index) => {
              
              vehicles = vehicles + item.plate_number
              if (index < row.value.length - 1) {
                vehicles = vehicles + '\n '
              }
            })

            return <div>{Parser( vehicles.replace(/\n/g,"<br/>"))}</div>
            
            
          }
        }
      ]
    }

    this.getTotalPages = this.getTotalPages.bind(this)
    this.onFetchData   = this.onFetchData.bind(this)
    this.handleResize  = this.handleResize.bind(this)
  }

  /**
   * ----------------------------------------------------------------
   */
  componentDidMount () {    
    window.addEventListener('resize', this.handleResize)
    this.setState({ maxHeight : getGridHeight() })
  }

  /**
   * ----------------------------------------------------------------
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  /**
   * ----------------------------------------------------------------
   */
  handleResize ( ) {        
    this.setState({ maxHeight : getGridHeight() })    
  }

  /**
   * ----------------------------------------------------------------
   */
  onFetchData (state, instance) {
    let filterObj = {}
    for (let i in state.filtered) {
      let f = state.filtered[i]

      if (f.id === 'id') {
        filterObj['id'] = f.value
      }

      if (f.id === 'Vehicles') {
        filterObj['plate_number'] = f.value
      }

      if (f.id === 'User.firstName') {
        filterObj['firstName'] = f.value
      }

      if (f.id === 'User.lastName') {
        filterObj['lastName'] = f.value
      }

      if (f.id === 'MarkType.label') {
        filterObj['markTypeId'] = f.value
      }

      if ( f.id == null || f.id.length <= 0 )  {
        filterObj['status'] = f.value
      }

    }

    let sortObj = {}
    for (let i in state.sorted) {
      let s = state.sorted[i]
      sortObj[s.id] = s.desc ? 'desc' : 'asc'
    }

    this.props.fetchMarks(filterObj, state.pageSize, state.page)
  }

  /**
   * ----------------------------------------------------------------
   */
  getTotalPages () {
    const {pagination} = this.props

    if (!pagination) {
      return 0
    }

    if (pagination.total === 0) {
      return 0
    }

    return Math.ceil(pagination.total / pagination.limit)
  }

  /**
   * ----------------------------------------------------------------
   */
  render () {
    const {
      columns,
      maxHeight
    } = this.state
    return (<div>
      <ReactTable
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            onClick: (e, handleOriginal) => {
              if (!rowInfo) {
                return
              }
              this.props.onRowClick(rowInfo.index, rowInfo.original)
            }}
        }}
        manual
        filterable
        loading={this.props.loading}
        noDataText={'Nessun Permesso Presente'}
        onFetchData={this.onFetchData}
        pages={this.getTotalPages()}
        className="-highlight"
        data={this.props.marks ? this.props.marks : []}
        style={{ maxHeight: maxHeight }}
        defaultPageSize={100}
        nextText={<FontAwesomeIcon  className="mainIconsNoAction"  size="2x" icon={solidIcons.faArrowRight} />}
        previousText={<FontAwesomeIcon  className="mainIconsNoAction"  size="2x" icon={solidIcons.faArrowLeft} />}
        loadingText={<Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />}
        rowsText="elementi"
        ofText="di"
        pageText="Pagina"
        columns={columns} />
    </div>)
  }
}
