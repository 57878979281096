import React, { Component }  from 'react'
import SostaOnlineApiService from '../../../services/SostaOnlineApiService'
import moment                from 'moment'
import { FontAwesomeIcon }   from '@fortawesome/react-fontawesome'
import  * as solidIcons      from '@fortawesome/free-solid-svg-icons'
import { Oval }              from 'react-loader-spinner'
import { actions as authActions} from 'redux/modules/Auth'
import { bindActionCreators }    from 'redux'
import { connect}                from 'react-redux'
import NewDocumentModal from 'components/FileManager/NewDocumentModal'
import { NEWS_SECTIONS, getHtmlMsgFromString, getNewsStyle } from 'libs/news'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";


const fileDownload = require('js-file-download')
const TextUser     = (props) => <div className={props.bold ? 'text-bold-user' : 'text-regular-user'} style={{textAlign: 'center'}}>{props.children}</div>

moment.locale('it')

const mapStateToProps = (state) => {
  return {
    stateAuth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authActions:   bindActionCreators(authActions,   dispatch)    
  }
}


class Documents extends Component {
  static propTypes = {}

  /**
   *  --------------------------------------------------------------
   * @param {*} props 
   */
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      downloading: null,
      documents: [],
      editing: {},
      editedName: null,
      errorMsg: null,
      showNewDocumentModal: false,
      news: []
    }

    this.onUploadDocument        = this.onUploadDocument.bind(this)
    this.onCloseNewDocumentModal = this.onCloseNewDocumentModal.bind(this)
  }

  /**
   *  --------------------------------------------------------------
   */
  async componentDidMount () {
    this.setState({loading: true})
    await this.fetchDocuments()    
    await this.fetchNews()
    this.setState({loading: false})
  }

  /**
   *  --------------------------------------------------------------
   * 
   */
  async fetchNews() {
    const response = await SostaOnlineApiService.fetchNewsMe(NEWS_SECTIONS.USERDOCUMENTS.value)       
    this.setState({
      news: response.data
    })      
  }

  /**
   *  --------------------------------------------------------------
   * 
   */
  async fetchDocuments () {
    await SostaOnlineApiService.fetchMyDocuments()
      .then( docResult => {
          if ( docResult.result === 'ok' ) {                                   
              var editing = {}

              docResult.data.map( doc => {
                editing[doc.id] = false
              })

              this.setState({
                'documents':docResult.data,
                'editing': editing
              })
          }
      } )
      .catch(err => {                        
          console.log("ERR",err)            
      })  
  }

  /**
   *  --------------------------------------------------------------
   * @returns 
   */
  getSortedList() {
    var {            
        documents
    } = this.state

    return documents ? documents.sort((a, b) => (a.id > b.id ) ? -1 : 1) : []
  }

  /**
   *  --------------------------------------------------------------
   * @param {*} doc 
   */
  onDocumentDownload (doc) {    
    
    if (doc != null) {      
      this.setState({ downloading: doc.id})
      SostaOnlineApiService.downloadDocumentMe(doc.id).then(data => {    
        fileDownload(data, doc.path, doc.mimeType)
      })
      .then( doc => {
        this.setState({ downloading: null})
      })
      .catch( error => {
        this.setState({ downloading: null})
      })
    }    
  }

  /**
   * --------------------------------------------------------------
   * @param {*} doc 
   */
  onDocumentEdit(doc) {    
    var editing = this.state.editing
    var editinDoc = !editing[doc.id]
       
    this.state.documents.map( doc => {      
      editing[doc.id] = false
    })
              
    editing[doc.id] = editinDoc    
    this.setState({ 
      editing: editing,
      editedName: doc.name,
      errorMsg: null
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} doc 
   */
  onDocumentSave(doc) {    

    var updateDocument = {
      id: doc.id,
      name: this.state.editedName
    }    

    SostaOnlineApiService.updateDocumentMe(updateDocument)
      .then( data => {      
        if ( data.result === "ok" ) {
          this.onDocumentEdit(doc)
          this.fetchDocuments()
        }
        else {        
          this.setState({ errorMsg: data.error })
        }
      })
      .catch( data => {   

        console.log("ERROR",data.error)

        if ( data.error === "Fields error" )  
          this.setState({ errorMsg: 'Il nome documento è obbligatorio' })
        else
          this.setState({ errorMsg: 'Errore durante l\'aggiornamento del documento' })
      })

  }

  /**
   * --------------------------------------------------------------   
   * @param {*} name 
   */
  onChangeName(name) {
    this.setState({editedName: name})    
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  showNewDocumentModal(evt) {
    this.setState({showNewDocumentModal: true})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} evt 
   */
  onCloseNewDocumentModal(evt) {
    this.setState({showNewDocumentModal: false})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} document 
   */
  async onUploadDocument(document) {
    this.setState({loading: true})
    await this.fetchDocuments()    
    this.setState({loading: false})
  }

  /**
   *  --------------------------------------------------------------
   * @returns 
   */
  render () {
    const {
      user 
    } = this.props    
    
    const {
      loading,
      downloading,
      editing,
      editedName,
      errorMsg,
      showNewDocumentModal, 
      news
    } = this.state

    const documents = this.getSortedList()

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-8">
            <div className="panel panel-default panel-border">
                              
              <h2 className="login-title">
                DOCUMENTI CARICATI
              </h2>
              
              <div className="panel-body">
                <div className="col-md-3">
                  <FontAwesomeIcon size="6x" className="sectionIcons" icon={solidIcons.faFolderOpen} />
                </div>                                  
                  {loading 
                    ? ( <div>Caricamento in corso...</div> ) 
                    : 
                    <React.Fragment>
                      {/* PULSANTI */}
                      { user && user.userConfirmed && 
                        <div key="buttons" className="col-md-9">
                          
                            <div className="bnt user-card-btn1" onClick={()=>{ this.showNewDocumentModal( ) }}>
                              <strong>NUOVO DOCUMENTO</strong>
                              <FontAwesomeIcon  className="mainIconsRevNoAction"  size="2x" icon={solidIcons.faCirclePlus} />
                            </div>
                          
                        </div>
                      }
                      { /* LISTA DOCUMENTI */ 
                      documents.map((doc, index) => (
                          <div key={'documents-'+doc.id} className={'col-md-9' + (index > 0 ? ' col-md-push-3' : '')} >
                            { downloading === doc.id && 
                              <div className="card-documents col-md-12">
                                <div className="row" style={{padding: "2em", height: "15em"}}>
                                  <Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />
                                </div>                    
                              </div>
                            }
                            { downloading !== doc.id && 
                              <div className="card-documents col-md-12">

                                <div className="row">
                                  <h5><strong>Documento N°&nbsp;</strong>{doc.id}</h5>
                                </div>

                                <div className="row">    
                                                                                            
                                      { !editing[doc.id] && 
                                        <h4 className="row">
                                          <span className="text-right"><strong>Nome:&nbsp;</strong></span>
                                          <span className="text-left" style={{ minWidth: "50%"}}>{doc.name}</span>
                                        </h4> 
                                      }
                                      { editing[doc.id] &&                                      
                                        <h4 className="row">
                                          <span className="text-right">
                                              <strong>Nome:&nbsp;</strong>
                                          </span>
                                          <span className="text-left">
                                            <input       
                                                style={{ minWidth: "50%"}}
                                                className="secondaryInput"                                           
                                                name="name"
                                                type="text"
                                                value={editedName}
                                                onChange={ (evt) => { this.onChangeName( evt.target.value)  } }
                                                required
                                              />
                                          </span>
                                        </h4>
                                      }
                                  
                                </div>

                                <div className="row">

                                  <div className="row">
                                    <h5><strong>Tipo:&nbsp;</strong>{doc.DocumentType.label}</h5>
                                  </div>

                                  <div className="row">
                                    <h5>
                                      <strong>Inserito:&nbsp;</strong>{moment(doc.createdAt).format('L')}<br/>
                                      <strong>Aggiornato:&nbsp;</strong>{moment(doc.updatedAt).format('L')}
                                    </h5>
                                  </div>
                                  
                                  { errorMsg && editing[doc.id] &&
                                    <div className="row">
                                      <h5><span className="error">{errorMsg}</span></h5>
                                    </div>  
                                  }

                                  <div className="row">      
                                    <span className="col-md-12">
                                      { !editing[doc.id] && 
                                        <a onClick={() => this.onDocumentDownload(doc)} className="btn mainBtn" >
                                          <FontAwesomeIcon size="1x" icon={solidIcons.faDownload} />&nbsp;&nbsp;Download
                                        </a>                                                                                                    
                                      }                                  
                                      { editing[doc.id] && 
                                        <a onClick={() => this.onDocumentEdit(doc)} className="btn alertBtn" >
                                          <FontAwesomeIcon size="1x" icon={solidIcons.faXmarkCircle} />&nbsp;&nbsp;Annulla
                                        </a>
                                      }
                                      { editing[doc.id] && 
                                        <a onClick={() => this.onDocumentSave(doc)} className="btn mainBtn" >
                                        <FontAwesomeIcon size="1x" icon={solidIcons.faCircleCheck} />&nbsp;&nbsp;Salva
                                        </a>
                                      }
                                      { !editing[doc.id] && 
                                        <a onClick={() => this.onDocumentEdit(doc)} className="btn mainBtn" >
                                          <FontAwesomeIcon size="1x" icon={solidIcons.faEdit} />&nbsp;&nbsp;Modifica
                                        </a>
                                      }
                                    </span>                                                                    
                                  </div>                                                              
                                </div>
                              </div>
                            }
                          </div>
                        ))                                        
                      }
                    </React.Fragment> 
                  }
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="information-container hidden-xs hidden-sm" style={{display:'flex', justifyContent: 'center'}}>
              <div style={{ width:'85%' }}>
                <div style={{marginTop: 15, marginBottom: 10, display:'flex', alignItems: 'center', justifyContent:'center'}}>
                  <span className="threesom-span">
                    <FontAwesomeIcon  size="2x" className="sectionIcons"   icon={solidIcons.faCaretLeft} />
                  </span>
                  <div>
                    <TextUser bold>DOCUMENTI</TextUser>
                    <TextUser>
                      Qui trovi la lista dei documenti che hai caricato utili per essere allegati alle richieste di permesso.
                    </TextUser>
                  </div>
                </div>
              </div>
            </div>

            {news != null && news.length > 0 && 
            <div className="information-container">
              <div style={{marginLeft: 10}}>
                <div style={{marginTop: "5em", marginBottom: 10}}>
                  <TextUser bold>COMUNICAZIONI</TextUser>
                  <div style={{ margin: 0 }}>
                    
                    <Carousel
                      autoPlay
                      interval={8000}
                      infiniteLoop
                      stopOnHover
                      emulateTouch
                      showThumbs={false}
                    >
                      { news.map(  n => 
                        <div key={"news-"+n} className={"boxLabel " + (getNewsStyle(n))}>
                          {n.thumbImage == null ? (
                          <div>
                            {n.startDate && moment(n.startDate).format('L')} - {n.title}<br/><br/>
                            { getHtmlMsgFromString(n.content)}<br/><br/><br/>
                          </div>
                          ):(
                            <img style={{width:'100%', height:'100%', objectFit:'contain'}} src={n.thumbImage}></img>
                          )}
                        </div>     
                      )} 
                    </Carousel>
                    
                  </div>
                </div>
              </div>
            </div>
            }

          </div>
        </div>
        
        <NewDocumentModal
          show={showNewDocumentModal}
          onClose={this.onCloseNewDocumentModal}
          onUploadDocument={this.onUploadDocument}                  
        />
        
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Documents)
