import React, { Component }         from 'react'
import { connect }                  from 'react-redux'
import moment                       from 'moment'
import SostaOnlineApiService        from '../services/SostaOnlineApiService'
import { reportActions }            from 'redux/modules/Report'
import { actions as authActions }   from 'redux/modules/Auth'
import { bindActionCreators }       from 'redux'
import { FontAwesomeIcon }          from '@fortawesome/react-fontawesome'
import  * as solidIcons             from '@fortawesome/free-solid-svg-icons'
import { Oval } from 'react-loader-spinner'
import { NavLink } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'


const fileDownload = require('js-file-download')

moment.locale('it')

/**
 * --------------------------------------------------------------
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  return {
    state: state.users,
    auth: state.auth,
    stateReport : state.report,
    downloading: false
  }
}

/**
 * --------------------------------------------------------------
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    authActions:   bindActionCreators(authActions,   dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}

class BackOfficeUserDocumentsContainer extends Component {

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      userId: props.match.params.id,
      documents: []
    }

    this.fetchData = this.fetchData.bind(this)
    
  }

  /**
   * --------------------------------------------------------------
   */
  async componentDidMount() {

    await this.fetchData()    
    .catch( error => {
      console.log("ERROR",error)
    })
    
  }

  /**
   * ----------------------------------------------------------------
   * 
   */
  async fetchData() {

    this.setState({loading: true})
    
    var user = await SostaOnlineApiService.fetchUser(this.state.userId)      
      .catch( error => {
        console.log("ERROR",error)        
      })
      
    if ( user != null  && user.data != null) {
      //console.log("USER",user.data.id)
      this.setState({
        user: user.data
      })
    }

    var documents = await SostaOnlineApiService.fetchDocuments(this.state.userId)
    .catch( error => {
      console.log("ERROR",error)        
    })
    
    if ( documents != null  && documents.data != null) {
      //console.log("DOCUMENTS",documents.data)
      this.setState({
        documents: documents.data
      })
    }

    
    this.setState({loading: false})
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  getSortedDocuments() {
    var documents = []

    if ( this.state.documents != null && this.state.documents.length > 0 ) {
      documents = this.state.documents.sort( (a , b ) => (a.id > b.id ) ? -1 : 1 )
    }


    return documents
  }

  /**
   * --------------------------------------------------------------
   * @param {*} document 
   */
  async downloadDocument(document) {
    var { user } = this.state
    var documentResponse = await SostaOnlineApiService.downloadDocument(document.id)
    fileDownload(documentResponse, user.id + '_' + document.name + '.' + document.mimeType.split('/')[1])
  }
  
  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render() {

    const {       
      user,
      loading
    } = this.state

    return (
      <div className="col-md-12">
      <div className="panel panel-default panel-border">
        <div className="panel-body">
          <div className="titolo-categoria">
            <h3 className="text-left sectionTitle">                    
              <FontAwesomeIcon size="1x" className="sectionIcons" icon={solidIcons.faFolderOpen} />
                &nbsp;&nbsp;DOCUMENTI UTENTE&nbsp;
                { ( user &&  user.id       ) &&  "("+user.id+") " }
                { ( user &&  user.firstName ) &&  user.firstName+" " }
                { ( user &&  user.lastName ) &&  user.lastName }
            </h3>
          </div>
          <div className="pull-left">
            {user && 
              <NavLink exact className="btn mainBtn" to={'/backoffice/users/' + user.id}>
                <FontAwesomeIcon size="1x" icon={solidIcons.faDeleteLeft} />&nbsp;&nbsp;
                TORNA AL PROFILO
              </NavLink>
            }
          </div>
        </div>
        { loading == true && 
        <div className="row" style={{  padding: 0,  margin: 0 }}>
          <div className={ "box col-xs-12" } style={{  marginLeft: "1.5em" }}>
            <div className="row"><h2>Caricamento in corso...</h2></div>
            <div className="row" style={{padding: "2em", height: "40em"}}>
              <Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />
            </div>
          </div>
        </div>
        }
        { loading == false &&
        <div className="row" style={{  padding: 0,  margin: 0 }}>
          <div className={ "box col-xs-12" } style={{ padding: 0, paddingRight: "1.5em" , paddingLeft: "1.5em",  margin: 0 , marginBottom: "0.5em" }}>
            
            <div className="row backofficeSettingsHeader" >
              <div className="col-sm-2 "><label>Id</label></div>
              <div className="col-sm-4 hidden-xs"><label>Nome</label></div>       
              <div className="col-sm-4 hidden-xs"><label>Tipo</label></div>       
              <div className="col-sm-2 hidden-xs"><label>Data</label></div>                               
            </div>
                      
            {this.getSortedDocuments().map( document  =>              
              <OverlayTrigger key={`doc-${document.id}`} placement="top" overlay={<Tooltip id={'tooltip-user-1-'+document.id}>Clicca scaricare il documento</Tooltip>}>                
                <div  className={ "backofficeSettingsRow row"} >
                  <div className="col-xs-12 row" onClick={ async (evt) => { await this.downloadDocument(document) }}>
                    <div className="col-sm-2"><label>{document.id}</label></div>                
                    <div className="col-sm-4"><label>{document.name}</label></div>
                    <div className="col-sm-4"><label>{document.DocumentType.name}</label></div>                
                    <div className="col-sm-2"><label>{ moment(document.createdAt).format('L')}</label></div>                    
                  </div>
                </div>
              </OverlayTrigger>         
            )}
          

            <div className="row backofficeSettingsFooter" >
                <div className="col-xs-12" ></div>            
            </div>
          </div>
        </div>
        }
      </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeUserDocumentsContainer)
