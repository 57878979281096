import React, { Component } from 'react'
import PropTypes from 'prop-types'
import "react-responsive-modal/styles.css";
import { reduxForm } from 'redux-form';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SostaOnlineApiService from 'services/SostaOnlineApiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as solidIcons from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import { Oval } from 'react-loader-spinner';
import RequestConfirmModal from 'components/General/RequestConfirmModal';
import { SketchPicker } from 'react-color';
import { MARK_TYPE_ELECTRIC, MARK_TYPE_FAMILY, MARK_TYPE_GREEN, MARK_TYPE_HYBRID, MARK_TYPE_STANDARD, PRICE_TYPE_PERSON_PER_HOUSING_UNIT } from 'libs/marks';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import { MARK_DURATION, MARK_EXPIRATION_DATE, MARK_PRINT_BY_USER, getSettingByName } from 'libs/settings';
import ReactDatePicker from 'react-datepicker';
import { DOCUMENT_TYPE_ALL, DOCUMENT_TYPE_DRIVER, DOCUMENT_TYPE_GENERIC, DOCUMENT_TYPE_IDCARD, DOCUMENT_TYPE_VEHICLE } from 'libs/documents';
import { ADMIN } from 'libs/roles';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reportActions } from 'redux/modules/Report'
import { actions as authActions } from 'redux/modules/Auth'
import BackofficeEditTimeSlotModal from './BackofficeEditTimeSlotModal';
import { getNullTimeSlot } from './BackofficeEditTimeSlot';
import { parsePrice } from 'libs/utils';


moment.locale('it')

/**
 * --------------------------------------------------------------------------
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  return {
    backofficeValues: state.form.backofficeSettings,
    auth: state.auth,
    stateReport: state.report
  }
}

/**
 * --------------------------------------------------------------------------
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    authActions: bindActionCreators(authActions, dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}

class BackofficeEditMarkType extends Component {

  static propTypes = {
    markTypeId: PropTypes.number,
    onSaveMarkType: PropTypes.func.isRequired,
    onDeleteMarkType: PropTypes.func.isRequired,
    onCancelEditMarkType: PropTypes.func.isRequired,
  }


  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    var userLang = "it";
    const getLocale = locale => require(`date-fns/locale/${userLang}/index.js`)

    this.state = {
      markType: this.getNullMarkType(),
      categories: [],
      areas: [],
      documentTypes: [],
      sendMsg: null,
      checkErrors: null,
      sendErrors: null,
      showConfirmDeleteMarkType: false,
      showEditTimeSlot: false,
      selectedTimeSlot: null,
      displayColorPicker: false,
      expiration: this.getNullExpiration(),
      selectedDocType: DOCUMENT_TYPE_ALL,
      selectedDocumentType: null,
      locale: getLocale(userLang),
      settings: null,
      printOption: null
    }

    this.saveMarkType = this.saveMarkType.bind(this)
    this.checkFields = this.checkFields.bind(this)
    this.cancelEditMarkType = this.cancelEditMarkType.bind(this)
    this.deleteMarkType = this.deleteMarkType.bind(this)
    this.setMarkTypeColor = this.setMarkTypeColor.bind(this)
    this.getDropdownTreeSelectAreasSourceData = this.getDropdownTreeSelectAreasSourceData.bind(this)
    this.onAreasChange = this.onAreasChange.bind(this)
    this.getDropdownTreeSelectCategoriesSourceData = this.getDropdownTreeSelectCategoriesSourceData.bind(this)
    this.onCategoriesChange = this.onCategoriesChange.bind(this)
    this.setMarkDuration = this.setMarkDuration.bind(this)
    this.setMarkExpiration = this.setMarkExpiration.bind(this)
    this.onCancelEditTimeSlot = this.onCancelEditTimeSlot.bind(this)
    this.onDeleteTimeSlot = this.onDeleteTimeSlot.bind(this)
    this.onSaveTimeSlot = this.onSaveTimeSlot.bind(this)

  }

  /**
   * --------------------------------------------------------------
   */
  getNullMarkType() {

    var nullMarkType = {
      id: null,
      name: "",
      label: "",
      virtualizable: true,
      renewable: true,
      price: 0,
      SubTotals: [],
      Prices: [],
      color: '#ffffff',
      type: MARK_TYPE_STANDARD,
      Categories: [],
      Areas: [],
      MarkRules: [{ marksLimit: 1, vehicleLimit: 1 }],
      DocumentTypes: [],
      TimeSlots: [],
      operatorCheck: true,
      pagopaSpont: false,
      resalable: false,
      printableByUser: false      
    }

    return nullMarkType

  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  getNullExpiration() {
    return { type: "DAYS", date: moment(), days: 30 }
  }


  /**
   * --------------------------------------------------------------
   * 
   */
  stateInit() {
    this.setState({
      markType: this.getNullMarkType(),
      categories: [],
      areas: [],
      documentTypes: [],
      sendMsg: null,
      checkErrors: null,
      sendErrors: null,
      showConfirmDeleteMarkType: false,
      showEditTimeSlot: false,
      selectedTimeSlot: null,
      displayColorPicker: false,
      expiration: this.getNullExpiration(),
      selectedDocType: DOCUMENT_TYPE_ALL,
      selectedDocumentType: null,
      printOption: this.getPrintByUserOption(this.state.settings)
    })
  }
  getPrintByUserOption(settings) {
    const stampaPermessiUtenteOpzioneConsentita = getSettingByName(settings, MARK_PRINT_BY_USER)
    console.log("stampaPermessiUtenteOpzioneConsentita", stampaPermessiUtenteOpzioneConsentita)
    if (stampaPermessiUtenteOpzioneConsentita !== null && typeof stampaPermessiUtenteOpzioneConsentita === 'object') {
      return stampaPermessiUtenteOpzioneConsentita.value
      //return (stampaPermessiUtenteOpzioneConsentita.value == 1 || stampaPermessiUtenteOpzioneConsentita.value == true || stampaPermessiUtenteOpzioneConsentita.value == "1")
    } else {
      return false
    }
  }

  /**
   * --------------------------------------------------------------
   */
  componentDidMount() {
    this.stateInit()
    this.fetchData()
  }

  /**
   * --------------------------------------------------------------
   * @param {*} prevProps 
   */
  componentDidUpdate(prevProps) {
  
    if (prevProps.markTypeId != this.props.markTypeId) {
      this.stateInit()
      this.fetchData()
    }

  }

  /**
   * --------------------------------------------------------------
   * 
   */
  async fetchData() {

    var areas = await SostaOnlineApiService.fetchAreas()
      .catch(error => {
        this.setState({ sendErrors: error.error })
      })

    if (areas != null) {
      this.setState({ areas: areas.data })
    }

    var categories = await SostaOnlineApiService.fetchFlatCategories()
      .catch(error => {
        this.setState({ sendErrors: error.error })
      })

    if (categories != null) {
      this.setState({ categories: categories.data })
    }

    var documentTypes = await SostaOnlineApiService.fetchDocumentTypes()
      .catch(error => {
        this.setState({ sendErrors: error.error })
      })

    if (documentTypes != null) {
      this.setState({ documentTypes: documentTypes.data })
    }

    if (this.props.markTypeId != null) {

      var markType = await SostaOnlineApiService.fetchMarkType(this.props.markTypeId)
        .catch(error => {
          this.setState({ sendErrors: error.error })
        })

      if (markType != null && markType.data != null) {

        if (markType.data.color == null)
          markType.data.color = "#ffffff"

        if (markType.data.MarkRules == null || markType.data.MarkRules.length <= 0)
          markType.data.MarkRules = [{ marksLimit: 1, vehicleLimit: 1 }]

        if (markType.data.SubTotals == null) {
          markType.data.SubTotals = []
        }

        if (markType.data.Prices == null) {
          markType.data.Prices = []
        }

        this.setState({ markType: markType.data })

        await SostaOnlineApiService.fetchSettings()
          .then(settings => {

            var key = MARK_DURATION + markType.data.id
            const resultDuration = settings.data.filter(element => element.name == key)

            key = MARK_EXPIRATION_DATE + markType.data.id
            const resultExpiration = settings.data.filter(element => element.name == key)

            if (resultDuration.length > 0 && resultDuration[0]) {
              this.setState({ expiration: { type: "DAYS", days: parseInt(resultDuration[0].value), date: moment() } })
            }
            else if (resultExpiration.length > 0 && resultExpiration[0]) {
              this.setState({ expiration: { type: "DATE", days: 30, date: moment(resultExpiration[0].value) } })
            }
            else {
              this.setState({ expiration: this.getNullExpiration() })
            }

            this.setState({ settings: settings.data })

          })

      }
      else {
        this.setState({ markType: this.getNullMarkType(), expiration: this.getNullExpiration() })
      }
    }
    else {
      this.setState({ markType: this.getNullMarkType(), expiration: this.getNullExpiration() })
    }

  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  checkFields() {

    var errors = []
    var markType = this.state.markType

    markType.name = markType.name.trim()
    markType.label = markType.label.trim()
    markType.price = parseFloat(markType.price).toFixed(2)

    if (markType.name == null || markType.name.length <= 0) {
      errors.push("Nome non specificato")
    }

    else if (markType.label == null || markType.label.length <= 0) {
      errors.push("Label non specificata")
    }

    else if (markType.price == null) {
      errors.push("Prezzo non specificato")
    }

    else if (markType.type == null) {
      errors.push("Tipo non specificato")
    }

    else if (markType.Areas == null || markType.Areas.length <= 0) {
      errors.push("Area non specificata")
    }

    else if (markType.Categories == null || markType.Categories.length <= 0) {
      errors.push("Categoria non specificata")
    }

    else if (markType.SubTotals.length > 0) {

      for (let key in markType.SubTotals) {
        var subTotal = markType.SubTotals[key]

        if (subTotal.value == null || subTotal.value <= 0) {
          errors.push("Tutti i sub totali devono avere un valore maggiore di zero")
          break
        }
        else if (subTotal.description == null || subTotal.description.length <= 0) {
          errors.push("Tutti i sub totali devono avere una descrizione")
          break
        }

      }

    }

    this.setState({ markType: markType })

    return errors;

  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  saveMarkType() {

    var errors = this.checkFields()
    this.setState({ checkErrors: errors })

    if (errors != null && errors.length > 0) {
      return
    }

    var markType = this.state.markType
    markType.expiration = this.state.expiration

    if (markType.id == null) {       
      
      console.log('XXXX: ', markType);
      
      if (markType.DocumentTypes[0] && markType.DocumentTypes[0].fixMandatory && markType.DocumentTypes[0].label) {
        markType.DocumentTypes[0].MarkTypesDocuments.mandatory = true
        markType.pagopaSpont = markType.DocumentTypes[0].docTypePagoPaSpont == true ? true : false
        markType.renewable = markType.DocumentTypes[0].docTypePagoPaSpont == true ? false : true     
      }      

      SostaOnlineApiService.createMarkType(markType)
        .then(result => {

          var markTypeId = null

          if (result.data != null && result.data.id != null)
            markTypeId = result.data.id

          if (this.props.onSaveMarkType != null) {
            this.props.onSaveMarkType(markTypeId)
          }          

          this.setState({ sendMsg: "Permesso creato con successo" })
        })
        .catch(error => {
          console.log("ERROR", error)
          this.setState({ sendErrors: "Errore durante Il salvataggio, riprovare più tardi!" })
        })

    }
    else {
      console.log('markType.id: ' , markType);
      
      if (markType.DocumentTypes[0] && markType.DocumentTypes[0].fixMandatory) {        
        markType.pagopaSpont = true
        // markType.renewable = false
        this.setMarkTypeDocumentMandatory(markType.DocumentTypes[0].id, true)
      }
      
      SostaOnlineApiService.updateMarkType(markType)
        .then(result => {

          if (this.props.onSaveMarkType != null) {
            this.props.onSaveMarkType(this.props.markTypeId)
          }

          this.setState({ sendMsg: "Permesso salvato con successo" })

        })
        .catch(error => {
          console.log("ERROR", error)
          this.setState({ sendErrors: "Errore durante Il salvataggio, riprovare più tardi!" })
        })

    }

  }

  /**
   * --------------------------------------------------------------
   * 
   */
  deleteMarkType() {

    if (this.props.markTypeId != null) {

      SostaOnlineApiService.deleteMarkType(this.props.markTypeId)
        .then(result => {

          if (this.props.onDeleteMarkType != null) {
            this.props.onDeleteMarkType()
          }

        })
        .catch(error => {
          console.log("ERROR", error)

          if (error.error != null) {
            this.setState({ sendErrors: error.error })
          }
          else {
            this.setState({ sendErrors: "Errore durante Il salvataggio, riprovare più tardi!" })
          }

        })

    }

    this.setState({ showConfirmDeleteMarkType: false })

  }

  /**
   * --------------------------------------------------------------
   * @param {*} name 
   */
  setMarkTypeName(name) {
    var markType = this.state.markType
    markType.name = name
    this.setState({ markType: markType })
  }


  /**
   * --------------------------------------------------------------
   * @param {*} label 
   */
  setMarkTypeLabel(label) {
    var markType = this.state.markType
    markType.label = label
    this.setState({ markType: markType })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} price 
   */
  setMarkTypePrice(price) {
    var markType = this.state.markType
    markType.price = parsePrice(price)
    this.setState({ markType: markType })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} virtualizable 
   */
  setMarkTypeVirtualizable(virtualizable) {
    var markType = this.state.markType
    markType.virtualizable = virtualizable
    /*
    if ( virtualizable ) {
      markType.color = "#ffffff"
    }
    */
    this.setState({ markType: markType })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} renewable 
   */
  setMarkTypeRenewable(renewable) {
    var markType = this.state.markType
    markType.renewable = renewable
    this.setState({ markType: markType })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} operatorCheck 
   */
  setOperatorCheck(operatorCheck) {
    var markType = this.state.markType
    markType.operatorCheck = operatorCheck
    this.setState({ markType: markType })
  }

 /**
 * --------------------------------------------------------------
 * @param {*} resalable 
 */
  setResalableCheck(resalable) {
    var markType = this.state.markType
    markType.resalable = resalable
    this.setState({ markType: markType })
  }

  /**
 * --------------------------------------------------------------
 * @param {*} pagopaSpont 
 */
  setPagoPASpont(pagopaSpont) {
    var markType = this.state.markType
    markType.pagopaSpont = pagopaSpont
    this.setState({ markType: markType })
  }

  /**
 * --------------------------------------------------------------
 * @param {*} printableByUser 
 */
  setPrintableCheck(choosedOption) {
    var markType = this.state.markType
    markType.printableByUser = choosedOption
    this.setState({ markType: markType })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} limit 
   */
  setMarksLimit(limit) {
    var markType = this.state.markType
    markType.MarkRules[0].marksLimit = limit
    this.setState({ markType: markType })
  }


  /**
  * --------------------------------------------------------------
  * @param {*} virtualizable 
  */
  setVehicleLimit(limit) {
    var markType = this.state.markType
    markType.MarkRules[0].vehicleLimit = limit
    this.setState({ markType: markType })
  }

  /**
  * --------------------------------------------------------------
  * @param {*} color 
  */
  setMarkTypeColor(color) {
    if (color != null && color.hex != null) {
      var markType = this.state.markType
      markType.color = color.hex
      this.setState({ markType: markType })
    }
  }

  /**
   * --------------------------------------------------------------
   * @param {*} markType 
   */
  setMarkType(type) {
    var markType = this.state.markType
    var expiration = this.state.expiration
    markType.type = type
    if (type == MARK_TYPE_FAMILY) {
      expiration.type = "DAYS"
    }
    this.setState({ markType: markType, expiration: expiration })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} markType 
   */
  setMarkDuration(days) {
    var expiration = this.state.expiration
    expiration.type = "DAYS"
    expiration.days = days
    expiration.date = moment()
    this.setState({ expiration: expiration })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} markType 
   */
  setMarkExpiration(date) {

    if (date == null) {
      date = moment()
    }
    else {
      date = moment(date)
    }

    var expiration = this.state.expiration
    expiration.type = "DATE"
    expiration.days = 30
    expiration.date = date
    this.setState({ expiration: expiration })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} type 
   */
  setMarkTypeExpirationType(type) {
    var expiration = this.state.expiration
    expiration.type = this.state.markType.type == MARK_TYPE_FAMILY ? "DAYS" : type
    this.setState({ expiration: expiration })
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  showColorPicker() {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  cancelEditMarkType() {

    this.stateInit()
    this.fetchData()

    if (this.props.onCancelEditMarkType != null)
      this.props.onCancelEditMarkType()
  }


  /**
   * --------------------------------------------------------------
   * @returns 
   */
  getDropdownTreeSelectAreasSourceData() {
    var data = []

    var markType = this.state.markType

    for (let key in this.state.areas) {
      var area = this.state.areas[key]
      var checked = (markType.Areas.find(a => a.id == area.id) != null)

      data.push({
        id: area.id,
        label: area.code,
        checked: checked
      })
    }

    return data
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  getDropdownTreeSelectCategoriesSourceData() {
    var data = []

    var markType = this.state.markType

    if (this.state.categories != null && this.state.categories.length > 0) {

      var sortedCategories = this.state.categories.sort((a, b) => (a.label > b.label) ? 1 : -1).filter(c => c.ParentId == null)

      for (let key in sortedCategories) {
        var category = sortedCategories[key]
        var childs = this.getDropdownTreeSelectCategoriesSourceDataChilds(category.id)
        var disabled = childs.length > 0
        var checked = (markType.Categories.find(c => c.id == category.id) != null && !disabled)
        var expanded = childs.length > 0 && childs.filter(c => c.checked == true).length > 0

        data.push({
          id: category.id,
          label: category.label,
          checked: checked,
          disabled: disabled,
          children: childs,
          expanded: expanded
        })
      }
    }

    return data
  }

  /**
   * --------------------------------------------------------------
   * @param {*} parentId 
   * @returns 
   */
  getDropdownTreeSelectCategoriesSourceDataChilds(parentId) {
    var childs = []
    var categories = this.state.categories
    var markType = this.state.markType

    if (parentId != null && categories != null) {
      categories = categories.filter(c => c.ParentId == parentId)

      for (let key in categories) {

        var category = categories[key]
        var checked = (markType.Categories.find(c => c.id == category.id) != null)

        childs.push({
          id: category.id,
          label: category.label,
          checked: checked,
          disabled: false
        })
      }
    }

    return childs

  }


  /**
   * --------------------------------------------------------------
   * @param {*} currentNode 
   * @param {*} selectedNodes 
   */
  onAreasChange(currentNode, selectedNodes) {

    var markType = this.state.markType
    var areas = this.state.areas
    const activeAreas = selectedNodes.filter(item => item.checked == true)
      .map(area => {
        return areas.find(a => a.id == area.id)
      })

    markType.Areas = activeAreas
    this.setState({ markType: markType })

  }


  /**
  * --------------------------------------------------------------
  * @param {*} currentNode 
  * @param {*} selectedNodes 
  */
  onCategoriesChange(currentNode, selectedNodes) {

    var markType = this.state.markType
    var categories = this.state.categories
    const activeCategories = selectedNodes
      .filter(item => item.checked == true)
      .map(category => {
        var cat = categories.find(c => c.id == category.id)
        var markTypeCatData = markType.Categories.find(c => c.id == category.id)

        if (markTypeCatData != null)
          cat = markTypeCatData
        else
          cat.MarkTypeCategories = { periodo: "", sosta: "", circolazione: "" }

        return cat
      })

    if (!this.isResidentCategorySelected(activeCategories)) {
      var prices = markType.Prices.filter((item, index) => item.type != PRICE_TYPE_PERSON_PER_HOUSING_UNIT)
      markType.Prices = prices
    }

    markType.Categories = activeCategories
    this.setState({ markType: markType })

  }

  /**
   * --------------------------------------------------------------
   * @param {*} categoryId 
   * @param {*} circolazione 
   */
  setMarkTypeCircolazione(categoryId, circolazione) {

    var markType = this.state.markType
    var categories = markType.Categories.map(c => {
      if (c.id == categoryId) {
        c.MarkTypeCategories.circolazione = circolazione
      }
      return c
    })

    markType.Categories = categories
    this.setState({ markType: markType })

  }

  /**
  * --------------------------------------------------------------
  * @param {*} categoryId 
  * @param {*} periodo 
  */
  setMarkTypePeriodo(categoryId, periodo) {

    var markType = this.state.markType
    var categories = markType.Categories.map(c => {
      if (c.id == categoryId) {
        c.MarkTypeCategories.periodo = periodo
      }
      return c
    })

    markType.Categories = categories
    this.setState({ markType: markType })

  }

  /**
  * --------------------------------------------------------------
  * @param {*} categoryId 
  * @param {*} sosta 
  */
  setMarkTypeSosta(categoryId, sosta) {

    var markType = this.state.markType
    var categories = markType.Categories.map(c => {
      if (c.id == categoryId) {
        c.MarkTypeCategories.sosta = sosta
      }
      return c
    })

    markType.Categories = categories
    this.setState({ markType: markType })

  }

  /**
   * --------------------------------------------------------------
   * @param {*} docType 
   * @returns 
   */
  getDocumentTypes(docType) {
    var documentTypes = this.state.documentTypes

    if (docType != DOCUMENT_TYPE_ALL) {
      documentTypes = documentTypes.filter(dt => docType == dt.docType)
    }

    return documentTypes
  }

  /**
   * --------------------------------------------------------------
   * @param {*} documentTypeId 
   */
  addDocumentType(documentTypeId) {
    var {
      markType,
      documentTypes
    } = this.state

    var alreadyPresent = markType.DocumentTypes.find(dt => documentTypeId == dt.id)

    // aggiungo il documento solo se non è già presente, non vogliamo doppioni
    if (alreadyPresent == null && documentTypeId != null) {
      var docToAdd = documentTypes.find(d => d.id == documentTypeId)

      if (docToAdd != null) {
        docToAdd.MarkTypesDocuments = { mandatory: false }
        markType.DocumentTypes.push(docToAdd)
        this.setState({ markType: markType })
      }

    }

  }

  /**
   * --------------------------------------------------------------
   * @param {*} documentTypeId 
   */
  removeDocumentType(documentTypeId) {
    var {
      markType,
    } = this.state

    var documentTypes = markType.DocumentTypes.filter(d => d.id != documentTypeId)
    markType.DocumentTypes = documentTypes
    this.setState({ markType: markType })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} documentTypeId 
   * @param {*} checked 
   */

  setMarkTypeDocumentMandatory(documentTypeId, checked) {    

    var {
      markType
    } = this.state

    var documentTypes = markType.DocumentTypes.map(dt => {      
      if (dt.id == documentTypeId){               
        dt.MarkTypesDocuments.mandatory = checked        
      }
      return dt    
    })

    if (documentTypes != null) {      
      markType.DocumentTypes = documentTypes
      this.setState({ markType: markType })      

      if (documentTypes.fixMandatory) {
        markType.pagopaSpont = true
      }     
    }

  }

  /**
   * --------------------------------------------------------------
   */
  editAreaTiming(categoryId) {

    var searchSelectedTimeSlots = null
    var selectedTimeSlot = null

    if (this.state.markType != null && this.state.markType.TimeSlots != null) {
      searchSelectedTimeSlots = this.state.markType.TimeSlots.filter(timeSlot => timeSlot.categoryId == categoryId)
    }

    if (searchSelectedTimeSlots != null && searchSelectedTimeSlots.length > 0) {
      selectedTimeSlot = searchSelectedTimeSlots[0]
    }
    else {
      selectedTimeSlot = getNullTimeSlot()
      selectedTimeSlot.categoryId = categoryId
      selectedTimeSlot.markTypeId = this.state.markType.id
    }

    this.setState({
      selectedTimeSlot: selectedTimeSlot,
      showEditTimeSlot: true
    })

  }

  /**
   * --------------------------------------------------------------
   * @param {*} enable 
   */
  addSubTotal() {

    var subTotals = this.state.markType.SubTotals
    var markType = this.state.markType

    if (!subTotals) {
      subTotals = []
    }

    subTotals.push({
      id: null,
      value: 0,
      description: "",
    })

    markType.SubTotals = subTotals
    this.setState({ markType: markType })

  }

  /**
  * --------------------------------------------------------------
  * @param {String} type 
  * @param {Integer} index
  */
  addPrice(type, index) {

    var prices = this.state.markType.Prices
    var markType = this.state.markType

    if (!prices) {
      prices = []
    }

    prices.push({
      id: null,
      value: 0,
      index: index,
      type: type
    })

    markType.Prices = prices
    this.setState({ markType: markType })

  }

  /**
   * --------------------------------------------------------------
   * @param {*} index 
   */
  removeSubTotal(index) {

    var subTotals = this.state.markType.SubTotals
    var markType = this.state.markType
    var filteredSubTotals = subTotals.filter((item, i) => i != index)
    markType.SubTotals = filteredSubTotals

    this.setState({ markType: markType })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} index 
   */
  removePrice(index) {

    var prices = this.state.markType.Prices
    var markType = this.state.markType
    var filteredPrices = prices.filter((item, i) => i != index)
    filteredPrices = filteredPrices.map((item, index) => {

      if (item.type == PRICE_TYPE_PERSON_PER_HOUSING_UNIT) {
        item.index = index + 2
      }
      else {
        item.index = index
      }

      return item

    })
    markType.Prices = filteredPrices

    this.setState({ markType: markType })
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  getPriceSubtotal() {
    var priceSubtotal = this.state.markType.price
    var subTotals = this.state.markType.SubTotals

    for (let key in subTotals) {
      priceSubtotal -= subTotals[key].value
    }

    return priceSubtotal
  }

  /**
   * --------------------------------------------------------------
   * @param {*} index 
   * @param {*} value 
   */
  setSubtotalValue(index, value) {

    var parsedValue = parsePrice(value)

    var priceSubtotal = this.state.markType.price
    var subTotals = this.state.markType.SubTotals
    var markType = this.state.markType
    var subTotal = subTotals[index]

    for (let key in subTotals) {
      priceSubtotal -= subTotals[key].value
    }

    if (parsedValue >= priceSubtotal) {
      parsedValue = 0
    }

    subTotal.value = parsedValue
    subTotals[index] = subTotal
    markType.SubTotals = subTotals

    this.setState({ markType: markType })
  }

  /**
  * --------------------------------------------------------------
  * @param {*} index 
  * @param {*} value 
  */
  setPriceValue(index, value) {

    var prices = this.state.markType.Prices
    var markType = this.state.markType
    var price = prices[index]

    price.value = parsePrice(value)
    prices[index] = price
    markType.Prices = prices

    this.setState({ markType: markType })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} index 
   * @param {*} description 
   */
  setSubtotalDescription(index, description) {
    var subTotals = this.state.markType.SubTotals
    var markType = this.state.markType
    var subTotal = subTotals[index]
    subTotal.description = description
    subTotals[index] = subTotal
    markType.SubTotals = subTotals

    this.setState({ markType: markType })
  }

  /**
   * --------------------------------------------------------------
   */
  onSaveTimeSlot() {

  }

  /**
   * --------------------------------------------------------------
   */
  onDeleteTimeSlot() {

  }

  /**
   * --------------------------------------------------------------
   */
  onCancelEditTimeSlot() {

    this.setState({
      selectedTimeSlot: null,
      showEditTimeSlot: false
    })

  }

  /**
   * --------------------------------------------------------------
   */
  isResidentCategorySelected(selectedCategories) {

    //var selectedCategories = this.state.markType.Categories
    var selected = false

    for (let key in selectedCategories) {
      if (selectedCategories[key].resident) {
        selected = true
        break
      }
    }

    return selected
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render() {

    const {
      markTypeId
    } = this.props

    const {
      sendErrors,
      checkErrors,
      markType,
      showConfirmDeleteMarkType,
      showEditTimeSlot,
      selectedTimeSlot,
      sendMsg,
      displayColorPicker,
      expiration,
      selectedDocType,
      locale,
      settings,
      printOption
    } = this.state

    if (this.props.auth.user.role != ADMIN) {
      return (<div className="text-primary">Utente non autorizzato</div>)
    }

    console.log('markType_2: ' , markType);
    

    return (
      <React.Fragment>

        {markType == null &&
          <div className="col-md-12">
            <div className="row">
              <div className="text-center" style={{ margin: "1em" }}>
                <div className="row"><h2>Caricamento in corso...</h2></div>
                <div className="row" style={{ padding: "2em", height: "40em" }}>
                  <Oval secondaryColor="#02afff" color="#174c88" height={40} width="100%" />
                </div>
              </div>
            </div>
          </div>
        }

        {markType != null &&
          <div className="col-xs-12" style={{ padding: "0em", paddingBottom: "2em", paddingLeft: "2em", paddingRight: "2em" }}>

            { /* SEZIONE DATI GENERALI */}
            {markType.id != null &&
              <div className="row" style={{ marginBottom: "1em" }}>
                <div className="col-xs-3">
                  <h5 style={{ marginTop: 5, marginBottom: 5 }}><strong>Id:</strong></h5>
                </div>

                <div className="col-xs-9">
                  <h5 style={{ marginTop: 5, marginBottom: 5 }}><strong>{markType.id}</strong></h5>
                </div>
              </div>
            }

            <div className="row" style={{ marginBottom: "1em" }}>
              <div className="col-xs-3">
                <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-name"}>
                  Nome del permesso, visualizzato solo nella sezione settings per itentificare il tipo permesso
                </Tooltip>}>
                  <h5 style={{ marginTop: 5, marginBottom: 5 }}><strong>Nome:</strong>&nbsp;<span className="glyphicon glyphicon-info-sign">{ }</span></h5>
                </OverlayTrigger>
              </div>

              <div className="col-xs-9" >
                <input
                  name="name"
                  className="form-control"
                  type="text"
                  onChange={e => { this.setMarkTypeName(e.target.value) }}
                  value={markType.name}
                  required />
              </div>
            </div>

            <div className="row" style={{ marginBottom: "1em" }}>
              <div className="col-xs-3">
                <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-label"}>
                  Label del permesso, visualizzata in tutte le sezioni del sito per identificare il tipo permesso
                </Tooltip>}>
                  <h5 style={{ marginTop: 10, marginBottom: 5, verticalAlign: "top" }}><strong>Label:</strong>&nbsp;<span className="glyphicon glyphicon-info-sign">{ }</span></h5>
                </OverlayTrigger>
              </div>

              <div className="col-xs-9" >
                <input
                  name="label"
                  className="form-control"
                  type="text"
                  onChange={e => { this.setMarkTypeLabel(e.target.value) }}
                  value={markType.label}
                  required />
              </div>
            </div>

            <div className="row" style={{ marginBottom: "1em" }}>
              <div className="col-xs-3">
                <h5 style={{ marginTop: 10, marginBottom: 5, verticalAlign: "top" }}><strong>Tipo:</strong></h5>
              </div>

              <div className="col-xs-9" >
                <select
                  name="parentId"
                  className="form-control"
                  onChange={e => { this.setMarkType(e.target.value) }}
                  value={markType.type == null || markType.type.length <= 0 ? MARK_TYPE_STANDARD : markType.type}
                  required >
                  <option value={MARK_TYPE_STANDARD}>Standard</option>
                  <option value={MARK_TYPE_HYBRID}  >Auto Ibrida</option>
                  <option value={MARK_TYPE_ELECTRIC}>Auto Elettrica</option>
                  <option value={MARK_TYPE_GREEN}   >Auto Ibrida o Elettrica</option>
                  <option value={MARK_TYPE_FAMILY}  >Family</option>
                </select>
              </div>
            </div>

            <div className="row" style={{ marginBottom: "1em" }}>
              <div className="col-xs-3">
                <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-areas"}>
                  Zone cittadine in cui il permesso è attivo
                </Tooltip>}>
                  <h5 style={{ marginTop: 10, marginBottom: 5, verticalAlign: "top" }}><strong>Zone: </strong>&nbsp;<span className="glyphicon glyphicon-info-sign">{ }</span></h5>
                </OverlayTrigger>
              </div>

              <div className="col-xs-9" >
                <DropdownTreeSelect
                  id="areas"
                  required={true}
                  disabled={false}
                  data={this.getDropdownTreeSelectAreasSourceData()}
                  onAction={({ action, node }) => { /*console.log(`onAction:: [${action}]`, node)*/ }}
                  onNodeToggle={(currentNode) => { /*console.log('onNodeToggle::', currentNode)*/ }}
                  onChange={this.onAreasChange}
                  texts={{ placeholder: "Seleziona aree" }}
                />
              </div>
            </div>

            { /* SEZIONE CATEGORIE */}
            {markType.Categories != null && markType.Categories.length > 0 &&
              <div className="noteRowNoMargin" style={{ marginBottom: "1em", minHeight: 0 }}></div>
            }

            <div className="row" style={{ marginBottom: "1em" }}>
              <div className="col-xs-3">
                <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-category"}>
                  Categorie utente per cui il permesso è richiedibile
                </Tooltip>}>
                  <h5 style={{ marginTop: 10, marginBottom: 5, verticalAlign: "top" }}><strong>Categorie: </strong>&nbsp;<span className="glyphicon glyphicon-info-sign">{ }</span></h5>
                </OverlayTrigger>
              </div>

              <div className="col-xs-9" >
                <DropdownTreeSelect
                  id="categories"
                  required={true}
                  disabled={false}
                  data={this.getDropdownTreeSelectCategoriesSourceData()}
                  onAction={({ action, node }) => { /*console.log(`onAction:: [${action}]`, node)*/ }}
                  onNodeToggle={(currentNode) => { /*console.log('onNodeToggle::', currentNode)*/ }}
                  onChange={this.onCategoriesChange}
                  texts={{ placeholder: "Seleziona categorie" }}
                />
              </div>
            </div>

            {markType.Categories != null && markType.Categories.length > 0 &&
              <div className="row" style={{ marginBottom: 0 }}>
                <div className="col-xs-3"></div>
                <div className="col-xs-9 row" style={{ marginRight: 0, paddingRight: 0 }}>
                  <div className="col-xs-3" style={{ marginRight: 0, paddingRight: 0 }}>
                    <label>Circolazione</label>
                  </div>
                  <div className="col-xs-3" style={{ marginRight: 0, paddingRight: 0 }}>
                    <label>Periodo</label>
                  </div>
                  <div className="col-xs-4" style={{ marginRight: 0, paddingRight: 0 }}>
                    <label>Sosta</label>
                  </div>
                  <div className="col-xs-2" style={{ marginTop: 0, marginRight: 0, paddingRight: 0 }}></div>
                </div>
              </div>
            }
            {markType.Categories.map(category =>
              <div className="row" key={"category-" + category.id} style={{ marginBottom: "1em" }}>
                <div className="col-xs-3">
                  <h5 style={{ paddingTop: "1em" }}><strong>{category.label} </strong></h5>
                </div>

                <div className="col-xs-9 row" style={{ marginRight: 0, paddingRight: 0 }}>
                  <div className="col-xs-3" style={{ marginRight: 0, paddingRight: 0, paddingTop: "0.5em" }}>
                    <input
                      name={"circolazione-" + category.id}
                      className="form-control"
                      type="text"
                      onChange={e => { this.setMarkTypeCircolazione(category.id, e.target.value) }}
                      value={category.MarkTypeCategories.circolazione}
                    />
                  </div>

                  <div className="col-xs-3" style={{ marginRight: 0, paddingRight: 0, paddingTop: "0.5em" }}>
                    <input
                      name={"periodo-" + category.id}
                      className="form-control"
                      type="text"
                      onChange={e => { this.setMarkTypePeriodo(category.id, e.target.value) }}
                      value={category.MarkTypeCategories.periodo}
                    />
                  </div>

                  <div className="col-xs-4" style={{ marginRight: 0, paddingRight: 0, paddingTop: "0.5em" }}>
                    <input
                      name={"sosta-" + category.id}
                      className="form-control"
                      type="text"
                      onChange={e => { this.setMarkTypeSosta(category.id, e.target.value) }}
                      value={category.MarkTypeCategories.sosta}
                    />
                  </div>

                  <div className="col-xs-2" style={{ margin: 0 }}>
                    <Button className="btn mainBtn" onClick={() => { this.editAreaTiming(category.id) }} >
                      <FontAwesomeIcon size="1x" icon={solidIcons.faClock} />
                    </Button>
                  </div>
                </div>
              </div>
            )}

            { /* SEZIONE PREZZO, DURATA E DATI SECONDARI */}
            {((markType.Categories != null && markType.Categories.length > 0) || markType.SubTotals.length > 0 || markType.Prices.length > 0) &&
              <div className="noteRowNoMargin" style={{ marginBottom: "1em", minHeight: 0 }}></div>
            }

            <div className="row" style={{ marginBottom: "1em" }}>
              <div className="col-xs-3">
                <h5 style={{ marginTop: 10, marginBottom: 5, verticalAlign: "top" }}><strong>Prezzo € : </strong></h5>
              </div>

              <div className="col-xs-3" >
                <input
                  name="price"
                  className="form-control"
                  type="number"
                  min={0}
                  onChange={e => { this.setMarkTypePrice(e.target.value) }}
                  value={markType.price}
                  required />
              </div>

              {markType.Prices.length > 0 &&
                <div className="col-xs-6">
                  Prezzo per 1° componente del nucleo abitativo
                </div>
              }
            </div>

            {markType.Prices.length <= 0 &&
              <div className="row" style={{ marginBottom: "1em" }}>

                <div className="col-xs-3">
                  <h5 style={{ marginTop: 10, marginBottom: 5, verticalAlign: "top" }}></h5>
                </div>
                <div className="col-xs-3" >
                  <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-edit-time-slots"} style={{ padding: 0, margin: 0 }}>
                    Aggiungi voci subtotali visualizzabili nella ricevuta e nel report del permesso
                  </Tooltip>}>
                    <Button className="btn mainBtn" style={{ margin: 0, marginTop: "0.1em" }} onClick={() => { this.addSubTotal() }} >
                      <FontAwesomeIcon className="IconsRevNoAction" size="1x" icon={solidIcons.faPlusCircle} />&nbsp;&nbsp;
                      Sub Totale
                    </Button>
                  </OverlayTrigger>
                </div>
              </div>
            }


            {markType.SubTotals.length <= 0 &&
              <div className="row" style={{ marginBottom: "1em" }}>

                <div className="col-xs-3">
                  <h5 style={{ marginTop: 10, marginBottom: 5, verticalAlign: "top" }}></h5>
                </div>

                {this.isResidentCategorySelected(markType.Categories) &&
                  <div className="col-xs-3" >
                    <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-edit-time-slots"} style={{ padding: 0, margin: 0 }}>
                      Aggiungi prezzo permesso per componente del singolo nucleo abitativo
                    </Tooltip>}>
                      <Button className="btn mainBtn" style={{ margin: 0, marginTop: "0.1em" }} onClick={() => { this.addPrice(PRICE_TYPE_PERSON_PER_HOUSING_UNIT, markType.Prices.length + 2) }} >
                        <FontAwesomeIcon className="IconsRevNoAction" size="1x" icon={solidIcons.faPlusCircle} />&nbsp;&nbsp;
                        Prezzo nucleo abitativo
                      </Button>
                    </OverlayTrigger>
                  </div>
                }

              </div>
            }

            {/* PREZZI */}
            {markType.Prices.length > 0 &&
              <React.Fragment>
                <div className="row" >
                  <div className="col-xs-3" >
                    <h5 style={{ marginTop: "0.5em", marginBottom: "0.5em" }}><strong>PREZZI</strong></h5>
                  </div>
                </div>

                <div className="row" style={{ marginBottom: "1em" }}>

                  <div className="col-xs-3">
                    &nbsp;
                  </div>

                  <div className="col-xs-3">
                    <h5 style={{ marginTop: 10, marginBottom: 5, verticalAlign: "top" }}><strong>Valore €</strong></h5>
                  </div>

                  <div className="col-xs-6">
                    <h5 style={{ marginTop: 10, marginBottom: 5, verticalAlign: "top" }}><strong>Descrizione</strong></h5>
                  </div>

                </div>

                {markType.Prices.map((price, index) =>
                  <div key={"price" + index} className="row" style={{ marginBottom: "1em" }}>
                    <div className="col-xs-3" >
                      <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-remove-price" + index} style={{ padding: 0, margin: 0 }}>
                        Rimuovi Prezzo
                      </Tooltip>}>
                        <Button className="btn alertBtn" style={{ margin: 0, marginTop: "0.1em" }} onClick={() => { this.removePrice(index) }}><FontAwesomeIcon size="1x" icon={solidIcons.faTrash} /></Button>
                      </OverlayTrigger>
                    </div>

                    <div className="col-xs-3">
                      <input
                        name={"priceValue" + index}
                        className="form-control"
                        type="number"
                        min={0}
                        onChange={e => { this.setPriceValue(index, e.target.value) }}
                        value={price.value}
                        required />
                    </div>

                    <div className="col-xs-6">
                      Prezzo per {index + 2}° componente del nucleo abitativo
                    </div>
                  </div>
                )}
              </React.Fragment>
            }

            {/* SUBTOTALI */}
            {markType.SubTotals.length > 0 &&
              <React.Fragment>
                <div className="row" >
                  <div className="col-xs-3" >
                    <h5 style={{ marginTop: "0.5em", marginBottom: "0.5em" }}><strong>SUBTOTALI</strong></h5>
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "1em" }}>
                  <div className="col-xs-3">
                    &nbsp;
                  </div>

                  <div className="col-xs-3">
                    <h5 style={{ marginTop: 10, marginBottom: 5, verticalAlign: "top" }}><strong>Valore €</strong></h5>
                  </div>

                  <div className="col-xs-6">
                    <h5 style={{ marginTop: 10, marginBottom: 5, verticalAlign: "top" }}><strong>Descrizione</strong></h5>
                  </div>
                </div>

                {markType.SubTotals.map((subTotal, index) =>
                  <div key={"subTotal" + index} className="row" style={{ marginBottom: "1em" }}>
                    <div className="col-xs-3" >
                      <Button className="btn alertBtn" style={{ margin: 0, marginTop: "0.1em" }} onClick={() => { this.removeSubTotal(index) }}><FontAwesomeIcon size="1x" icon={solidIcons.faTrash} /></Button>
                    </div>
                    <div className="col-xs-3">
                      <input
                        name={"subTotalValue" + index}
                        className="form-control"
                        type="number"
                        min={0}
                        onChange={e => { this.setSubtotalValue(index, e.target.value) }}
                        value={subTotal.value}
                        required />
                    </div>

                    <div className="col-xs-6">
                      <input
                        name={"subTotalDescription" + index}
                        className="form-control"
                        type="text"
                        onChange={e => { this.setSubtotalDescription(index, e.target.value) }}
                        value={subTotal.description}
                        required />
                    </div>
                  </div>
                )}

                <div className="row" style={{ marginBottom: "1em" }}>
                  <div className="col-xs-3">
                    &nbsp;
                  </div>

                  <div className="col-xs-3">
                    <input
                      name="priceValue"
                      className="form-control"
                      type="number"
                      value={this.getPriceSubtotal()}
                      required
                      disabled
                    />
                  </div>

                  <div className="col-xs-6">
                    <input
                      name="priceDescription"
                      className="form-control"
                      type="text"
                      value={"Tariffa permesso"}
                      required
                      disabled
                    />
                  </div>
                </div>
              </React.Fragment>
            }

            {(markType.SubTotals.length > 0 || markType.Prices.length > 0) &&
              <div className="noteRowNoMargin" style={{ marginBottom: "1em", minHeight: 0 }}></div>
            }

            <div className="row" style={{ marginBottom: "1em" }}>
              <div className="col-xs-3">
                <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-time-duration"}>
                  Validità temporale del permesso che può essere espressa in termini di durata in giorni o di data di scadenza fissa.
                </Tooltip>}>
                  <h5 style={{ marginTop: 10, marginBottom: 5, verticalAlign: "top" }}><strong>{expiration.type == "DAYS" ? "Durata" : "Scadenza"}: </strong>&nbsp;<span className="glyphicon glyphicon-info-sign">{ }</span></h5>
                </OverlayTrigger>
              </div>

              <div className="col-xs-3" >
                <select
                  className="form-control"
                  name="expirationType"
                  onChange={e => { this.setMarkTypeExpirationType(e.target.value) }}
                  value={expiration.type}
                >
                  <option value="DAYS">Giorni</option>
                  <option value="DATE">Data</option>
                </select>
              </div>

              <div className="col-xs-3" >
                {expiration.type == "DAYS"
                  ? expiration.days != null &&
                  <input
                    onChange={(e) => { this.setMarkDuration(e.target.value) }}
                    value={expiration.days}
                    name={`markDuration-${markType.id}`}
                    type="number"
                    min={0}
                    className="form-control"
                  />
                  : expiration.date != null && <ReactDatePicker
                    locale={locale}
                    required
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="GG/MM/AAAA"
                    selected={expiration.date.toDate()}
                    onChange={(value) => this.setMarkExpiration(value)}
                  />
                }
              </div>
            </div>

            <div className="row" style={{ marginBottom: "1em" }}>

              <div className="col-xs-3">
                <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-virtualizable"}>
                  Nel caso sia puntato il permesso viene totalmente dematerializzato e non necessita di nessun tagliando da esporre sul veicolo. I controlli vengono effettuati informaticamente.
                </Tooltip>}>
                  <h5 style={{ marginTop: 10, marginBottom: 5, verticalAlign: "top" }}><strong>Virtuale</strong> <span className="glyphicon glyphicon-info-sign">{ }</span></h5>
                </OverlayTrigger>
              </div>

              <div className="col-xs-3 pull-left" >
                <input
                  style={{ width: "2em" }}
                  name="virtualizable"
                  className="form-control"
                  type="checkbox"
                  onChange={e => { this.setMarkTypeVirtualizable(e.target.checked) }}
                  checked={markType.virtualizable}
                />
              </div>

              <div className="col-xs-3">
                <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-color"}>
                  E' possibile scegliere un colore che abbia priorità su quello scelto per la zona,
                  che venga mostrato all’interno della generazione e stampa dei tagliandi dei permessi,
                  e in fase di richiesta Permesso lato interfaccia frontoffice
                </Tooltip>}>
                  <h5 style={{ marginTop: 10, marginBottom: 5, verticalAlign: "top" }}><strong>Colore:</strong>&nbsp;<span className="glyphicon glyphicon-info-sign">{ }</span></h5>
                </OverlayTrigger>
              </div>

              <div className="col-xs-3"  >
                <input
                  style={{ background: markType.color, color: markType.color }}
                  name="color"
                  className="form-control"
                  type="text"
                  onChange={e => { this.setMarkTypeColor(e.target.value) }}
                  onClick={e => { this.showColorPicker() }}
                  value={markType.color}
                  required />

                {displayColorPicker &&
                  <div className="row">
                    <div className="pull-left" style={{ margin: "0em", marginTop: "0.3em" }}>
                      <div className="col-xs-12" >
                        <SketchPicker
                          color={markType.color}
                          onChangeComplete={this.setMarkTypeColor}
                        />
                      </div>
                      <div className="col-xs-12">
                        <Button className="btn mainBtn" onClick={() => { this.showColorPicker() }}>
                          <FontAwesomeIcon size="1x" icon={solidIcons.faXmarkCircle} />&nbsp;&nbsp;Chiudi
                        </Button>
                      </div>
                    </div>
                  </div>
                }
              </div>

            </div>

            <div className="row" style={{ marginBottom: "1em" }}>

              <div className="col-xs-3">
                <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-renewable"}>
                  Nel caso il permesso sia rinnovabile vengono attivati, ( a seconda della configurazione generale ) N giorni prima,
                  i tasti di rinnovo sotto i permessi, sia nella sezione utente che amministrazione,
                  e viene mandato un messaggio di notifica di scadenza del permesso.
                </Tooltip>}>
                  <h5 style={{ marginTop: 10, marginBottom: 5, verticalAlign: "top" }}><strong>Rinnovabile</strong> <span className="glyphicon glyphicon-info-sign">{ }</span></h5>
                </OverlayTrigger>
              </div>
              <div className="col-xs-3 pull-left" >
                <input
                  style={{ width: "2em" }}
                  name="renewable"
                  className="form-control"
                  type="checkbox"
                  onChange={e => { this.setMarkTypeRenewable(e.target.checked) }}
                  checked={(markType.DocumentTypes[0] && markType.DocumentTypes[0].fixMandatory == true) ? false : markType.renewable}
                  disabled = {(markType.DocumentTypes[0] && markType.DocumentTypes[0].fixMandatory == true) ? true : false}                    
                />
              </div>

              <div className="col-xs-3" >
                <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-max-marks"}>
                  Numero massimo di permessi attivi ottenibili da un singolo utente
                </Tooltip>}>
                  <h5 style={{ marginTop: 10, marginBottom: 5, verticalAlign: "top" }}><strong>Max Permessi: </strong><span className="glyphicon glyphicon-info-sign">{ }</span></h5>
                </OverlayTrigger>
              </div>

              <div className="col-xs-3" >
                <input
                  name="marksLimit"
                  className="form-control"
                  type="number"
                  onChange={e => { this.setMarksLimit(e.target.value) }}
                  value={markType.MarkRules[0].marksLimit}
                  min={0}
                  required />
              </div>
            </div>

            <div className="row" style={{ marginBottom: "1em" }}>

              <div className="col-xs-3">
                <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-renewable"}>
                  Se marcato abilita il controllo operatore sulla richiesta permesso on-line da parte di un utente.
                  Se smarcato l'utente passa direttamente al pagamento del permesso senza nessun controllo da parte dell'operatore.
                </Tooltip>}>
                  <h5 style={{ marginTop: 10, marginBottom: 5, verticalAlign: "top" }}><strong>Controllo Operatore</strong> <span className="glyphicon glyphicon-info-sign">{ }</span></h5>
                </OverlayTrigger>
              </div>

              <div className="col-xs-3 pull-left" >
                <input
                  style={{ width: "2em" }}
                  name="operatorCheck"
                  className="form-control"
                  type="checkbox"
                  onChange={e => { this.setOperatorCheck(e.target.checked) }}
                  checked={markType.operatorCheck}
                />
              </div>          

              <div className="col-xs-3">
                <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-max-vehicles"}>
                  numero massimo di veicoli assegnabili ad ogni permesso
                </Tooltip>}>
                  <h5 style={{ marginTop: 10, marginBottom: 5, verticalAlign: "top" }}><strong>Max Veicoli: </strong><span className="glyphicon glyphicon-info-sign">{ }</span></h5>
                </OverlayTrigger>
              </div>

              <div className="col-xs-3">
                <input
                  name="vehicleLimit"
                  className="form-control"
                  type="number"
                  min={0}
                  onChange={e => { this.setVehicleLimit(e.target.value) }}
                  value={markType.MarkRules[0].vehicleLimit}
                  required />
              </div>
            </div>

            <div className="row" style={{ marginBottom: "1em" }}>
              <div className="col-xs-3">
                <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-renewable"}>
                  Se selezionato, abilita la vendita di questo tipo di permesso ai rivenditori.
                  Se deselezionato, questo permesso non sarà disponibile ai rivenditori.
                </Tooltip>}>
                  <h5 style={{ marginTop: 10, marginBottom: 5, verticalAlign: "top" }}><strong>Vendibile</strong> <span className="glyphicon glyphicon-info-sign">{ }</span></h5>
                </OverlayTrigger>
              </div>

              <div className="col-xs-3 pull-left" >
                <input
                  style={{ width: "2em" }}
                  name="resalable"
                  className="form-control"
                  type="checkbox"
                  onChange={e => { this.setResalableCheck(e.target.checked) }}
                  checked={markType.resalable}
                />
              </div>
            
            </div>
            {
              /** Consente all'utente di stampare direttamente i permessi dall'area user se l'opzione MARK_PRINT_BY_USER è impostata nei settings del DB */
              console.log("BackofficeEditMarkType - render - printOption:", this.state.printOption)
            }
            { /** TODO INCORSO */
              this.getPrintByUserOption(settings) &&
              <div className="row">
                <div className="col-xs-3">
                  <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-printable"}>
                    Se selezionato, abilita la stampa di questo tipo di permesso agli utenti.
                    Se deselezionato, questo permesso non sarà stampabile dall'area-user.
                  </Tooltip>}>
                    <h5 style={{ marginTop: 10, marginBottom: 5, verticalAlign: "top" }}><strong>Stampabile da User-Area</strong> <span className="glyphicon glyphicon-info-sign">{ }</span></h5>
                  </OverlayTrigger>
                </div>

                <div className="col-xs-3 pull-left" >
                  <input
                    style={{ width: "2em" }}
                    name="printableByUser"
                    className="form-control"
                    type="checkbox"
                    onChange={e => { this.setPrintableCheck(e.target.checked) }}
                    checked={markType.printableByUser}
                  />
                </div>
              </div>
            }
            { /* SEZIONE DOCUMENTI */}
            <div className="noteRowNoMargin" style={{ marginBottom: "1em", minHeight: 0 }}></div>

            <div className="row" style={{ maring: 0, marginBottom: "1em" }}>
              <div className="col-xs-6" style={{ maring: 0, marginBottom: "1em" }}>
                <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-documents"}>
                  specificare quali documenti, opzionali o obbligatori, l’utente debba allegare alla richiesta del permesso
                </Tooltip>}>
                  <h5 style={{ marginTop: "1em" }}><strong>Aggiungi documento</strong> <span className="glyphicon glyphicon-info-sign">{ }</span></h5>
                </OverlayTrigger>
              </div>
              <div className="col-xs-6" style={{ maring: 0, padding: 0 }}>
                <div className="col-xs-12" style={{ maring: 0, paddingLeft: 0, marginBottom: "1em" }}>
                  <select
                    name="docType"
                    className="form-control"
                    onChange={(e) => { this.setState({ selectedDocType: e.target.value }) }}
                    value={selectedDocType}
                  >
                    <option value={DOCUMENT_TYPE_ALL}>Tutti</option>
                    <option value={DOCUMENT_TYPE_GENERIC}>Doc. Generici</option>
                    <option value={DOCUMENT_TYPE_DRIVER} >Doc. Conducente</option>
                    <option value={DOCUMENT_TYPE_VEHICLE}>Doc. Veicolo</option>
                    <option value={DOCUMENT_TYPE_IDCARD} >Doc. Identità</option>
                  </select>
                </div>

                <div className="col-xs-12" style={{ maring: 0, paddingLeft: 0, marginBottom: "1em" }}>
                  <select
                    name="selectedDocumentTypeId"
                    className="form-control"
                    onChange={(e) => { this.addDocumentType(e.target.value) }}
                  >
                    <option value={null}> - </option>
                    {this.getDocumentTypes(selectedDocType).map(documentType =>
                      <option key={documentType.id} value={documentType.id}>{documentType.label}</option>
                    )}
                  </select>
                </div>
              </div>
            </div>

            {markType.DocumentTypes != null && markType.DocumentTypes.length > 0 &&
              <div className="row" style={{ marginBottom: "1em" }}>
                <div className="col-xs-9">
                  <h5 style={{ marginTop: "1em" }}><strong>DOCUMENTI RICHIESTI PER IL PERMESSO</strong></h5>
                </div>
                <div className="col-xs-3">
                  <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-obbligatory-doc"}>
                    Se spuntata, il documento deve essere fornito obbligatoriamente in fase di richiesta del permesso da parte dell'utente.
                    In caso contrario il documento viene richiesto ma solo in modo opzionale.
                  </Tooltip>}>
                    <h5 style={{ marginTop: "1em" }}><strong>OBBLIGATORIO</strong>&nbsp;<span className="glyphicon glyphicon-info-sign">{ }</span></h5>
                  </OverlayTrigger>
                </div>
              </div>
            }

            {markType.DocumentTypes.map(documentType =>
              <div className="row" key={"document-" + documentType.id} style={{ marginBottom: "1em" }}>
                
                <div className="col-xs-9">
                  <h5 style={{ marginTop: "1em" }}><strong>{documentType.label}</strong></h5>
                </div>

                <div className="col-xs-1">
                  <input
                    style={{ width: "2em" }}
                    name="virtualizable"
                    className="form-control"
                    type="checkbox"
                    onChange={e => { this.setMarkTypeDocumentMandatory(documentType.id, e.target.checked) }}
                    checked={(documentType.fixMandatory) ? true : documentType.MarkTypesDocuments.mandatory }
                    disabled = {(documentType.fixMandatory) ? true : false}                   
                  />
                </div>

                <div className="col-xs-2">
                  <Button className="btn alertBtn" onClick={() => { this.removeDocumentType(documentType.id) }}><FontAwesomeIcon size="1x" icon={solidIcons.faTrash} /></Button>
                </div>
              </div>
            )}

            {markType.DocumentTypes != null && markType.DocumentTypes.length > 0 &&
              <div className="noteRowNoMargin" style={{ marginBottom: "1em", minHeight: 0 }}></div>
            }

            { /* SEZIONE MESSAGGI D'ERRORE */}
            <div className="row" style={{ marginBottom: "1em" }}>
              {sendMsg != null &&
                <h4 className="col-md-12 text-confirm text-center" style={{ marginBottom: '1.5em', marginTop: '1em' }}>
                  {sendMsg}
                </h4>
              }
              {sendErrors != null &&
                <h4 className="col-md-12 text-danger text-center" style={{ marginBottom: '1.5em', marginTop: '1em' }}>
                  {sendErrors}
                </h4>
              }
              {checkErrors != null && checkErrors.length > 0 &&
                <h4 className="col-md-12 text-danger text-center" style={{ marginBottom: '1.5em', marginTop: '1em' }}>
                  {checkErrors.map((error, index) => { return (<div key={index}>{error}</div>) })}
                </h4>
              }
            </div>

            { /* SEZIONE PULSANTI */}
            <div className="row" style={{ marginBottom: "1em" }}>
              <div className="col-md-12 text-center" style={{ marginBottom: '1.5em', marginTop: '1em' }}>
                <Button className="btn alertBtn" onClick={() => { this.cancelEditMarkType() }}>Annulla</Button>{' '}
                <Button className="btn mainBtn" onClick={() => { this.saveMarkType() }} >Salva</Button>
                {markTypeId &&
                  <Button className="btn alertBtn" onClick={() => { this.setState({ showConfirmDeleteMarkType: true }) }}><FontAwesomeIcon size="1x" icon={solidIcons.faTrash} />&nbsp;&nbsp;Elimina</Button>
                }
              </div>
            </div>

          </div>
        }

        <RequestConfirmModal
          requestStyle={2}
          requestTitle="Conferma Eliminazione Tipo Permesso"
          requestSubTitle="ATTENZIONE"
          requestMsg={"Proseguendo con l'operazione si eliminerà il tipo permesso " + markType.name + "<br/><br/>L'operazione è irreversibile, si vuole procedere?"}
          show={showConfirmDeleteMarkType}
          onConfirm={this.deleteMarkType}
          onRefuse={() => { this.setState({ showConfirmDeleteMarkType: false }) }}
        />

        <BackofficeEditTimeSlotModal
          show={showEditTimeSlot}
          timeSlot={selectedTimeSlot}
          onSave={this.onSaveTimeSlot}
          onDelete={this.onDeleteTimeSlot}
          onCancelEdit={this.onCancelEditTimeSlot}
          onClose={this.onCancelEditTimeSlot}
        />
      </React.Fragment>

    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'BackofficeEditMarkType' })(BackofficeEditMarkType))
