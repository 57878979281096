import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import './NumberPlateModal.css'
import "react-responsive-modal/styles.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'


export default class NumberPlateModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    error: PropTypes.string
  }


  render() {
    const {
      closeModal,
      open,
      mark,
      error      
    } = this.props

    return (
      
      <Modal
        open={open}
        onClose={closeModal}
        showCloseIcon={false}
        closeOnEsc={true}
        closeOnOverlayClick={true}
        classNames={{ modal: 'app-modal-container' }}        
        focusTrapped={true}
        animationDuration={500}
        center               
      >
        {mark && (         
         <React.Fragment>
            <h4 className="app-modal-title">
              <div style={{ flex: 10, textAlign : 'left'}}>
                <FontAwesomeIcon className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faCar} />
              </div>          
              <div style={{ flex: 80, textAlign : 'center' }}><strong>Scegli il veicolo attivo</strong></div>          
              <div style={{ flex: 10, textAlign : 'right'}}>
                <FontAwesomeIcon  onClick={ evt => this.props.closeModal() } className="mainIconsRev"  size="1x" icon={solidIcons.faXmark} />
              </div>          
            </h4> 
            <div className="panel-body">             
              <p>Clicca su una delle targhe per modificare il veicolo attivo del <br/> permesso: <b>{mark.MarkType.name}</b> n: <b>{mark.id}</b></p>
              {error && <p className="text-danger">Errore! {error}</p>}
              <legend></legend>
              {mark.Vehicles.map((item,index) =>
                { if ( !item.archived ) return (
                <div  key={index} className={`plate-number-button ${mark.ActiveVehicle.id === item.id ? 'plate-number-button-selected' : ''}`} onClick={() => { this.props.onVehicleChoosen(item) }}>
                  {item.plate_number}
                  {mark.ActiveVehicle.id === item.id && <span className="glyphicon glyphicon-ok circular-button" />}
                </div>
                )}
              )}
              <button className="platenumber-modal-button-cancel" onClick={this.props.closeModal}>Annulla</button>
            </div>
            </React.Fragment>
          //</div>
        )}
      </Modal>      
      
    )
  }
}




