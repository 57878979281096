import React, { Component }  from 'react'
import SostaOnlineApiService from '../../../services/SostaOnlineApiService'
import moment                from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import  * as regularIcons  from '@fortawesome/free-regular-svg-icons'
import NewMessageModal from 'components/Messages/NewMessageModal'
import './Messages.css'
import { getSubjectDescription } from 'libs/messages'
import MessageModal from 'components/Messages/MessageModal'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import { reportActions } from 'redux/modules/Report' 
import { connect } from 'react-redux'
import { NEWS_SECTIONS, getHtmlMsgFromString, getNewsStyle } from 'libs/news'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"

const TextUser     = (props) => <div className={props.bold ? 'text-bold-user' : 'text-regular-user'} style={{textAlign: 'center'}} >{props.children}</div>

const mapStateToProps = (state) => {
  return {
    stateAuth: state.auth,
    stateReport : state.report
  }
}

const mapDispatchToProps = (dispatch) => {
  return {    
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}


class Messages extends Component {
  static propTypes = {}

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      selectedThreadId: null,
      selectedChildId: null,
      messages: [],
      showNewMessageModal: false,
      showMessageModal: false,
      sendErrors: null,
      selectedMsg: null,
      news: []
    }

    this.getClassRow = this.getClassThreadRow.bind(this)    
    this.onCloseNewMessageModal = this.onCloseNewMessageModal.bind(this)
    this.onSendNewMessage = this.onSendNewMessage.bind(this)
    this.onCloseMessageModal = this.onCloseMessageModal.bind(this)
    this.onSendReplyMessage = this.onSendReplyMessage.bind(this)
    this.selectChild = this.selectChild.bind(this)
    this.expandCollapseTree = this.expandCollapseTree.bind(this)
    this.selectTree = this.selectTree.bind(this)
    this.fetchThingsToDo = this.fetchThingsToDo.bind(this)
    
  }

  /**
   * --------------------------------------------------------------
   */
  async componentDidMount () {
    this.setState({loading: true})
    await this.fetchMessages() 
    await this.fetchNews()   
    //this.fetchThingsToDo()
    this.setState({loading: false})
  }

  /**
   *  --------------------------------------------------------------
   * 
   */
  async fetchNews() {
    const response = await SostaOnlineApiService.fetchNewsMe(NEWS_SECTIONS.USERMESSAGES.value)       
    this.setState({
      news: response.data
    })      
  }

  /**
   * --------------------------------------------------------------
   * @param {*} prevProps 
   */
  async componentDidUpdate(prevProps) {
    //console.log("Messages componentDidUpdate")
    
  }

  /**
   * --------------------------------------------------------------
   */
  async fetchMessages () {
    await SostaOnlineApiService.fetchMyMessages()
      .then( result => {
          if ( result.result === 'ok' ) {                     
              this.setState({'messages':result.data})
          }
      } )
      .catch(err => {                        
          console.log("ERR",err)            
      })  
  }

  /**
   * --------------------------------------------------------------
   */
  fetchThingsToDo () {    
    SostaOnlineApiService.fetchThingsToDoMe().then(data => {      
      this.props.reportActions.fetchThingsToDo(data.data)
    })
    .catch( error => {
      console.log("ERROR",error)
    })
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  getSortedList() {
    var {            
        messages: messages
    } = this.state

    messages = messages.filter( item => item.rootMessage == null  )
    return messages ? messages.sort((a, b) => ( moment(a.threadUpdate).isBefore(moment(b.threadUpdate)) ) ? 1 : -1) : []
  }

  /**
   * --------------------------------------------------------------
   * @param {*} rootMsg 
   * @returns 
   */
  getChilds(rootMsg) {
    var {            
      messages: messages
    } = this.state

    messages = messages.filter( item => item.rootMessage == rootMsg.id )
    return messages ? messages.sort((a, b) => (a.id > b.id ) ? -1 : 1) : []
  }

  /**
   * --------------------------------------------------------------
   * @param {*} rootMsg 
   */
  expandCollapseTree(rootMsg) {  

    if ( this.state.selectedThreadId === rootMsg.id ) {
      this.setState({selectedThreadId: null})
      this.setState({selectedChildId: null})
      //console.log("DESELECT: ",rootMsg.id)
    }
    else {      
      this.setState({selectedThreadId: rootMsg.id})
      //console.log("SELECT: ",rootMsg.id)      
    }

    if (  this.childsCount(rootMsg)  <= 0  ) {
      this.showMessage(rootMsg)
    }

  }

  /**
   * --------------------------------------------------------------
   * @param {*} rootMsg 
   */
  selectTree(rootMsg) {      
      this.setState({selectedThreadId: rootMsg.id})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} msg 
   */
  selectChild(childMsg) {
    if ( this.state.selectedThreadId === childMsg.id ) {      
      this.setState({selectedChildId: null})
      //console.log("DESELECT: ",childMsg.id)
    }
    else {      
      this.setState({selectedChildId: childMsg.id})
      //console.log("SELECT: ",childMsg.id)      
    }
  }

  /**
   * --------------------------------------------------------------
   * @param {*} msg 
   */
  getClassThreadRow(msg) {
    var classRow = "messageRow"
    
    if (  msg.id === this.state.selectedThreadId ) {
      if ( !msg.read && msg.fromId !== this.props.user.id )      
        classRow = "messageUnreadSelectedRow"
      else
        classRow = "messageSelectedRow"
    }
    else if ( this.haveUnreadChilds(msg) || ( !msg.read && msg.fromId !== this.props.user.id  ) ) {
      classRow = "messageUnreadRow"
    }

    return classRow
  }

  /**
   * --------------------------------------------------------------
   * @param {*} msg 
   */
  getClassChildRow(msg) {
    var classRow = "messageRow"

    if (  msg.id === this.state.selectedChildId ) {
      if ( !msg.read && msg.fromId !== this.props.user.id )      
        classRow = "messageUnreadSelectedRow"
      else
        classRow = "messageSelectedRow"
    }
    else if ( !msg.read && msg.fromId !== this.props.user.id  ) {
      classRow = "messageUnreadRow"
    }

    return classRow
  }

  /**
   * --------------------------------------------------------------
   * @param {*} msg 
   */
  getMailIcon(msg) {
    var icon = solidIcons.faDownload
    var beat = true
    var tooltip = "messaggio in arrivo"

    if( msg.read ) {
      beat = false
    }

    if ( this.props.user.id === msg.fromId )  {
      beat = false

      if ( msg.rootMessage == null ) {
        tooltip = "Messaggio inviato"
        icon = solidIcons.faUpload
      }
      else {
        tooltip = "Risposta inviata"
        icon = solidIcons.faReply
      }
    }    
    
    return (
      <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-0-'+msg.id}>{tooltip}</Tooltip>}>
        <FontAwesomeIcon  className="mainIconsNoAction"  beatFade={beat} size="2x" icon={icon} />
      </OverlayTrigger>    
    )
  }  

  /**
   * --------------------------------------------------------------
   * @param {*} msg 
   */
  getThreadIcon(msg) {
    var icon           = solidIcons.faFolder
    var classIcon      = "mainIcons"
    var tooltipMessage = "Clicca per vedere lo scambio di messaggi"
      
    if ( msg.rootMessage != null || this.getChilds(msg) <= 0  ) {
      icon = regularIcons.faFileLines            
      tooltipMessage = this.getTooltipMessage(msg)
    }    
    else if ( msg.id === this.state.selectedThreadId ) {
      icon = solidIcons.faFolderOpen
    }
    
    return (
      <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-1-'+msg.id}>{tooltipMessage}</Tooltip>}> 
        <FontAwesomeIcon  className={classIcon}  size="2x" icon={icon} />      
      </OverlayTrigger>
    )
    
  }

  /**
   * --------------------------------------------------------------
   * @param {*} msg 
   * @returns 
   */
  getTooltipMessage(msg) {
    var tooltipMessage = ""

    if ( msg.fromId === this.props.user.id )
      tooltipMessage = "Clicca per vedere il Messaggio"
    else
      tooltipMessage = "Clicca per vedere il Messaggio e rispondere."

    return tooltipMessage
  }

  /**
   * --------------------------------------------------------------
   * @param {*} msg 
   */
  childsCount(msg) {
    var childs = this.state.messages.filter( m  =>  m.rootMessage === msg.id )
    return childs.length
  }


  /**
   * --------------------------------------------------------------
   * @param {*} msg 
   */
  haveUnreadChilds(msg) {
    var childs = this.state.messages.filter( m  =>   m.rootMessage === msg.id && !m.read && m.fromId !== this.props.user.id  )
    //console.log("ID ", msg.id , " CHILDS ",childs.length)
    return childs.length > 0 ? true : false
  }

  /**
   * --------------------------------------------------------------
   * @param {*} msg 
   * @param {*} index 
   * @returns 
   */
  getRootMsgRow(msg) {

    return (     
      <tbody  key={'main-'+msg.id}>
        
        <tr key={'msg-'+msg.id} className={ this.getClassThreadRow(msg) }>
          <td className="text-left" onClick={ evt => { this.expandCollapseTree(msg) } } >{this.getThreadIcon(msg)}</td>          
          <td className="text-left" onClick={ evt => { this.showMessage(msg) } } >{this.getMailIcon(msg)}</td>
          <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-2-'+msg.id}>{this.getTooltipMessage(msg)}</Tooltip>}>
            <td className="text-left" onClick={ evt => { this.showMessage(msg) } } >{getSubjectDescription(msg.subject)}</td>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-3-'+msg.id}>{this.getTooltipMessage(msg)}</Tooltip>}>
            <td className="text-left hidden-xs" onClick={ evt => { this.showMessage(msg) } } >{msg.content.substring(0,60)} ...</td>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-4-'+msg.id}>{this.getTooltipMessage(msg)}</Tooltip>}>
            <td className="text-left" onClick={ evt => { this.showMessage(msg) } } >{moment(msg.threadUpdate).format('L')}</td>
          </OverlayTrigger>
        </tr>
        {  msg.id === this.state.selectedThreadId  && this.getChilds(msg).map( ( msg1 ) => {      
          return(
            <tr key={'msg-'+msg1.id} className={this.getClassChildRow(msg1)} >
              
              <td className="text-right" onClick={ evt => { this.showMessage(msg1) } }><div>{'   '}</div>{this.getThreadIcon(msg1)}</td>
              
              <td className="text-left" onClick={ evt => { this.showMessage(msg1) } }>{this.getMailIcon(msg1)}</td>
              <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-2-'+msg1.id}>{this.getTooltipMessage(msg1)}</Tooltip>}>
                <td className="text-left" onClick={ evt => { this.showMessage(msg1) } }>{getSubjectDescription(msg1.subject)}</td>
              </OverlayTrigger>
              <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-2-'+msg1.id}>{this.getTooltipMessage(msg1)}</Tooltip>}>
                <td className="text-left hidden-xs" onClick={ evt => { this.showMessage(msg1) } }>{msg1.content.substring(0,60)} ...</td>
              </OverlayTrigger>
              <OverlayTrigger placement="top" overlay={<Tooltip id={'tooltip-2-'+msg1.id}>{this.getTooltipMessage(msg1)}</Tooltip>}>
                <td className="text-left" onClick={ evt => { this.showMessage(msg1) } }>{moment(msg1.threadUpdate).format('L')}</td>
              </OverlayTrigger>
            </tr>
          )
          })
        }               
      </tbody>  
    )
  
  }

  /**
   * --------------------------------------------------------------
   * @param {*} msg 
   */
  showMessage(msg) {

    if ( msg.rootMessage == null )
      this.selectTree(msg)
    else
      this.selectChild(msg)

    if ( !msg.read && msg.toId === this.props.user.id) {

      var updateMsg = {
        id: msg.id,
        read: true
      }

      SostaOnlineApiService.updateMessageMe(updateMsg)
        .then(
          data => {
            var messages  = this.state.messages.map( m => {  

              if ( m.id === msg.id )   
                m.read = true
        
              return m 
        
            })
        
            this.setState({messages: messages})
            this.fetchThingsToDo() 
          }
        )
        .catch( error => {
          console.log("ERROR",error)
        })
    }

    this.setState({selectedMsg: msg, showMessageModal: true})    
    
        
  }


  /**
   * --------------------------------------------------------------
   */
  onOpenNewMessageModal () {
    this.setState({      
      showNewMessageModal: true
    })
  }

  /**
   * --------------------------------------------------------------
   */
  onCloseNewMessageModal () {
    this.setState({
      showNewMessageModal: false,
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} msg 
   */
  onSendNewMessage (msg) {
    //console.log("FETCHING MESSAGES")
    this.fetchMessages() 
  }

  /**
   * --------------------------------------------------------------
   */
  onCloseMessageModal () {
    this.setState({
      showMessageModal: false,
    })
  }

  /**
   * --------------------------------------------------------------
   * @param {*} msg 
   */
  onSendReplyMessage (msg) {
    //console.log("FETCHING MESSAGES")
    this.fetchMessages() 
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {

    const {loading, news} = this.state
    const messages = this.getSortedList()

    return (
      <React.Fragment>
        <div className="row">
          <div className={ news && news.length > 0 ? "col-md-8 " : "col-md-12" }>
            <div className="panel panel-default panel-border">
              <h2 className="login-title">
                MESSAGGI
              </h2>
              
              <div className="panel-body">
                <div className="col-md-3">
                  <FontAwesomeIcon size="6x" className="sectionIcons" icon={solidIcons.faEnvelope} />
                </div>           

                    {loading ? (
                      <div className="col-md-9">Caricamento in corso...</div>
                    ) : messages.length === 0 ? (
                      <div className="col-md-9" style={{paddingTop: 20}}>
                        <div className="alternativeMessage">
                          <h4 style={{marginBottom: 5 }}>Al momento non sono presenti conversazioni attive</h4>
                          <span>Clicca il pulsante qui sotto per contattarci!!</span>
                        </div>
                        <div className="col-md-12 messageBtnBar">
                          <div className="col-md-3 mainBtn" onClick={ (evt) => { this.onOpenNewMessageModal() }}><FontAwesomeIcon size="1x" className="mainIconsRevNoAct" icon={solidIcons.faPaperPlane} />&nbsp;&nbsp;Contattaci</div>
                        </div>
                      </div>
                    ) : (
                      <React.Fragment>
                      <div className="col-md-9 messageBtnBar" style={{paddingTop: 20, marginBottom: 50}}>
                        <div className="mainBtn" onClick={ (evt) => { this.onOpenNewMessageModal() }}><FontAwesomeIcon size="1x" className="mainIconsRevNoAct" icon={solidIcons.faPaperPlane} />&nbsp;&nbsp;Contattaci</div>
                      </div>
                      <table className="table table-bordered">
                        <thead>                                                    
                          <tr className="messageHeader">
                            <th className="col-md-1" ></th>                            
                            <th className="col-md-1" ></th>
                            <th className="col-md-4 text-left">Soggetto</th>
                            <th className="col-md-5 text-left hidden-xs">Anteprima</th>
                            <th className="col-md-1">Data</th>
                          </tr>
                        </thead>
                        
                        { messages.map( (msg) =>  this.getRootMsgRow(msg) ) }
                        
                      </table>
                      </React.Fragment>
                    )}
              </div>
            </div>
          </div>

          { news && news.length > 0 && 
          <div className="col-md-4 ">
            
              <div style={{marginLeft: 0}}>
                <div style={{margin: 0 }}>
                  <TextUser bold>COMUNICAZIONI</TextUser>
                  <div style={{ margin: 0 }}>
                    {news != null && news.length > 0 && 
                    <Carousel
                      autoPlay
                      interval={8000}
                      infiniteLoop
                      stopOnHover
                      emulateTouch
                      showThumbs={false}
                    >
                    { news.map(  n => 
                      <div key={"news-"+n} className={"boxLabel " + (getNewsStyle(n))}>
                        {n.thumbImage == null ? (
                        <div>
                          {n.startDate && moment(n.startDate).format('L')} - {n.title}<br/><br/>
                          { getHtmlMsgFromString(n.content)}<br/><br/><br/>
                        </div>
                        ):(
                          <img style={{width:'100%', height:'100%', objectFit:'contain'}} src={n.thumbImage}></img>
                        )}
                      </div>     
                    )} 
                    </Carousel>
                    }
                  </div>
                </div>
              </div>
          </div>
          }
        </div>

        <NewMessageModal          
          show={this.state.showNewMessageModal}
          onSendMessage={this.onSendNewMessage}          
          onClose={this.onCloseNewMessageModal}
          />

        <MessageModal 
          show={this.state.showMessageModal}
          onReplyMessage={this.onSendReplyMessage}          
          onClose={this.onCloseMessageModal}
          msg={this.state.selectedMsg}
          user={this.props.user}
        />

      </React.Fragment>

    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Messages)