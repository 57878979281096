import React, {Component} from 'react'

export default class SplashBtn extends Component {
    render() {
        const {
            onClick,
            children} = this.props;

        return(
            <button type={this.props.type ? this.props.type : null} onClick={onClick} className="btn mainBtn">
                {children}
            </button>
        );
    }
}
