import React, { Component } from 'react'
import PropTypes from 'prop-types'
import "react-responsive-modal/styles.css";
import { reduxForm } from 'redux-form';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SostaOnlineApiService from 'services/SostaOnlineApiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import { SketchPicker } from 'react-color';
import RequestConfirmModal from 'components/General/RequestConfirmModal';
import BackofficeAddressesModal from './BackofficeAddressesModal';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reportActions } from 'redux/modules/Report' 
import {actions as authActions} from 'redux/modules/Auth'
import { ADMIN } from 'libs/roles';
import BackofficePassagesModal from 'components/Passages/BackofficePassagesModal';


moment.locale('it')

/**
 * --------------------------------------------------------------------------
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
  return {
    backofficeValues: state.form.backofficeSettings,
    auth: state.auth,
    stateReport : state.report   
  }
}

/**
 * --------------------------------------------------------------------------
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    authActions:   bindActionCreators(authActions,   dispatch),
    reportActions: bindActionCreators(reportActions, dispatch)
  }
}


class BackofficeEditArea extends Component {

  static propTypes = {    
    areaId:            PropTypes.number,    
    onSaveArea:        PropTypes.func.isRequired,
    onDeleteArea:      PropTypes.func.isRequired,
    onCancelEditArea : PropTypes.func.isRequired,
  }

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = this.getStateInit()

    this.saveArea                  = this.saveArea.bind(this)
    this.checkFields               = this.checkFields.bind(this)    
    this.setAreaColor              = this.setAreaColor.bind(this)
    this.getNullArea               = this.getNullArea.bind(this)
    this.deleteArea                = this.deleteArea.bind(this)
    this.onCloseShowAddressesModal = this.onCloseShowAddressesModal.bind(this)
    this.onCloseShowPassagesModal  = this.onCloseShowPassagesModal.bind(this)
    this.onUpdateAddresses         = this.onUpdateAddresses.bind(this)
    this.onUpdatePassages          = this.onUpdatePassages.bind(this)
    this.editAddresses             = this.editAddresses.bind(this)
   
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  getNullArea() {
    var nullArea = { id: null, name: '', code: '', color: "#ffffff", userCheck: false }

    return nullArea    
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  getStateInit() {
    return {      
      area: this.getNullArea(),
      checkErrors: null,
      sendErrors: null,      
      displayColorPicker: false,
      showConfirmDeleteArea: false,
      showAddressesModal: false,      
      showPassagesModal: false,
      addresses: []
    }
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  stateInit() {
    this.setState(this.getStateInit())    
  }

  /**
   * --------------------------------------------------------------
   */
  componentDidMount() {
    this.stateInit()
    this.fetchData()
  }

  /**
   * --------------------------------------------------------------
   * @param {*} prevProps 
   */
   componentDidUpdate(prevProps) {       

    if ( prevProps.areaId != this.props.areaId  ) {
      this.stateInit()
      this.fetchData()
    }
        
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  async fetchData() {
    
    if ( this.props.areaId != null ) {
      var area = await SostaOnlineApiService.fetchArea(this.props.areaId)

      if ( area != null && area.data != null) {

        if ( area.data.color == null )
          area.data.color = "#ffffff"

        this.setState({area: area.data})
      }
      else {
        this.setState({area: this.getNullArea()})
      }

      var addresses = await SostaOnlineApiService.fetchAddresses(this.props.areaId)

      if ( addresses != null && addresses.data != null ) {
        this.setState({ addresses: addresses.data })
      }
      else {
        this.setState({ addresses: [] })
      }

    }
    else {
      this.setState({area: this.getNullArea()})
    }

  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  checkFields() {

    var errors = []   

    if ( this.state.area.name == null || this.state.area.name.trim().length <= 0 ) {
      errors.push("Nome non specificato")
    }

    if ( this.state.area.code == null || this.state.area.code.trim().length <= 0) {
      errors.push("Codice non specificato")
    }

    if ( this.state.area.color == null || this.state.area.color.length <= 0) {
      errors.push("colore non specificato")
    }

    if ( this.state.area.userCheck == null ) {
      errors.push("Check utente non specificato")
    }

    return errors;

  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  saveArea() {
   // console.log("SAVE AREA",this.state.area)

    var errors = this.checkFields()
    this.setState({checkErrors: errors})

    if ( errors != null && errors.length > 0 ) {      
      return
    }    

    if ( this.state.area.id == null  ) {

      SostaOnlineApiService.createArea(this.state.area)
      .then( result => {    
        
        var areaId  = null

        if ( result.data != null && result.data.id != null )
          areaId =  result.data.id
        
        if ( this.props.onSaveArea != null ) {
          this.props.onSaveArea(areaId)
        }           
  
      })
      .catch( error => {
        console.log("ERROR",error)
        this.setState({ sendErrors: "Errore durante Il salvataggio, riprovare più tardi!" })
      })

    }
    else {
      SostaOnlineApiService.updateArea(this.state.area)
      .then( result => {      
        
        if ( this.props.onSaveArea != null ) {
          this.props.onSaveArea(this.props.areaId)
        }        
  
      })
      .catch( error => {
        console.log("ERROR",error)
        this.setState({ sendErrors: "Errore durante Il salvataggio, riprovare più tardi!" })
      })

    }

  }

  /**
   * --------------------------------------------------------------
   * 
   */
  async deleteArea() {    

    if ( this.props.areaId != null  ) {

      await SostaOnlineApiService.deleteArea(this.props.areaId)
      .then( result => {      
        
        if ( this.props.onDeleteArea != null ) {
          this.props.onDeleteArea()
        }                    

      })
      .catch( error => {
        console.log("ERROR",error)

        if ( error.error != null ) {
          this.setState({ sendErrors: error.error })
        }
        else {
          this.setState({ sendErrors: "Errore durante Il salvataggio, riprovare più tardi!" })
        }
        
      })

      this.setState({ showConfirmDeleteArea: false})

    }

  }

  
  /**
   * --------------------------------------------------------------
   * 
   */
  onCancelEditArea() {

    this.stateInit()
    this.fetchData()

    if ( this.props.onCancelEditArea != null )
      this.props.onCancelEditArea()
  }

  /**
   * --------------------------------------------------------------
   * @param {*} name 
   */
  setAreaName(name) {
    var area = this.state.area
    area.name=name
    this.setState({area: area})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} code 
   */
  setAreaCode(code) {
    var area = this.state.area
    area.code=code
    this.setState({area: area})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} userCheck 
   */
  setUserCheck(userCheck) {
    var area = this.state.area           
    area.userCheck = userCheck
    this.setState({area: area})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} color 
   */
  setAreaColor(color) {
    if ( color != null && color.hex != null)  {      
      var area = this.state.area
      area.color=color.hex
      this.setState({area: area})
    }    
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  showColorPicker() {    
    this.setState({displayColorPicker: !this.state.displayColorPicker})
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
   onCloseShowAddressesModal() {
    this.setState({      
      showAddressesModal: false
    })
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  onCloseShowPassagesModal() {
    this.setState({      
      showPassagesModal: false
    })
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  async onUpdateAddresses() {
    this.setState({submitting: true, error: null, success: false})                                
    await this.fetchData()
    this.setState({submitting: false, error: false, success: true})  
  }


  /**
   * --------------------------------------------------------------------------
   * 
   */
  async onUpdatePassages() {
    this.setState({submitting: true, error: null, success: false})                                
    await this.fetchData()
    this.setState({submitting: false, error: false, success: true})  
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
   editAddresses(areaId) {    
    this.setState({      
      showAddressesModal: true
    })
  }

  editPassages(areaId) {
    this.setState({      
      showPassagesModal: true
    })
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {

    const {        
      areaId 
    } = this.props

    const {
      sendErrors,
      checkErrors,
      area,
      displayColorPicker,
      showConfirmDeleteArea,
      showAddressesModal,
      showPassagesModal,
      addresses
    } = this.state

    if ( this.props.auth.user.role != ADMIN ) {
      return (<div className="text-primary">Utente non autorizzato</div>)
    }
      
    return (
      <React.Fragment>
              
        { area  == null &&
        <div className="col-md-12">
          <div className="row">          
            <div className="text-center" style={{ margin: "1em" }}>
              <div className="row"><h2>Caricamento in corso...</h2></div>
              <div className="row" style={{padding: "2em", height: "40em"}}>
                <Oval secondaryColor="#02afff"  color="#174c88" height={40} width="100%" />
              </div>
            </div>
          </div>          
        </div>
        }

        { area != null &&
        <div className="col-md-12">
          <div className="app-modal-body">                                  
            
            <React.Fragment>

              { area.id != null &&
              <div className="row" style={{ marginBottom: "1em" }}>
                  <div className="col-xs-3">
                    <h5 style={{ marginTop: 5 , marginBottom: 5, verticalAlign: "top" }}><strong>Id:</strong></h5>
                  </div>
                  
                  <div className="col-xs-9">
                    <h5 style={{ marginTop: 5 , marginBottom: 5, marginLeft: 15, verticalAlign: "top" }}><strong>{area.id}</strong></h5>
                  </div>                                
              </div>
              }
              
              <div className="row" style={{ marginBottom: "1em" }}>
                <div className="col-xs-3">
                  <h5 style={{ marginTop: 10 , marginBottom: 5, verticalAlign: "top" }}><strong>Nome:</strong></h5>
                </div>
                
                <div className="col-xs-9">
                  <input
                    name="name" 
                    className="form-control" 
                    type="text" 
                    onChange={ e => {  this.setAreaName(e.target.value) }}
                    value={area.name}
                    required />
                </div>                                
              </div>

              <div className="row" style={{ marginBottom: "1em" }}>
                  <div className="col-xs-3">
                    <h5 style={{ marginTop: 10 , marginBottom: 5, verticalAlign: "top" }}><strong>Codice:</strong></h5>
                  </div>
                  
                  <div className="col-xs-9">
                    <input
                      name="code" 
                      className="form-control" 
                      type="text" 
                      onChange={ e => {  this.setAreaCode(e.target.value) }}
                      value={area.code}
                      required />
                  </div>                                   
              </div>

              <div className="row" style={{ marginBottom: "1em" }}>
                  <div className="col-xs-3">
                    <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-color"}>
                      colore che viene mostrato all’interno della generazione e stampa dei tagliandi permessi non virtuali associati a questa zona
                    </Tooltip>}>
                      <h5 style={{ marginTop: 10 , marginBottom: 5, verticalAlign: "top" }}><strong>Colore:</strong> <span className="glyphicon glyphicon-info-sign">{}</span></h5>
                    </OverlayTrigger>
                  </div>
                  
                  <div className="col-xs-2" height="1000em">
                    <input
                      style={{background: area.color, color: area.color}}
                      name="color" 
                      className="form-control" 
                      type="text" 
                      onChange={ e => {  this.setAreaColor(e.target.value) }}
                      onClick={ e => { this.showColorPicker() }}
                      value={area.color}
                      required />

                    { displayColorPicker &&
                      <div className="row">
                        <div className="pull-left" style={{ margin: "0em" , marginTop: "0.3em" }}>
                          <div className="col-xs-12">
                            <SketchPicker 
                              color={ area.color }
                              onChangeComplete={ this.setAreaColor }
                            />   
                          </div>
                          <div className="col-xs-12">
                            <Button className="btn mainBtn" onClick={ () => { this.showColorPicker() } }>
                            <FontAwesomeIcon  size="1x" icon={solidIcons.faXmarkCircle} />&nbsp;&nbsp;Chiudi
                            </Button>
                          </div>
                        </div>
                      </div>                      
                    }
                  </div>
              </div>

              <div className="row" style={{ marginBottom: "1em" }}>
                <div className="col-xs-3">                  
                    <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-usercheck"}>
                      Se spuntato impone come requisito che gli utenti che richiedono permessi con assegnata questa Zona, siano lavoratori o residenti nella zona medesima
                    </Tooltip>}>
                      <h5 style={{ marginTop: 10 , marginBottom: 5, verticalAlign: "top" }}>
                      <strong>User Check:</strong> <span className="glyphicon glyphicon-info-sign">{}</span>
                      </h5>
                    </OverlayTrigger>                  
                </div>
                
                <div className="col-xs-9" textalign="left">
                  <input
                    style={{ width: "2em"}}
                    name="userCheck" 
                    className="form-control" 
                    type="checkbox" 
                    onChange={ e => {  this.setUserCheck(e.target.checked) }}
                    checked={area.userCheck}
                    required />
                </div>
              </div>
              
              <div className="row" style={{ marginBottom: "1em" }}>
                { sendErrors && 
                  <h4 className="col-xs-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                    {sendErrors}
                  </h4>
                }
                { checkErrors && checkErrors.length > 0 && 
                  <h4 className="col-xs-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                    {checkErrors.map( ( error, index  ) => { return (<div key={index}>{error}</div>) }  )}
                  </h4>
                }
              </div>

              { areaId != null && 
              <div className="row" style={{ marginBottom: "1em" }}>
                <div className="col-xs-6 text-right" style={{marginBottom: '15px', marginTop: '10px'}}>
                  <button className="mainBtn"  onClick={ e => { this.editAddresses() } } >Gestione Indirizzi</button>
                </div>

                <div className="col-xs-6 text-left" style={{marginBottom: '15px', marginTop: '10px'}}>
                  <button className="mainBtn"  onClick={ e => { this.setState({ showPassagesModal: true }) } } >Gestione Varchi</button>
                </div>
              </div>
              }
              
              <div className="row" style={{ marginBottom: "1em" }}>
                <div className="col-xs-12 text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                  <Button className="btn alertBtn" onClick={() => { this.onCancelEditArea() } }>Annulla</Button>{' '}
                  <Button className="btn mainBtn"  onClick={() => { this.saveArea() }} >Salva</Button>
                  { areaId &&
                  <Button className="btn alertBtn" onClick={() => { this.setState({ showConfirmDeleteArea: true })} }><FontAwesomeIcon  size="1x" icon={solidIcons.faTrash} />&nbsp;&nbsp;Elimina</Button>
                  }
                </div>
              </div>          

            </React.Fragment>            
          </div>
        </div>           
        }  

        <RequestConfirmModal
          requestStyle={2}
          requestTitle="Conferma Eliminazione Zona"
          requestSubTitle="ATTENZIONE"
          requestMsg={"Proseguendo con l'operazione si eliminerà la Zona "+ area.name +" ed i corrispondenti indirizzi associati.<br/><br/>L'operazione è irreversibile, si vuole procedere?"}
          show={showConfirmDeleteArea}
          onConfirm={this.deleteArea}
          onRefuse={ () => {this.setState({ showConfirmDeleteArea: false }) }}
        />

        { areaId != null && 
        <BackofficeAddressesModal
          show={showAddressesModal}
          onClose={this.onCloseShowAddressesModal}
          areaId={areaId}
          onUpdateAddresses={this.onUpdateAddresses}
          />        
        }

        { areaId != null && 
        <BackofficePassagesModal
          show={showPassagesModal}
          onClose={this.onCloseShowPassagesModal}
          areaId={areaId}
          addresses={addresses}
          onUpdatePassages={this.onUpdatePassages}
          
          />        
        }
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'BackofficeEditArea'})(BackofficeEditArea))
