export const MARK_DURATION           = 'MarkDuration_'
export const MARK_EXPIRATION_DATE    = 'MarkExpirationDate_'
export const MARK_MODIFY_PRICE       = 'MarkModifyPrice'
export const MARK_RENEW_NOTIFY_DAYS  = 'MarkRenewNotifyDays'
export const MARK_MODIFY_CODE        = 'MarkModifyCode'
export const MARK_ENABLE_START_DATE  = 'MarkEnableStartDate'
export const MARK_ENABLE_END_DATE    = 'MarkEnableEndDate'// tentativo di bruno nel realizzare una end date dinamica dalle variabili di settings? incomprensibile
export const MARK_PRINT_BY_USER      = 'MarkPrintByUser'//Consente di stampare dai permessi dell'AreaUser. Doppia verifica perché poi ogni permesso avrà il suo settaggio


/**
 * --------------------------------------------------------------------------
 * 
 */
export function getSettingByName (settings,name) {

    var setting = null
    
    if ( settings != null && settings.length > 0 && name != null ) {
        const foundSettings = settings.filter(item => item.name == name)

        if ( foundSettings != null && foundSettings.length > 0) {
            setting = foundSettings[0]
        }
    }
    
    return setting
  }



