import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import "react-responsive-modal/styles.css";
import { Field, reduxForm } from 'redux-form';
import { Button } from 'react-bootstrap';
import SostaOnlineApiService from 'services/SostaOnlineApiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { actions as authActions} from 'redux/modules/Auth'
import { getActiveChangeRequest, getActiveMarks, getRequestedDocumentTypes, getValidPlateNumberContent } from 'libs/vehicles';
import { areObligatoryDocsTypeSelected, getDocumentTypeColor, isTypeDocumentSelected, MARK_TYPE_ELECTRIC, MARK_TYPE_GREEN, MARK_TYPE_HYBRID } from 'libs/marks';
import FileInputField from 'components/FileInputField';


const mapStateToProps = (state) => {
  return {
    stateAuth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authActions:   bindActionCreators(authActions,   dispatch)    
  }
}


class UpdateVehicleModal extends Component {

  static propTypes = {
    show:            PropTypes.bool.isRequired,
    onClose:         PropTypes.func.isRequired,    
    onUpdateVehicle: PropTypes.func.isRequired,    
    vehicle:         PropTypes.object    
  }

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = {            
      checkErrors: null,
      sendErrors: null ,
      ownershipTypeId: null,
      powerSupplyId: null,
      vehicleOwnershipTypes: [],
      vehiclePowerSupplies:  [],
      markTypes: [],
      plateNumber: null,      
      documentTypes: [],      
      requestedDocTypes: [],
      selectedDocuments: [],      
    }

    this.updateVehicle      = this.updateVehicle.bind(this)
    this.checkFields        = this.checkFields.bind(this)
    this.fetchInitialData   = this.fetchInitialData.bind(this)
    this.onDocumentAdd      = this.onDocumentAdd.bind(this)
    this.onCloseFileManager = this.onCloseFileManager.bind(this)
   
  } 

  /**
   * --------------------------------------------------------------
   */
  async fetchInitialData () {
                        
    this.setState({loading: true})
  
    try {
      const vehicleOwnershipTypes = await SostaOnlineApiService.fetchVehicleOwnershipTypes()
      const vehiclePowerSupplies  = await SostaOnlineApiService.fetchVehiclePowerSupplies()    
      const documentTypes         = await SostaOnlineApiService.fetchDocumentTypes()      
      const markTypes             = await SostaOnlineApiService.fetchMarkTypes()      

      if (  vehiclePowerSupplies  && vehiclePowerSupplies.result  === 'ok' && 
            vehicleOwnershipTypes && vehicleOwnershipTypes.result === 'ok' && 
            documentTypes         && documentTypes.result         === 'ok' &&
            markTypes.result === 'ok' 
          ) {        

        var selectedDocuments = this.state.selectedDocuments.map( doc => {          
          if ( doc.DocumentType == null && doc.DocumentTypeId != null ) {            
            var findDocType = documentTypes.data.filter( docType => docType.id == doc.DocumentTypeId )

            if ( findDocType != null && findDocType.length > 0 )
              doc.DocumentType = findDocType[0]
          }

          return doc 
        })

        this.setState({  
          selectedDocuments:     selectedDocuments,                 
          vehicleOwnershipTypes: vehicleOwnershipTypes.data,
          vehiclePowerSupplies:  vehiclePowerSupplies.data,
          documentTypes:         documentTypes.data.filter( doc => doc.docType === 'VEHICLE' ) ,
          markTypes:             markTypes.data            
        })      
      } 
      this.setState({loading: false})
    }
    catch (error){ 
      console.log("ERROR",error)
      this.setState({loading: false})
    }
 
  }

  /**
   * --------------------------------------------------------------
   */
  async stateInit() {

    var oldPlateNumber    = this.props.vehicle ? this.props.vehicle.plate_number           : null
    var ownershipTypeId   = this.props.vehicle ? this.props.vehicle.VehicleOwnershipTypeId : null
    var powerSupplyId     = this.props.vehicle ? this.props.vehicle.VehiclePowerSupplyId   : null    
    var requestedDocTypes = []
    var activeMarks       = []
    var plateNumber       = null
    var selectedDocuments = []
    
    if ( this.props.vehicle ) {

      if ( this.props.vehicle.Marks ) {              
        requestedDocTypes = getRequestedDocumentTypes(this.props.vehicle.Marks,this.state.markTypes,this.state.documentTypes)                    
      }

      // se sto modificando una richiesta già presente
      var changeRequest = getActiveChangeRequest(this.props.vehicle)
      
      if ( changeRequest ) {          
        plateNumber     = changeRequest.plate_number
        ownershipTypeId = changeRequest.VehicleOwnershipTypeId
        powerSupplyId   = changeRequest.VehiclePowerSupplyId
        
        changeRequest.VehiclesChangeRequestsDocuments.map( ( vcrd , index ) => {
          var doc      = vcrd.Document
          doc.approved = vcrd.approved
          selectedDocuments.push(doc)                  
        })
      }
    }           

    this.setState({
      documentFile:      null,
      checkErrors:       null,
      sendErrors:        null,
      plateNumber:       plateNumber,
      oldPlateNumber:    oldPlateNumber,
      ownershipTypeId:   ownershipTypeId,
      powerSupplyId:     powerSupplyId,      
      documentType:      null,
      requestedDocTypes: requestedDocTypes,
      selectedDocuments: selectedDocuments,
      activeMarks:       activeMarks
    })

    this.props.initialize({ 
      plateNumber:     plateNumber,
      ownershipTypeId: ownershipTypeId,
      powerSupplyId:   powerSupplyId,      
    })

    if ( selectedDocuments.length > 0 ) {
      selectedDocuments.map( ( doc,index) => {
        this.props.change('documentFile-' + index + "-" + doc.id, doc)  
      })      
    }

  }

  /**
   * --------------------------------------------------------------
   */
  async componentDidMount() {  
    await this.stateInit()
    await this.fetchInitialData ()    
  }
  
  /**
   * --------------------------------------------------------------
   * Restituisce la lista dei tipi Permesso attivi 
   * @param {*} marks          lista permessi utente di un determinato veicolo 
   * @param {*} markTypes      lista tipi permessi
   * @returns 
   */
   getActiveMarkTypes(marks, markTypes) {
    var activeMarks          = getActiveMarks(marks)
    var activeMarkTypeIdList = []
    var activeMarkTypes      = []
            
    if ( activeMarks && activeMarks.length > 0 && markTypes ) {            
      activeMarks.map( mark => { activeMarkTypeIdList.push(mark.markTypeId) })

      // rimuovo gli elementi doppi
      activeMarkTypeIdList = activeMarkTypeIdList.filter(function(elem, pos) {
        return activeMarkTypeIdList.indexOf(elem) == pos;
      });       
      
      activeMarkTypeIdList.map( markTypeId => {                
        markTypes.map( markType => {
          if ( markType.id == markTypeId ) {
            activeMarkTypes.push(markType)
          }            
        })
      })
        
    }
          
    return activeMarkTypes

  }

  /**
   * --------------------------------------------------------------
   * @param {*} prevProps 
   */
  componentDidUpdate(prevProps) {
    if ( prevProps.vehicle != this.props.vehicle ) {          
      this.stateInit()
      this.fetchInitialData()      
    }
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  checkFields() {

    var errors = []    

    const {
      plateNumber,
      powerSupplyId,
      ownershipTypeId,
      selectedDocuments,
      requestedDocTypes,
      vehiclePowerSupplies,
      markTypes
    } = this.state

    // check targa
    if ( plateNumber == null || plateNumber.length <= 0 ) {
      errors.push("Non hai specificato una targa")
    }

    // check tipo alimentazione
    if ( powerSupplyId == null || isNaN(parseInt(powerSupplyId)) ) {
      errors.push("Non hai specificato il tipo di alimentazione")
    }
    else {      
      var powerSupplies = vehiclePowerSupplies.filter( vps => vps.id == powerSupplyId )

      if ( powerSupplies.length > 0 ) {      
        var activeMarkTypes   = this.getActiveMarkTypes(this.props.vehicle.Marks,markTypes)
        var greenRequested    = false
        var hybridRequested   = false
        var electricRequested = false

        activeMarkTypes.map( amt => {
          if ( amt.type == MARK_TYPE_GREEN ) {
            greenRequested= true
          }
          else if ( amt.type == MARK_TYPE_HYBRID ) {
            hybridRequested= true
          }
          else if ( amt.type == MARK_TYPE_ELECTRIC ) {
            electricRequested = true
          }
        })

        if ( greenRequested && !powerSupplies[0].hybrid && !!powerSupplies[0].electric ) {
          errors.push("Il veicolo richiede un tipo di alimentazione ibrida o elettrica")
        }
        if ( hybridRequested && !powerSupplies[0].hybrid ) {
          errors.push("Il veicolo richiede un tipo di alimentazione ibrida")
        }
        if ( electricRequested && !powerSupplies[0].electric ) {
          errors.push("Il veicolo richiede un tipo di alimentazione elettrica")
        }
      }
      else {
        errors.push("Non hai specificato il tipo di alimentazione")
      }      
    }
    
    // check tipo proprietà veicolo
    if( ownershipTypeId == null ||  isNaN(parseInt(ownershipTypeId)) ) {
      errors.push("Non hai specificato la proprietà del veicolo")
    }
    
    // check documenti richiesti
    if ( requestedDocTypes && requestedDocTypes.length > 0 ) {      
      if ( ! selectedDocuments || selectedDocuments.length <= 0 ) {
        errors.push("Nessun documento selezionato")
      }
      else if ( !areObligatoryDocsTypeSelected(requestedDocTypes,selectedDocuments) ) {
        errors.push("Mancano dei documenti obbligatori")
      }          
    }

    return errors;

  }

  /**
   * --------------------------------------------------------------
   */
  async updateVehicle() {       
    
    var errors = this.checkFields()
    this.setState({
      checkErrors: errors,
      sendErrors: null
    })

    if ( errors != null && errors.length > 0 ) {      
      return
    }     
    
    var changeRequest = getActiveChangeRequest(this.props.vehicle)
            
    // INVIO RICHIESTA
    if ( !changeRequest ) {
      SostaOnlineApiService.createVehicleChangeRequestMe(
          this.props.vehicle.id,
          this.state.plateNumber,
          this.state.ownershipTypeId,
          this.state.powerSupplyId,    
          this.state.selectedDocuments
        )
        .then( changeRequestResult =>{
          if ( changeRequestResult.result === 'ok' ) {

            if ( this.props.onUpdateVehicle )
              this.props.onUpdateVehicle(changeRequestResult.data)

            this.close()

          }
          else {
              this.setState({sendErrors: changeRequestResult.error})
          }
        } )
        .catch( error => {
          this.setState({sendErrors: error.error})
        })          
    }

    // INVIO MODIFICA RICHIESTA
    else {      
            
      SostaOnlineApiService.updateVehicleChangeRequestMe(changeRequest.id,
        this.state.plateNumber,
        this.state.ownershipTypeId,
        this.state.powerSupplyId,    
        this.state.selectedDocuments
      )
      .then( changeRequestResult =>{
        if ( changeRequestResult.result === 'ok' ) {
          
          if ( this.props.onUpdateVehicle ) 
            this.props.onUpdateVehicle(changeRequestResult.data)

          this.close()
            
        }
        else {
            this.setState({sendErrors: changeRequestResult.error})
        }
      })
      .catch( error => {
        this.setState({sendErrors: error.error})
      })       

    }
            
  }  

  /**
   * --------------------------------------------------------------
   */
  close() {
    this.props.onClose()
    this.stateInit()
  }

  /**
   * --------------------------------------------------------------
   * 
   */
   onCloseFileManager() {    
    const {selectedDocuments} = this.state
    const newDocuments = selectedDocuments.filter( (item ) =>  item.id != null && item.id != -1 )
    this.setState({selectedDocuments: newDocuments})
  }

  /**
   * --------------------------------------------------------------------------
   * @param {*} document 
   * @param {*} index 
   */
   onDocumentChange (document,index) {    

    const {selectedDocuments} = this.state    
    const newDocuments = selectedDocuments.map((item,index1) => {

      if ( index === index1 || item.id === -1  ) {                    
        this.props.change('documentFile-' + index + "-" + document.id, document)
        return document
      } 
      else {        
        return item
      }
    })
    
    this.setState({
      selectedDocuments: [...newDocuments]
    })
    
  }

  /**
   * --------------------------------------------------------------------------
   * 
   */
  onDocumentRemove (index) {
    this.setState({selectedDocuments: this.state.selectedDocuments.filter((_, i) => i !== index)})
  }
  
  /**
   * --------------------------------------------------------------------------
   * 
   */
  onDocumentAdd () {    
    var docs =  this.state.selectedDocuments.map( doc => { doc.openFileManager = false ; return doc  } )
    docs = [...docs, { 'openFileManager': true , id:-1 } ]
    this.setState({ 'selectedDocuments': docs})    
  }

   /**
   * --------------------------------------------------------------------------
   * @param {*} document 
   * @returns 
   */
  isTypeDocumentSelected(document) {
      return  isTypeDocumentSelected(document.documentTypeId,this.state.selectedDocuments)          
  }

  /**
   * --------------------------------------------------------------------------
   * @param {*} document 
   * @returns 
   */
   getDocumentTypeColor(document) {    
    return getDocumentTypeColor(document,this.state.selectedDocuments)
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {

    const {
      show,
      onClose,      
    } = this.props

    const {
      sendErrors,
      checkErrors,
      vehiclePowerSupplies,
      vehicleOwnershipTypes,
      oldPlateNumber,      
      requestedDocTypes,
      selectedDocuments
    } = this.state


    return (
      <Modal
        open={show}
        onClose={onClose}
        closeOnEsc={true}
        showCloseIcon={false}       
        closeOnOverlayClick={false}
        classNames={{modal: 'app-modal-container'}}
        animationDuration={500}
        center               
      >
        <h4 className="app-modal-title">
          <div style={{ flex: 10, textAlign : 'left'}}>
            <FontAwesomeIcon  className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faCarBurst} />
          </div>          
          <div style={{ flex: 80, textAlign : 'center' }}><strong>MODIFICA VEICOLO {oldPlateNumber}</strong></div>          
          <div style={{ flex: 10, textAlign : 'right'}}>
            <FontAwesomeIcon  onClick={ evt => this.close() } className="mainIconsRev"  size="1x" icon={solidIcons.faXmark} />
          </div>          
        </h4>        

        <div className="col-md-12" >
          <div className="app-modal-body">
            <fieldset>            
              <div className="row">
                <div className="col-md-12" style={{ marginBottom: "1em" }} >
                  <Field 
                    name="powerSupplyId" 
                    className="form-control" 
                    component="select" 
                    onChange={ e => { this.setState({powerSupplyId: e.target.value}) }}
                    required
                    placeholder="Seleziona il tipo di alimentazione del nuovo veicolo..."
                    >
                    <option>Seleziona il tipo di alimentazione del nuovo veicolo...</option>
                    { vehiclePowerSupplies.map( ( ps , index )=> {
                      return ( <option key={index} value={ps.id}>{ps.name}</option> )
                    })}
                    
                  </Field>
                </div>
              </div>   

              <div className="row">
                <div className="col-md-12" style={{ marginBottom: "1em" }} >
                  <Field 
                    name="ownershipTypeId" 
                    className="form-control" 
                    component="select" 
                    onChange={ e => { this.setState({ownershipTypeId: e.target.value}) }}
                    required
                    placeholder="Seleziona la proprietà del veicolo..."
                    >
                    <option>Seleziona la proprietà del veicolo...</option>
                    { vehicleOwnershipTypes.map( ( ot , index )=> {
                      return ( <option key={index} value={ot.id}>{ot.name}</option> )
                    })}
                    
                  </Field>                
                </div>
              </div>   

              <div className="row">      
                <div className="col-md-12" style={{ marginBottom: "1em" }} >          
                  <Field 
                    name="plateNumber" 
                    className="form-control" 
                    component="input"
                    onChange={ e => {                   
                      var plateNumber = getValidPlateNumberContent(e.target.value)
                      this.setState({plateNumber: plateNumber})
                    }}
                    required
                    placeholder="Inserisci la targa del nuovo veicolo..."
                    normalize={ value => getValidPlateNumberContent(value) }                           
                    />
                </div>
              </div>   

              { requestedDocTypes && requestedDocTypes.length > 0 &&              
                <React.Fragment>
                  {/* TODO creare un componente centralizzato per gestire i documenti */}
                  <div className="row" >
                    <div className="col-md-12" style={{ marginBottom: "1em" }} >
                      <strong>Documenti richiesti:</strong>
                    </div>
                  </div>
                  { requestedDocTypes.map( docType => 
                    <div key={"docType-"+docType.id} className="row" style={{ 'color': this.getDocumentTypeColor(docType) }} >
                      <div className="col-md-12 text-left">
                      <strong>{docType.label} { docType.mandatory && "( obbligatorio )" } </strong>
                      </div>
                    </div>                  
                  )}

                  <div className="row" style={{ marginTop: "1em" , marginBottom: "1em" }}>
                    <div className="col-md-12 text-left">
                    I file caricati devono essere nel formato: png, jpeg, pdf.
                    </div>
                  </div>
                
                  <div className="row">
                    <div className="col-md-12" style={{ marginBottom: "1em" }} >
                      <div className="mainBtn col-xl-3 col-lg-3 col-md-4 col-sm-5 col-xs-5" onClick={this.onDocumentAdd} >
                          <FontAwesomeIcon size="1x" icon={solidIcons.faFileCirclePlus} />&nbsp;
                          Allega Documento                
                      </div>
                    </div>
                  </div>            

                  {selectedDocuments.map((item, index) => {                  
                            
                    return (
                      <div className="row row-list-body" key={'document-' + index + '-' + item.id} >

                        <div className="col-sm-6 text-left  cell-list-body " >
                          <div style={{ marginLeft: 15, paddingTop:10 }}>                            
                            <strong>{item.DocumentType ? item.DocumentType.label : 'Seleziona un documento'}</strong>
                          </div>                          
                        </div>
                      
                        <div hidden={item.approved} className="col-sm-5 text-left cell-list-body" >
                          <Field                                
                            openFileManager={item.openFileManager}
                            onChange={(document) => { this.onDocumentChange(document,index) }}
                            onClose={this.onCloseFileManager}
                            name={'documentFile-' + index + '-' + item.id}
                            component={FileInputField}                          
                            required
                          />
                        </div>

                        <div hidden={!item.approved} className="col-sm-5 text-left cell-list-body" >
                          <div className="successLabel" style={{ marginLeft: 5, marginTop: 5 }}>
                            <FontAwesomeIcon className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faCheckCircle} />
                            &nbsp;&nbsp;{item.name}
                          </div>
                        </div>

                        <div className="col-sm-1 text-left cell-list-body">
                          { !item.approved &&
                          <div onClick={() => this.onDocumentRemove(index)} className="alertBtn" >
                            <FontAwesomeIcon size="1x" icon={solidIcons.faTrash} />                              
                          </div>
                          }
                        </div>

                      </div>
                    )          
                  })}
                </React.Fragment>
              }
                                          
              <div className="row">
                { sendErrors && 
                  <h4 className="col-md-12 text-danger text-center" style={{marginBottom: '2em', marginTop: '2em'}}>
                    {sendErrors}
                  </h4>
                }
                { checkErrors && checkErrors.length > 0 && 
                  <h4 className="col-md-12 text-danger text-center" style={{marginBottom: '2em', marginTop: '2em'}}>
                    {checkErrors.map( ( error, index  ) => { return (<div key={index}>{error}</div>) }  )}
                  </h4>
                }
              </div>

              <div className="row">
                <div className="col-md-12 text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                  <Button className="btn alertBtn"     onClick={() => this.close()        } >Annulla </Button>{' '}
                  <Button className="btn mainBtn"      onClick={() => this.updateVehicle()} >Aggiorna</Button>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        
      </Modal>
    )
  }
}

export default  connect(mapStateToProps, mapDispatchToProps)(reduxForm({ enableReinitialize: true, form: 'UpdateVehicleModal'})(UpdateVehicleModal))
