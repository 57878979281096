import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import "react-responsive-modal/styles.css";
import { Field, reduxForm } from 'redux-form';
import { Button } from 'react-bootstrap';
import SostaOnlineApiService from 'services/SostaOnlineApiService';
import { getSubMessageType } from 'libs/messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  * as solidIcons  from '@fortawesome/free-solid-svg-icons'


class NewDocumentModal extends Component {

  static propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,    
    onUploadDocument: PropTypes.func.isRequired    
  }

  /**
   * --------------------------------------------------------------
   * @param {*} props 
   */
  constructor(props) {
    super(props)

    this.state = {      
      documentFile: null,     
      documentType: null,
      documentName: "", 
      checkErrors: null,
      sendErrors: null ,
      documentTypes: []
    }

    this.uploadDocument = this.uploadDocument.bind(this)
    this.checkFields    = this.checkFields.bind(this)
   
  }  

  /**
   * --------------------------------------------------------------
   */
  componentDidMount() {    
    this.stateInit()

    SostaOnlineApiService.fetchDocumentTypes().then( data => {      
      this.setState({documentTypes: data.data})
    })
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  checkFields() {

    var errors = []

    if ( this.state.documentFile == null ) {
      errors.push("Nessun file selezionato")
    }

    if ( this.state.documentName == null || this.state.documentName.length <= 0  ) {
      errors.push("Nome documento non specificato")
    }

    if ( this.state.documentType == null ) {
      errors.push("Tipo documento non specificato")
    }

    return errors;

  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  uploadDocument() {    

    var errors = this.checkFields()
    this.setState({checkErrors: errors})

    if ( errors != null && errors.length > 0 ) {      
      return
    }
     
    SostaOnlineApiService.uploadDocument(      
      this.state.documentName,
      this.state.documentType,
      this.state.documentFile
    )
    .then( result => {      

      if ( this.props.onUploadDocument != null ) {
        this.props.onUploadDocument(result)
      }

      if ( this.props.onClose )
        this.props.onClose()
    })
    .catch( error => {
      console.log("ERROR",error)
      if ( error.error !==  null ||   error.error.length > 0 )
        this.setState({ sendErrors:  error.error })
      else 
        this.setState({ sendErrors: "Errore durante l'invio del documento, riprovare pù tardi!" })
    })
    

    
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  getSelectedMessageType() {
    return getSubMessageType(this.state.messageType,this.state.subMessageType)    
  }

  /**
   * --------------------------------------------------------------
   */
  close() {
    this.props.onClose()
    this.stateInit()
  }

  /**
   * --------------------------------------------------------------
   * 
   */
  stateInit() {
    this.setState({      
      documentFile: null,     
      documentType: null,
      documentName: "", 
      checkErrors: null,
      sendErrors: null 
    })    

  }

  /**
   * --------------------------------------------------------------
   * @param {*} value 
   */
  onDocumentFileChange(value) {
  
    if ( value.length > 0 ) {      
      this.setState({
        documentFile: value[0],
        documentName: value[0].name
      })
    }
  }
  
  /**
   * --------------------------------------------------------------
   * @param {*} value 
   */
  onDocumentNameChange(value) {
    this.setState({documentName: value})
  }

  /**
   * --------------------------------------------------------------
   * @param {*} value 
   */
  onDocumentTypeChange(value) {
    this.setState({documentType: value})
  }

  /**
   * --------------------------------------------------------------
   * @returns 
   */
  render () {

    const {
      show,
      onClose      
    } = this.props

    const {
      sendErrors,
      checkErrors,
      documentFile,
      documentName,
      documentType,
      documentTypes   
    } = this.state


    return (
      <Modal
        open={show}
        onClose={onClose}
        closeOnEsc={true}
        showCloseIcon={false}       
        closeOnOverlayClick={false}
        classNames={{modal: 'app-modal-container'}}
        animationDuration={500}
        center               
      >
        <h4 className="app-modal-title">
          <div style={{ flex: 10, textAlign : 'left'}}>
            <FontAwesomeIcon  className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faFileUpload} />
          </div>          
          <div style={{ flex: 80, textAlign : 'center' }}><strong>CARICA NUOVO DOCUMENTO</strong></div>
          <div style={{ flex: 10, textAlign : 'right'}}>
            <FontAwesomeIcon  onClick={ evt => this.close() } className="mainIconsRev"  size="1x" icon={solidIcons.faXmark} />
          </div>          
        </h4>        

        <div className="col-md-12" >
          <div className="app-modal-body">
            <div className="row" style={{ paddingLeft: "15px" , paddingRight: "10px" }}>
              <h4 ><strong>File (pdf, jpeg, png, tiff - max: 2MB)</strong></h4>
            </div>

            <div className="row" style={{ paddingLeft: "10px" , paddingRight: "10px" }}>
                
                <label className="mainBtn">
                  <input         
                    style={{ display: "none"}}                               
                    type="file" 
                    name="documentFile"                                       
                    onChange={ (evt) => { this.onDocumentFileChange(evt.target.files) } }
                  />
                  <FontAwesomeIcon  size="1x" icon={solidIcons.faFile} />&nbsp;&nbsp;Seleziona ...
                </label>
                  
                
                { documentFile &&                   
                  <span><strong> {documentFile.name} </strong></span>
                }
            </div>

            <div className="row" style={{ paddingLeft: "10px" , paddingRight: "20px" }}>              
              <input    
                style={{ width : "100%"}}
                className="secondaryInput"
                type="text"
                name="documentName"
                placeholder="Nome Documento..."
                value={documentName}
                onChange={ (evt) => { this.onDocumentNameChange(evt.target.value) } }
                />              
            </div>  

            <div className="row" style={{ paddingLeft: "10px" , paddingRight: "20px" }}>              
              <select
                style={{ width : "100%"}}
                value={documentType}
                onChange={ (evt) => { this.onDocumentTypeChange(evt.target.value) } }
                disabled={!documentFile}
                className="secondaryInput"
              >
                <option value="" >
                  Seleziona il tipo documento...
                </option>
                {documentTypes.map((item, index) => {
                  return (
                    <option  value={item.id}  key={`select-document-type-option-${index}`} >
                      {item.label}
                    </option>
                  )
                })}
              </select>
            </div>         

            <div className="row">
              { sendErrors && 
                <h4 className="col-md-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                  {sendErrors}
                </h4>
              }
              { checkErrors && checkErrors.length > 0 && 
                <h4 className="col-md-12 text-danger text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                  {checkErrors.map( ( error, index  ) => { return (<div key={index}>{error}</div>) }  )}
                </h4>
              }
            </div>

            <div className="row">
              <div className="col-md-12 text-center" style={{marginBottom: '15px', marginTop: '10px'}}>
                <Button className="btn alertBtn" onClick={ () => this.close() }>
                  <FontAwesomeIcon  className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faXmarkCircle} />&nbsp;&nbsp;Annulla
                </Button>{' '}
                <Button className="btn mainBtn"  onClick={ () => this.uploadDocument()}>
                  <FontAwesomeIcon  className="mainIconsRevNoAction"  size="1x" icon={solidIcons.faUpload} />&nbsp;&nbsp;Carica
                </Button>
              </div>
            </div>
          </div>
        </div>
        
      </Modal>
    )
  }
}

export default reduxForm({ form: 'NewDocumentModal'})(NewDocumentModal)
