import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { MARK_STATUS_LIST } from 'libs/marks'

export default class MarkStatusFilter extends Component {
  static propTypes = {
    filter: PropTypes.any,
    onChange: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)    
  }

  async componentDidMount () {
    
  }

  render () {
    const {filter,  onChange}   = this.props    
    
    return (
      <select
        onChange={event => onChange(event.target.value ) }
        style={{ width: '100%' }}
        value={filter ? filter.value : ''}
      >
        <option value="">Tutti</option>
        {MARK_STATUS_LIST.sort((a, b) => (a.value > b.value ) ? 1 : -1).map((item, index) => {          
          return (
            <option
              key={item.id + '-' + index}
              value={item.id}>
              {item.value}
            </option>
          )
        })}
      </select>
    )
  }
}
